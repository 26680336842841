import axios from 'axios';

const authenticatedInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
    headers: {
        'Access-Control-Allow-Origin' : '*',
    }
});

authenticatedInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('JWT');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});
authenticatedInstance.interceptors.response.use(
    response => response, // Pass through if the response is successful
    error => {
        if (error.response && error.response.status === 401) {
            // If 401 Unauthorized, assume the token has expired
            localStorage.removeItem('JWT'); // Clear the stored token
            window.location.href = '/login'; // Redirect to login page
        }
        return Promise.reject(error); // Pass on the error to be handled later
    }
);
export default authenticatedInstance;
