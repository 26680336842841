import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authenticatedInstance from "../../../custom-axios/axios-auth";
import NavBar from "../../Navbar/NavBar";

const SellerSubmission = () => {
    const [submission, setSubmission] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSubmission = async () => {
            try {
                // Fetch submission details from the endpoint
                const response = await authenticatedInstance.get('/submissions/submission');

                if (response.status === 200) {
                    setSubmission(response.data);
                }
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    // Redirect to /not-found if submission is not found
                    navigate('/not-found');
                } else {
                    setError('Failed to load submission details.');
                    console.error('Error fetching submission details:', err);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchSubmission();
    }, [navigate]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <div
                className="mt-5 submission-details-container bg-bgGrayOrange p-6 rounded-lg shadow-md max-w-3xl mx-auto font-poppins">
                <h1 className="text-2xl font-bold mb-6 text-darkBrown text-center">Your Submission Details</h1>
                {submission ? (
                    <div className="submission-details p-4 bg-white rounded-lg shadow-sm">
                        {/* Submission Status */}
                        <h2 className="text-lg font-semibold text-darkBrown mb-4">
                            Submission Status: {submission.status}
                        </h2>
                        <h2 className="text-lg font-semibold text-darkBrown mb-4">
                            Your plan: {submission.subscriptionPlan}
                        </h2>
                        <h2 className="text-lg font-semibold text-darkBrown mb-4">
                            Type of shop: {submission.shop.productType}
                        </h2>

                        {/* Seller Profile Details */}
                        <div className="seller-profile mb-6">
                            <h3 className="text-lg font-bold text-darkBrown mb-2">Seller Profile:</h3>
                            <p><strong>First Name:</strong> {submission.sellerProfile.firstName}</p>
                            <p><strong>Last Name:</strong> {submission.sellerProfile.lastName}</p>
                            <p><strong>Bio:</strong> {submission.sellerProfile.bio}</p>
                            <p><strong>Approved:</strong> {submission.sellerProfile.approved ? 'Yes' : 'No'}</p>
                            {submission.sellerProfile.profilePictureFile?.id && (
                                <div className="profile-picture mt-2">
                                    <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${submission.sellerProfile.profilePictureFile.id}`}
                                        alt="Profile"
                                        className="w-32 h-32 object-cover rounded-full border border-lightBrown"
                                    />
                                </div>
                            )}
                        </div>

                        {/* Shop Details */}
                        <div className="shop-details mb-6">
                            <h3 className="text-lg font-bold text-darkBrown mb-2">Shop Details:</h3>
                            <p><strong>Shop Name:</strong> {submission.shop.name}</p>
                            <p><strong>Description:</strong> {submission.shop.description}</p>
                            <p><strong>ZIP Code:</strong> {submission.shop.zipCode}</p>
                            {submission?.shop?.profilePictureFile?.id && (
                                <div className="shop-picture mt-2">
                                    <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${submission.shop.profilePictureFile.id}`}
                                        alt="Shop Profile"
                                        className="w-32 h-32 object-cover rounded border border-lightBrown"
                                    />
                                </div>
                            )}
                            {submission.shop.categoryFilters.length > 0 && (
                                <ul className="products-list mt-2">
                                    Category Filters:
                                    {submission.shop.categoryFilters.map((category, index) => (
                                        <li key={index} className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm">
                                            <p> {category.name}</p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {submission.shop.categoryService.length > 0 && (
                                <ul className="products-list mt-2">
                                    Category Services:
                                    {submission?.shop?.categoryService.map((category, index) => (
                                        <li key={index} className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm">
                                            <p> {category.name}</p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {submission.shop.categoryHolidays.length > 0 && (
                                <ul className="products-list mt-2">
                                    Category Holidays:
                                    {submission?.shop?.categoryHolidays.map((category, index) => (
                                        <li key={index} className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm">
                                            <p> {category.name}</p>
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {/* Products List */}
                            <h4 className="text-lg font-bold text-darkBrown mt-4">Products:</h4>
                            {submission.shop.products.length > 0 ? (
                                <ul className="products-list mt-2">
                                    {submission?.shop?.products?.map((product, index) => (
                                        <li key={index} className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm">
                                            <p><strong>Title:</strong> {product.title}</p>
                                            <p><strong>Description:</strong> {product.description}</p>
                                            <p><strong>Price:</strong> {product.price}</p>
                                            <p><strong>Type:</strong> {product.productType}</p>
                                            <p><strong>Hidden:</strong> {product.isHidden ? 'Yes' : 'No'}</p>
                                            <div className="images mt-2">
                                                {product?.imageFiles?.map((image, imgIndex) => {
                                                    // Access the specific property that contains the image filename or URL
                                                    const imgSrc = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${image.id || image.url}`;
                                                    return (
                                                        <img
                                                            key={imgIndex}
                                                            src={imgSrc}
                                                            alt={`Product Image ${imgIndex + 1}`}
                                                            className="w-20 h-20 object-cover rounded border border-lightBrown mr-2"
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No products available.</p>
                            )}
                        </div>
                    </div>
                ) : (
                    <p className="text-darkBrown">No submission details available.</p>
                )}
            </div>
        </div>

    );
};

export default SellerSubmission;
