

const StatusActions = ({ closeModal, handleSubmit, isSubmitting }) => {
    return (
        <div className="flex justify-end space-x-4">
            <button type="button" onClick={closeModal} className="custom-btn-secondary">
                Cancel
            </button>
            <button
                type="button"
                onClick={handleSubmit} // Combine and submit the message and note
                className={`custom-btn-critical ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isSubmitting}
            >
                {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
        </div>
    );
};
export default StatusActions;