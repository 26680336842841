import React, { useState } from 'react';

const ReviewForm = ({ handleSubmit, review, handleInputChange, setIsEditing }) => {
    const [starRating, setStarRating] = useState(review.rating || 0);
    const [errorMessage, setErrorMessage] = useState(''); // Add error message state

    const handleStarClick = (ratingValue) => {
        setStarRating(ratingValue);
        handleInputChange({ target: { name: 'rating', value: ratingValue } });
        setErrorMessage(''); // Clear error message when a star is selected
    };

    const renderStars = () => {
        return [1, 2, 3, 4, 5].map((star) => (
            <svg
                key={star}
                onClick={() => handleStarClick(star)}
                className={`w-6 h-6 cursor-pointer ${star <= starRating ? 'text-yellow-300' : 'text-gray-300'}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
            >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
        ));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (starRating === 0) {
            setErrorMessage('Please select a star rating.');
            return; // Prevent submission if no rating is selected
        }
        handleSubmit(e);
    };

    return (
        <form className="review-form" onSubmit={onSubmit}>
            <label>
                Rating:
                <div className="flex items-center">
                    {renderStars()}
                    <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                        {starRating} out of 5
                    </p>
                </div>
                {errorMessage && (
                    <p className="text-red-500 text-sm mt-1">{errorMessage}</p> // Display error message
                )}
            </label>
            <br />
            <label>
                Comment:
                <textarea
                    name="comment"
                    className="border-2"
                    value={review.comment}
                    onChange={handleInputChange}
                />
            </label>
            <br />
            <button type="submit" className="custom-btn-primary mt-4 mb-4">
                Submit Review
            </button>
            <button type="button" className="custom-btn-outline ms-2 mb-4" onClick={() => setIsEditing(false)}>
                Cancel
            </button>
        </form>
    );
};

export default ReviewForm;
