import { useState, useEffect } from 'react';
import authenticatedInstance from "../custom-axios/axios-auth";
import dayjs from 'dayjs';

const useSellerOrdersByMonth = (monthString) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                setLoading(true);
                const response = await authenticatedInstance.get('/orders/seller/ordersByDate', {
                    params: { month: monthString }
                });

                // Process orders to convert dateDelivery
                const processedOrders = response.data.map(order => {
                    const [year, month, day, hour, minute] = order.dateDelivery; // Destructure the array
                    return {
                        ...order,
                        dateDelivery: dayjs(new Date(year, month - 1, day, hour, minute)) // Create a new Date object
                    };
                });

                setOrders(processedOrders);
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };

        if (monthString) {
            fetchOrders();
        }
    }, [monthString]);

    return { orders, loading };
};

export default useSellerOrdersByMonth;
