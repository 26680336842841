import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../../custom-axios/axios-auth';
import { useParams, useNavigate } from 'react-router-dom';

const CustomerProfileFormComponent = () => {
    const { id } = useParams();
    const [profile, setProfile] = useState({ user: { email: '' }, firstName: '', lastName: '', reviews: [] });
    const [shops, setShops] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                const response = await authenticatedInstance.get(`/admin/users/customer/${id}`);
                setProfile(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile details:', error);
                setError('Failed to load profile details.');
                setLoading(false);
            }
        };

        const fetchShops = async () => {
            try {
                const response = await authenticatedInstance.get(`/admin/shops/shortened`);
                setShops(response.data);
            } catch (error) {
                console.error('Error fetching shops:', error);
                setError('Failed to load shops.');
            }
        };

        fetchProfileDetails();
        fetchShops();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile({
            ...profile,
            [name]: value,
            user: {
                ...profile.user,
                [name]: name === "email" ? value : profile.user.email
            }
        });
    };

    const handleReviewChange = (index, e) => {
        const { name, value } = e.target;
        const updatedReviews = profile.reviews.map((review, i) =>
            i === index ? { ...review, [name]: value } : review
        );
        setProfile({ ...profile, reviews: updatedReviews });
    };

    const addReview = () => {
        setProfile({
            ...profile,
            reviews: [...profile.reviews, { rating: 0, comment: '', userId: id, dateTime: new Date().toISOString(), shopId: null }]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await authenticatedInstance.put(`/admin/users/customer/${id}`, profile);
            alert('Profile updated successfully');
            navigate(`/admin/users/${profile.user.id}`); // Navigate to the user ID
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Failed to update profile.');
        }
    };

    if (loading) {
        return <p className="loading-message">Loading...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <div className="container profile-form-container">
            <h1>Edit Customer Profile</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={profile.firstName || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={profile.lastName || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={profile.user.email || ''}
                        onChange={handleInputChange}
                    />
                </div>

                <h3>Reviews</h3>
                {profile.reviews.map((review, index) => (
                    <div key={index} className="review-group">
                        <div className="form-group">
                            <label htmlFor={`rating-${index}`}>Rating</label>
                            <input
                                type="number"
                                id={`rating-${index}`}
                                name="rating"
                                value={review.rating || 0}
                                onChange={(e) => handleReviewChange(index, e)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor={`comment-${index}`}>Comment</label>
                            <textarea
                                id={`comment-${index}`}
                                name="comment"
                                value={review.comment || ''}
                                onChange={(e) => handleReviewChange(index, e)}
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor={`shopId-${index}`}>Shop</label>
                            <select
                                id={`shopId-${index}`}
                                name="shopId"
                                value={review.shopId || ''}
                                onChange={(e) => handleReviewChange(index, e)}
                            >
                                <option value="">Select a shop</option>
                                {shops.map((shop) => (
                                    <option key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                ))}
                <button type="button" className="add-review-button" onClick={addReview}>Add Review</button>

                <button type="submit" className="submit-button">Save Changes</button>
            </form>
        </div>
    );
};

export default CustomerProfileFormComponent;
