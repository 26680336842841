import React, {useEffect, useState} from 'react';
import axios from '../../custom-axios/axios';
import {jwtDecode} from "jwt-decode";
import {FaEye, FaEyeSlash} from "react-icons/fa";

const PasswordChange = () => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [userId, setUserId] = useState(null);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('JWT');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUserId(decodedToken.userId)
            } catch (error) {
                console.error('Failed to decode token:', error);
            }
        }
    }, []);

    const validatePassword = (password) => {
        // Regular expression to check for at least 8 characters, one uppercase letter, and one digit
        const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async () => {

        if (!validatePassword(newPassword)) {
            setPasswordError('Password must be at least 8 characters long, include an uppercase letter and a number.');
            return;
        } else {
            setPasswordError('');
            // Proceed with registration logic
        }
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setIsLoading(true); // Start loading spinner
        try {
            const token = localStorage.getItem('JWT');
            const response = await axios.post('/auth/changepassword', {
                userId,
                password,
                newPassword
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }});
            if (response.status === 200) {
                setError(null);
                setSuccess("Password changes successfully.");
            }

        } catch (err) {
            setError('Password change failed.');
        } finally {
            setIsLoading(false); // Stop loading spinner
        }
    };


    return (
        <div
            className="register-container min-h-screen flex items-center justify-center bg-bgGrayOrange p-4 font-poppins">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h1 className="text-3xl font-bold text-darkBrown mb-6 text-center">Change your password</h1>

                {isLoading ? (
                    <div className="text-center">
                        <p>Loading...</p>
                    </div>
                ) : (
                    <>
                        <div className="mb-4 relative">
                            <label className="block text-sm font-medium text-darkBrown mb-2">Old Password</label>
                            <input
                                type={passwordVisible ? 'text' : 'password'}  // Toggle between 'password' and 'text'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                // onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-11 text-gray-500"
                                onClick={() => setPasswordVisible(!passwordVisible)}  // Toggle visibility
                            >
                                {passwordVisible ? <FaEyeSlash/> : <FaEye/>}
                            </button>
                        </div>

                        <div className="mb-6 relative">
                            <label className="block text-sm font-medium text-darkBrown mb-2"> New
                                Password</label>
                            <input
                                type={newPasswordVisible ? 'text' : 'password'}  // Toggle between 'password' and 'text'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="New password"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-11 text-gray-500"
                                onClick={() => setNewPasswordVisible(!newPasswordVisible)}  // Toggle visibility
                            >
                                {passwordVisible ? <FaEyeSlash/> : <FaEye/>}
                            </button>
                            {passwordError && (
                                <p className="text-red-500 text-sm mt-2">{passwordError}</p>
                            )}
                        </div>

                        <div className="mb-6 relative">
                            <label className="block text-sm font-medium text-darkBrown mb-2">Confirm New
                                Password</label>
                            <input
                                type={confirmPasswordVisible ? 'text' : 'password'}  // Toggle between 'password' and 'text'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm password"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-11 text-gray-500"
                                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}  // Toggle visibility
                            >
                                {passwordVisible ? <FaEyeSlash/> : <FaEye/>}
                            </button>
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="custom-btn-primary w-full"
                        >
                            Submit
                        </button>

                        {error && (
                            <p className="text-red-600 text-sm mt-4 text-center">{error}</p>
                        )}
                        {success && (
                            <p className="text-green-600 text-sm mt-4 text-center">{success}</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PasswordChange;
