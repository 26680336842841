import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../../custom-axios/axios-auth';
import { useParams, useNavigate } from 'react-router-dom';

const AdminProfileFormComponent = () => {
    const { id } = useParams();
    const [profile, setProfile] = useState({ user: { email: '' } });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                const response = await authenticatedInstance.get(`/admin/users/admin/${id}`);
                setProfile(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile details:', error);
                setError('Failed to load profile details.');
                setLoading(false);
            }
        };

        fetchProfileDetails();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile({
            ...profile,
            user: {
                ...profile.user,
                [name]: value
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await authenticatedInstance.put(`/admin/users/admin/${id}`, profile);
            alert('Profile updated successfully');
            navigate(`/admin/users/${id}`);
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Failed to update profile.');
        }
    };

    if (loading) {
        return <p className="loading-message">Loading...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <div className="container profile-form-container">
            <h1>Edit Admin Profile</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={profile.user.email || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <button type="submit" className="submit-button">Save Changes</button>
            </form>
        </div>
    );
};

export default AdminProfileFormComponent;
