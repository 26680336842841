import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from '../../custom-axios/axios';
import {UserContext} from '../../contexts/UserContext';
import './RegisterComponent.css';
import GoogleLoginButton from "../GoogleLogin/GoogleLoginButton";
import {FaFacebook, FaEye, FaEyeSlash} from 'react-icons/fa';  // Import eye icons for show/hide password
import {jwtDecode} from 'jwt-decode';

const RegisterComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false); // State for toggling password visibility
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // For confirm password visibility
    const navigate = useNavigate();
    const {login, fetchUserInfo} = useContext(UserContext);

    const validatePassword = (password) => {
        // Regular expression to check for at least 8 characters, one uppercase letter, and one digit
        const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
        return regex.test(password);
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic regex for email validation
        return emailRegex.test(email);
    };
    const handleRegister = async () => {

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        } else {
            setEmailError('');
        }
        if (!validatePassword(password)) {
            setPasswordError('Password must be at least 8 characters long, include an uppercase letter and a number.');
            return;
        } else {
            setPasswordError('');
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setIsLoading(true); // Start loading spinner
        try {
            const response = await axios.post('/auth/customer/register', {
                firstName,
                lastName,
                user: {
                    email,
                    password
                }
            });
            const token = response.data.jwtToken;
            localStorage.setItem('JWT', token);
            login(token); // Set token and login context

            // Fetch user info before navigating
            const decodedToken = jwtDecode(token);
            await fetchUserInfo(decodedToken.userId);

            setError(null);
            setSuccess("Registration and login successful.");
            navigate('/');  // Redirect to home page after user data is fetched
        } catch (err) {
            setError('Registration failed.');
        } finally {
            setIsLoading(false); // Stop loading spinner
        }
    };

    const handleSocialRegisterSuccess = (token) => {
        setError(null);
        setSuccess("Registration successful with social account.");
        localStorage.setItem('JWT', token);
        login(token);  // Log in and fetch user information immediately
        navigate('/');  // Redirect to home page after successful social registration
    };

    return (
        <div
            className="register-container min-h-screen flex items-center justify-center bg-bgGrayOrange p-4 font-poppins">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h1 className="text-3xl font-bold text-darkBrown mb-6 text-center">Create Your Account</h1>

                {isLoading ? (
                    <div className="text-center">
                        <p>Loading...</p>
                    </div>
                ) : (
                    <>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-darkBrown mb-2">First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="Enter first name"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleRegister()}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-darkBrown mb-2">Last Name</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Enter last name"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleRegister()}
                            />
                        </div>

                        <div className="mb-4 relative">
                            <label className="block text-sm font-medium text-darkBrown mb-2">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleRegister()}
                            />
                            {emailError && <p className="text-red-500 text-sm mt-2">{emailError}</p>}
                        </div>

                        <div className="mb-4 relative">
                            <label className="block text-sm font-medium text-darkBrown mb-2">Password</label>
                            <input
                                type={passwordVisible ? 'text' : 'password'}  // Toggle between 'password' and 'text'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleRegister()}
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-9 text-gray-500 toggle-password-btn"
                                onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                                {passwordVisible ? <FaEyeSlash/> : <FaEye/>}
                            </button>
                            {passwordError && (
                                <p className="text-red-500 text-sm mt-2">{passwordError}</p>
                            )}
                        </div>

                        <div className="mb-6 relative">
                            <label className="block text-sm font-medium text-darkBrown mb-2">Confirm Password</label>
                            <input
                                type={confirmPasswordVisible ? 'text' : 'password'}  // Toggle between 'password' and 'text'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm password"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleRegister()}
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-9 text-gray-500 toggle-password-btn"
                                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                            >
                                {confirmPasswordVisible ? <FaEyeSlash/> : <FaEye/>}
                            </button>

                        </div>

                        <button
                            onClick={handleRegister}
                            className="custom-btn-primary w-full"
                        >
                            Register
                        </button>

                        {/*<div className="flex items-center justify-center my-6">*/}
                        {/*    <span className="text-sm text-gray-500">or register with</span>*/}
                        {/*</div>*/}

                        {/*/!* Social Register Buttons *!/*/}
                        {/*<div className="flex space-x-4 justify-center">*/}
                        {/*    <GoogleLoginButton onLoginSuccess={handleSocialRegisterSuccess}/>*/}
                        {/*    <button*/}
                        {/*        onClick={handleSocialRegisterSuccess}*/}
                        {/*        className="flex items-center justify-center w-full p-3 text-sm text-darkBrown border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300"*/}
                        {/*    >*/}
                        {/*        <FaFacebook className="mr-2 text-lg text-blue-600"/> Facebook*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        {error && (
                            <p className="text-red-600 text-sm mt-4 text-center">{error}</p>
                        )}
                        {success && (
                            <p className="text-green-600 text-sm mt-4 text-center">{success}</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default RegisterComponent;
