import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import authenticatedInstance from '../../custom-axios/axios-auth';
import SellerDetails from './SellerDetails';
import ShopInfo from './ShopInfo';
import './ProductDetails.css';
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from 'date-fns';
import useEditProductPrice from "../../hooks/useEditProductPrice";
import { Helmet } from 'react-helmet';
const ProductDetails = () => {
    const {productId} = useParams();
    const [sellerProfile, setSellerProfile] = useState(null);
    const [userId, setUserId] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);
    const [editingTitle, setEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState('');
    const [editingDescription, setEditingDescription] = useState(false);
    const [newDescription, setNewDescription] = useState('');
    const [newImage, setNewImage] = useState(null);
    const [imageUploadMessage, setImageUploadMessage] = useState('');
    const [showProductOptions, setShowProductOptions] = useState(false);
    const [isOrderFormOpen, setIsOrderFormOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState({});
    const [selectedDate, setSelectedDate] = useState({});
    const [additionalMessage, setAdditionalMessage] = useState('');

    const { changePrice, isLoading, error } = useEditProductPrice();
    const [isEditingPrice, setIsEditingPrice] = useState(false);
    const [newPrice, setNewPrice] = useState(null);
    const [product, setProduct] = useState(null)


    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await authenticatedInstance.get(`/products/${productId}/approved`);

                if (response.status === 200) {
                    setSellerProfile(response.data);
                    // Find product in sellerProfile and set it in state
                    const productFound = response.data.shop.products.find(
                        (p) => p.id === parseInt(productId)
                    );
                    setProduct(productFound); // Set the product state
                    setNewPrice(productFound.price); // Set initial price for editing
                } else {
                    throw new Error('Unexpected response');
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    console.error('Access denied: Product belongs to an unapproved seller.');
                    navigate('/unauthorized');  // Redirect to unauthorized page if seller is not approved
                } else if (error.response && error.response.status === 404) {
                    console.error('Product not found');
                    navigate('/not-found');  // Redirect to not found page if product doesn't exist
                } else {
                    console.error('Error fetching product details:', error);
                }
            }
        };


        const token = localStorage.getItem('JWT'); // Get JWT token from local storage
        if (token) {
            const decoded = jwtDecode(token); // Decode JWT token
            setUserId(decoded.userId); // Set user ID from decoded token
        }

        if (productId) {
            fetchProductDetails();
        }
    }, [productId]);
    const fileInputRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (titleRef.current && !titleRef.current.contains(e.target)) {
                if (editingTitle && newTitle !== product.title) {
                    if (window.confirm("Do you want to save the changes to the title?")) {
                        saveTitleChange();
                    } else {
                        setNewTitle(product.title);
                        setEditingTitle(false);
                    }
                } else {
                    setEditingTitle(false);
                }
            }

            if (descriptionRef.current && !descriptionRef.current.contains(e.target)) {
                if (editingDescription && newDescription !== product.description) {
                    if (window.confirm("Do you want to save the changes to the description?")) {
                        saveDescriptionChange();
                    } else {
                        setNewDescription(product.description);
                        setEditingDescription(false);
                    }
                } else {
                    setEditingDescription(false);
                }
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [editingTitle, newTitle, editingDescription, newDescription]);

    if (!sellerProfile || !sellerProfile.shop) {
        return <p>Loading product details...</p>;
    }



    if (!product) {
        return <p>Product not found</p>;
    }

    const hasImages = product.imageFiles && product.imageFiles.length > 0;
    const isSeller = sellerProfile.user && userId === sellerProfile.user.id;

    const handlePreviousImage = (event) => {
        event.stopPropagation();
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? product.imageFiles.length - 1 : prevIndex - 1
        );
    };

    const handleNextImage = (event) => {
        event.stopPropagation();
        setCurrentImageIndex((prevIndex) =>
            prevIndex === product.imageFiles.length - 1 ? 0 : prevIndex + 1
        );
    };


    const handleImageClick = () => {
        setIsModalOpen(true);
    };
    const handleProductTypeChange = async (productId) => {
        try {
            // Toggle product type between 'PRODUCT' and 'SERVICE'
            const newProductType = product.productType === 'PRODUCT' ? 'SERVICE' : 'PRODUCT';

            // Send request to update product type
            const response = await authenticatedInstance.put(`/products/${productId}/type`, null, {
                params: {value: newProductType}
            });

            if (response.status === 200) {
                // Update product type locally after a successful response
                const updatedProduct = {...product, productType: newProductType};
                const updatedProducts = sellerProfile.shop.products.map((p) =>
                    p.id === productId ? updatedProduct : p
                );
                setSellerProfile({...sellerProfile, shop: {...sellerProfile.shop, products: updatedProducts}});
            } else {
                throw new Error('Failed to update product type');
            }
        } catch (error) {
            console.error('Error changing product type:', error);
        }
    };

    const handleHideProduct = async (productId) => {
        try {
            // Send request to hide the product
            const response = await authenticatedInstance.put(`/products/${productId}/hide`, null, {
                params: {value: true}
            });

            if (response.status === 200) {
                // Update product to hidden locally after a successful response
                const updatedProduct = {...product, isHidden: true};
                const updatedProducts = sellerProfile.shop.products.map((p) =>
                    p.id === productId ? updatedProduct : p
                );
                setSellerProfile({...sellerProfile, shop: {...sellerProfile.shop, products: updatedProducts}});
            } else {
                throw new Error('Failed to hide product');
            }
        } catch (error) {
            console.error('Error hiding product:', error);
        }
    };
    const handleModalClose = (event) => {
        if (event.target.classList.contains('modal-unique')) {
            setIsModalOpen(false);
        }
    };

    const handleSelectAsMainImage = async () => {
        try {
            const imageId = product.imageFiles[currentImageIndex].id; // Change to imageFiles
            const response = await authenticatedInstance.put(`/products/${productId}/main-image`, null, {
                params: { imageId }
            });
            if (response.status === 200) {
                // Update the product images
                const updatedProduct = { ...product, imageFiles: response.data.imageFiles }; // Change to imageFiles
                const updatedProducts = sellerProfile.shop.products.map(p =>
                    p.id === updatedProduct.id ? updatedProduct : p
                );
                setSellerProfile({ ...sellerProfile, shop: { ...sellerProfile.shop, products: updatedProducts } });
                setIsModalOpen(false);
                setCurrentImageIndex(0);
            } else {
                throw new Error('Failed to update main image');
            }
        } catch (error) {
            console.error('Error updating main image:', error);
        }
    };


    const handleRemoveImage = async () => {
        try {
            const imageId = product.imageFiles[currentImageIndex].id; // Change to imageFiles
            const response = await authenticatedInstance.delete(`/products/${productId}/image`, {
                params: { imageId }
            });
            if (response.status === 200) {
                // Update the product images
                const updatedProduct = { ...product, imageFiles: response.data.imageFiles }; // Change to imageFiles
                const updatedProducts = sellerProfile.shop.products.map(p =>
                    p.id === updatedProduct.id ? updatedProduct : p
                );
                setSellerProfile({ ...sellerProfile, shop: { ...sellerProfile.shop, products: updatedProducts } });
                setCurrentImageIndex(0); // Reset to the first image
                setIsModalOpen(false);
            } else {
                throw new Error('Failed to remove image');
            }
        } catch (error) {
            console.error('Error removing image:', error);
        }
    };


    const handleAddImage = async () => {
        if (!newImage) return;

        try {
            const formData = new FormData();
            formData.append('image', newImage);
            const response = await authenticatedInstance.post(`/products/${productId}/image`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            });

            if (response.status === 200) {
                // Assuming response.data now contains both images and imageFiles
                const updatedProduct = {
                    ...product,
                    images: response.data.images,      // Existing images
                    imageFiles: response.data.imageFiles // New imageFiles added
                };

                // Update the products list in the seller profile
                const updatedProducts = sellerProfile.shop.products.map(p =>
                    p.id === updatedProduct.id ? updatedProduct : p
                );

                setSellerProfile({
                    ...sellerProfile,
                    shop: {
                        ...sellerProfile.shop,
                        products: updatedProducts
                    }
                });

                setNewImage(null); // Clear the file input state
                fileInputRef.current.value = ''; // Reset the file input element
                setImageUploadMessage('Image uploaded successfully!');
                setTimeout(() => setImageUploadMessage(''), 3000); // Clear the message after 3 seconds
            } else {
                throw new Error('Failed to add image');
            }
        } catch (error) {
            console.error('Error adding image:', error);
        }
    };


    const handleTitleChange = (e) => {
        setNewTitle(e.target.value);
    };

    const saveTitleChange = async () => {
        try {
            const updatedField = {value: newTitle};
            const response = await authenticatedInstance.put(`/products/${productId}/title`, updatedField);
            if (response.status === 200) {
                const updatedProduct = {...product, title: newTitle};
                const updatedProducts = sellerProfile.shop.products.map(p =>
                    p.id === updatedProduct.id ? updatedProduct : p
                );
                setSellerProfile({...sellerProfile, shop: {...sellerProfile.shop, products: updatedProducts}});
                setEditingTitle(false);
            } else {
                throw new Error('Failed to update product title');
            }
        } catch (error) {
            console.error('Error saving product title:', error);
        }
    };

    const handleDescriptionChange = (e) => {
        setNewDescription(e.target.value);
    };

    const saveDescriptionChange = async () => {
        try {
            const updatedField = {value: newDescription};
            const response = await authenticatedInstance.put(`/products/${productId}/description`, updatedField);
            if (response.status === 200) {
                const updatedProduct = {...product, description: newDescription};
                const updatedProducts = sellerProfile.shop.products.map(p =>
                    p.id === updatedProduct.id ? updatedProduct : p
                );
                setSellerProfile({...sellerProfile, shop: {...sellerProfile.shop, products: updatedProducts}});
                setEditingDescription(false);
            } else {
                throw new Error('Failed to update product description');
            }
        } catch (error) {
            console.error('Error saving product description:', error);
        }
    };
    // Handle date change for ordering
    const handleDateChange = (productId, date) => {
        setSelectedDate(prevDates => ({
            ...prevDates,
            [productId]: date
        }));
    };

    // Handle quantity change for ordering
    const handleQuantityChange = (productId, value) => {
        setQuantity(prevQuantities => ({
            ...prevQuantities,
            [productId]: value
        }));
    };

    // Handle placing an order
    const handleOrderClick = (productId, productName) => {
        const quantitySelected = selectedProduct.productType === 'PRODUCT' ? quantity[productId] : 0;
        const dateSelected = selectedDate[productId];

        if (!dateSelected || (selectedProduct.productType === 'PRODUCT' && quantitySelected <= 0)) {
            alert("Please select a valid date and quantity.");
            return;
        }

        // Convert selected date to Date object if it's not already
        const selectedDateTime = new Date(dateSelected);
        const currentDateTime = new Date();

        // Check if the selected date is at least 1 hour in the future
        const oneHourLater = new Date(currentDateTime.getTime() + 60 * 60 * 1000);

        if (selectedDateTime < oneHourLater) {
            alert("Please select a date and time at least 1 hour in the future.");
            return;
        }

        const productLink = `${process.env.REACT_APP_FRONTEND_URL}/product/${productId}`;

        // Format the selected date into a readable format for the chat message
        const formattedDateForMessage = format(selectedDateTime, 'MMMM d, yyyy h:mm aa');

        // Format the date in ISO format for the backend
        const isoDateForBackend = selectedDateTime.toISOString();

        // Create the order message for the chat, depending on product type (service vs product)
        let orderMessage;
        if (quantitySelected === 0) {
            // Service message
            orderMessage = `I would like to hire you for [${productName}](${productLink}) on ${formattedDateForMessage}.`;
        } else {
            // Product message
            orderMessage = `I would like to order ${quantitySelected} units of [${productName}](${productLink}) to be delivered on ${formattedDateForMessage}.`;
        }

        // Append additional message if provided
        if (additionalMessage.trim()) {
            orderMessage += ` Additional Note: ${additionalMessage.trim()}`;
        }

        const state = {
            userId: userId,
            sellerId: sellerProfile.user.id,
            sellerFullName: sellerProfile.user.fullName,
            prefilledMessage: orderMessage,
            isOrder: true,
            orderDetails: {
                productId: productId,
                productName: productName,
                quantity: quantitySelected,
                deliveryDate: isoDateForBackend  // Send ISO format to the backend
            }
        };


        navigate('/chat', {state});
        setIsOrderFormOpen(false);
    };
    const handleProductDelete = async (productId) => {

        try {
            const response = await authenticatedInstance.delete('/products/deleteproduct', {
                data: {
                    productId: productId,
                    shopId: sellerProfile.shop.id,
                    userId: userId
                }
            });

            if (response.status === 200) {
                setIsDeleteModalOpen(false);
                navigate(`/shop-details/${sellerProfile.shop.id}`);
            }
        }
        catch (error) {
            console.error('Error deleting product:', error);
        }

    }
    const handleSavePrice = () => {
        if (!product) return; // Ensure product is defined
        changePrice(product.id, newPrice).then((updatedProduct) => {
            if (updatedProduct) {
                setIsEditingPrice(false); // End price edit mode
                setProduct(updatedProduct); // Update the product with the new data
                setNewPrice(updatedProduct.price); // Update newPrice with the saved price
            }
        });
    };

    const handleCancelEdit = () => {
        setIsEditingPrice(false); // Cancel price edit mode
        setNewPrice(product.price); // Revert to the original price
    };

    return (
        <div>
            <Helmet>
                <title>{product?.name}</title>
                <meta name="description" content={product?.description} />
            </Helmet>
            <div className="bg-white p-8 font-poppins min-h-screen">
                <div className="product-details shadow-lg rounded-lg p-8 relative">

                    {isSeller && (
                        <div className="absolute top-4 right-4 z-10">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowProductOptions(!showProductOptions);
                                }}
                                className="flex items-center justify-center w-12 h-12 rounded-full bg-lightBrown text-white hover:bg-primaryGreen transition duration-300 ease-in-out shadow-lg transform hover:scale-110"
                            >
                                <EllipsisVerticalIcon className="w-8 h-8"/>
                            </button>
                            {showProductOptions && sellerProfile.subscriptionPlan === "Premium" && (
                                <div
                                    className="absolute right-0 mt-2 w-40 bg-white border border-lightBrown rounded-lg shadow-lg z-50"
                                >
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleHideProduct(product.id);
                                            setShowProductOptions(false);
                                        }}
                                        className="block w-full text-left px-4 py-2 text-sm text-customRed hover:bg-bgGrayOrange transition duration-200"
                                    >
                                        Hide Product
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="flex flex-col lg:flex-row w-full">
                        <div className="w-full lg:w-1/2">
                            {/* Full width on small screens, half on large screens */}
                            <ShopInfo sellerProfile={sellerProfile} userId={userId}/>
                        </div>
                        <div className="w-full lg:w-1/2">
                            {/* Full width on small screens, half on large screens */}
                            <SellerDetails sellerProfile={sellerProfile} userId={userId}/>
                        </div>
                    </div>


                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mb-8">
                        {/* Left Column: Product Image Section */}
                        <div className="col-span-1">
                            {/* Product Image Section */}
                            <div className="flex items-center justify-center space-x-4 mb-6 relative">
                                {hasImages && product.imageFiles.length > 0 ? (
                                    <>
                                        {product.imageFiles.length > 1 && (
                                            <button
                                                onClick={handlePreviousImage}
                                                className="custom-btn-outline absolute left-0 top-1/2 -translate-y-1/2 transform"
                                            >
                                                ←
                                            </button>
                                        )}
                                        <div className="w-full max-w-md mx-auto p-4">
                                            <img
                                                src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${product.imageFiles[currentImageIndex].id}`}
                                                alt={`Product Image ${currentImageIndex + 1}`}
                                                className="rounded-lg shadow-md cursor-pointer object-contain w-full max-h-96"
                                                onClick={handleImageClick}
                                            />
                                        </div>
                                        {product.imageFiles.length > 1 && (
                                            <button
                                                onClick={handleNextImage}
                                                className="custom-btn-outline absolute right-0 top-1/2 -translate-y-1/2 transform"
                                            >
                                                →
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <div className="w-full max-w-md mx-auto p-4 text-center">
                                        <p className="text-lg text-textProductColor">No images available</p>
                                    </div>
                                )}
                            </div>


                            {/* Add Image Section for Seller */}
                            {isSeller && (
                                <div className="flex items-center space-x-4 mb-6">
                                    <input
                                        type="file"
                                        onChange={(e) => setNewImage(e.target.files[0])}
                                        ref={fileInputRef}
                                        className="border border-lightBrown p-2 rounded w-full max-w-xs"
                                    />
                                    <button onClick={handleAddImage} className="custom-btn-primary">
                                        Add Image
                                    </button>
                                    {imageUploadMessage && (
                                        <p className="text-sm text-primaryGreen">{imageUploadMessage}</p>
                                    )}
                                </div>
                            )}

                        </div>

                        {/* Right Column: Shop Information and Seller Details */}
                        <div
                            className="col-span-1 flex flex-col justify-center items-center space-y-6 w-full max-w-full px-4">
                            {/* Product Title Section */}
                            <div ref={titleRef} className="product-title text-center mb-6 w-full">
                                {isSeller ? (
                                    editingTitle ? (
                                        <input
                                            type="text"
                                            value={newTitle}
                                            onChange={handleTitleChange}
                                            onBlur={saveTitleChange}
                                            autoFocus
                                            className="border border-darkBrown p-2 rounded w-full text-xl text-darkBrown font-bold text-center"
                                            placeholder="Enter product title"
                                        />
                                    ) : (
                                        <h2
                                            onClick={() => {
                                                setEditingTitle(true);
                                                setNewTitle(product.title);
                                            }}
                                            className="text-3xl md:text-4xl font-lora text-darkBrown font-bold cursor-pointer hover:underline overflow-hidden text-ellipsis whitespace-nowrap"
                                            title={product.title} // Show full title on hover
                                        >
                                            {product.title}
                                        </h2>
                                    )
                                ) : (
                                    <h2
                                        className="text-3xl md:text-4xl font-manrope text-textProductColor font-bold overflow-hidden text-ellipsis whitespace-nowrap"
                                        title={product.title}
                                    >
                                        {product.title}
                                    </h2>
                                )}
                            </div>


                            {/* Product Description Section */}
                            <div ref={descriptionRef} className="product-description text-center mb-6 w-full px-4">
                                {isSeller ? (
                                    editingDescription ? (
                                        <textarea
                                            value={newDescription}
                                            onChange={handleDescriptionChange}
                                            onBlur={saveDescriptionChange}
                                            autoFocus
                                            rows="5"
                                            className="border border-darkBrown p-4 rounded w-full text-darkBrown"
                                        />
                                    ) : (
                                        <p
                                            onClick={() => {
                                                setEditingDescription(true);
                                                setNewDescription(product.description);
                                            }}
                                            className="text-lg text-darkBrown cursor-pointer hover:underline w-full break-words"
                                            title={product.description} // Show full description on hover
                                        >
                                            {product.description}
                                        </p>
                                    )
                                ) : (
                                    <p className="text-lg text-textProductColor w-full break-words">
                                        {product.description}
                                    </p>
                                )}
                            </div>


                            <div className="product-title text-center mb-6">

                                {/* Price Edit (Only visible to seller) */}
                                {isSeller && isEditingPrice ? (
                                    <div className="flex flex-col items-center space-y-2">
                                        <input
                                            type="number"
                                            value={newPrice}
                                            onChange={(e) => setNewPrice(e.target.value)} // Update price
                                            onClick={(e) => e.stopPropagation()} // Prevent event propagation
                                            onFocus={(e) => e.stopPropagation()} // Prevent event propagation
                                            className="border p-2 rounded w-full max-w-xs border-darkBrown text-darkBrown text-center font-raleway font-bold text-xl"
                                        />
                                        <div className="flex flex-col space-y-2">
                                            <button
                                                onClick={handleSavePrice}
                                                className="custom-btn-primary"
                                            >
                                                {isLoading ? 'Saving...' : 'Save Price'}
                                            </button>
                                            <button
                                                onClick={handleCancelEdit}
                                                className="custom-btn-outline"
                                            >
                                                Cancel
                                            </button>
                                            {error && <p className="text-red-500">{error}</p>}
                                        </div>
                                    </div>
                                ) : (
                                    <h2
                                        className={`text-4xl font-lora font-bold ${sellerProfile.productType === 'PRODUCT' ? 'text-primaryProductColor' : 'text-primaryServiceColor'}`}
                                        onClick={() => isSeller && setIsEditingPrice(true)} // Start price edit on click if user is seller
                                    >
                                        {newPrice}$
                                    </h2>
                                )}
                            </div>
                            {/* Order Button for Non-Sellers */}
                            {!isSeller && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedProduct(product);
                                        setIsOrderFormOpen(true);
                                    }}
                                    className="px-4 py-2 bg-primaryProductColor text-white rounded-none w-full mt-4 hover:bg-secondaryProductColor transition"
                                >
                                    Order
                                </button>
                            )}
                            {isSeller && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedProduct(product);
                                        setIsDeleteModalOpen(true)
                                    }}
                                    className="px-4 py-2 bg-primaryProductColor text-white rounded-none w-full mt-4 hover:bg-secondaryProductColor transition"
                                >
                                    Delete product
                                </button>
                            )}

                            {/* Order Form Modal */}
                            {isOrderFormOpen && selectedProduct && (
                                <div
                                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                    <div
                                        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg relative font-poppins">
                                        <h2 className="text-3xl font-bold text-darkBrown mb-6 text-center">
                                            Order {selectedProduct.title}
                                        </h2>

                                        {/* Quantity for Product Type */}
                                        {selectedProduct.productType === 'PRODUCT' && (
                                            <label className="block text-sm mb-6 text-darkBrown">
                                                Quantity:
                                                <input
                                                    type="number"
                                                    value={quantity[selectedProduct.id] || ''}
                                                    onChange={(e) => handleQuantityChange(selectedProduct.id, e.target.value)}
                                                    min="1"
                                                    className="block mt-2 w-full border p-3 rounded-lg border-lightBrown focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                                                />
                                            </label>
                                        )}

                                        {/* Delivery Date and Time */}
                                        <div className="block text-sm mb-6 text-darkBrown">
                                            <label className="block mb-2">Delivery Date and Time:</label>
                                            <DatePicker
                                                selected={selectedDate[selectedProduct.id] || null}
                                                onChange={(date) => handleDateChange(selectedProduct.id, date)}
                                                showTimeSelect
                                                timeFormat="hh:mm aa" // AM/PM format
                                                timeIntervals={15}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                minDate={new Date()}
                                                placeholderText="Select a delivery date and time"
                                                className="block w-full border p-3 rounded-lg mt-1 border-lightBrown focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                                                popperClassName="font-poppins z-50"
                                                shouldCloseOnSelect={true}
                                            />
                                        </div>

                                        {/* Additional Message */}
                                        <label className="block text-sm mb-6 text-darkBrown">
                                            Additional Message (optional):
                                            <textarea
                                                value={additionalMessage}
                                                onChange={(e) => setAdditionalMessage(e.target.value)}
                                                placeholder="Any additional requests or information..."
                                                className="block mt-2 w-full border p-3 rounded-lg border-lightBrown focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                                            />
                                        </label>

                                        {/* Order Button */}
                                        <button
                                            onClick={() => handleOrderClick(selectedProduct.id, selectedProduct.title)}
                                            className="custom-btn-primary w-full mt-4"
                                        >
                                            Place Order
                                        </button>

                                        {/* Cancel Button */}
                                        <button
                                            onClick={() => setIsOrderFormOpen(false)}
                                            className="mt-4 custom-btn-secondary w-full"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isDeleteModalOpen && selectedProduct && (
                                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
                                        <h2 className="text-lg font-bold mb-4">Are you sure?</h2>
                                        <p className="mb-6">Do you really want to delete this product? This action
                                            cannot be undone.</p>
                                        <div className="flex justify-end space-x-4">
                                            <button
                                                onClick={() => setIsDeleteModalOpen(false)}
                                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => handleProductDelete(selectedProduct.id)}
                                                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )


                            }
                        </div>
                    </div>
                </div>


                {/* Product Details Section */}


                {/* Modal for Viewing and Managing Images */}
                {isModalOpen && hasImages && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                        onClick={handleModalClose}
                    >
                        <div className="bg-white rounded-lg p-8 shadow-2xl max-w-3xl w-full relative">
                            <button
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevent click from propagating to the modal background
                                    setIsModalOpen(false);  // Close the modal
                                }}
                                className="absolute top-4 right-4 text-darkBrown hover:text-primaryGreen transition duration-300 text-2xl"
                            >
                                &times;
                            </button>

                            {product.imageFiles.length > 1 && (
                                <button
                                    onClick={handlePreviousImage}
                                    className="custom-btn-outline absolute left-0 top-1/2 -translate-y-1/2 transform"
                                >
                                    ←
                                </button>
                            )}

                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${product.imageFiles[currentImageIndex].id}`}
                                alt={`Product Image ${currentImageIndex + 1}`}
                                className="w-full max-h-96 object-contain rounded-lg shadow-lg"
                            />

                            {product.imageFiles.length > 1 && (
                                <button
                                    onClick={handleNextImage}
                                    className="custom-btn-outline absolute right-0 top-1/2 -translate-y-1/2 transform"
                                >
                                    →
                                </button>
                            )}

                            {isSeller && (
                                <div className="flex justify-center mt-6 space-x-4">
                                    <button
                                        onClick={handleSelectAsMainImage}
                                        className="custom-btn-primary"
                                    >
                                        Select as Main Image
                                    </button>
                                    <button
                                        onClick={handleRemoveImage}
                                        className="custom-btn-remove"
                                    >
                                        Remove Image
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}


            </div>
        </div>
    );


};

export default ProductDetails;
