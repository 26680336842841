import { useEffect, useState } from 'react';
import authenticatedInstance from "../custom-axios/axios-auth";

const useOrder = (orderId) => {
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await authenticatedInstance.get(`/orders/${orderId}`);
                setOrder(response.data);
            } catch (err) {
                setError(err.response ? err.response.data.message : 'Failed to fetch order');
            } finally {
                setLoading(false);
            }
        };

        fetchOrder();
    }, [orderId]);

    return { order, error, loading };
};

export default useOrder;
