import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth'; // Import your authenticated axios instance
import './UsersComponent.css';
import { useNavigate } from 'react-router-dom';


const UsersComponent = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await authenticatedInstance.get('/admin/users');
            setUsers(response.data); // Assuming response.data is an array of UserExportDTO
            setLoading(false); // Set loading to false after data fetch
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to load users.');
            setLoading(false);
        }
    };

    const handleCardClick = (userId) => {
        navigate(`/admin/user/${userId}`);
    };

    const getRoleText = (roles) => {
        const roleTexts = [];
        if (roles.includes('CUSTOMER')) {
            roleTexts.push(
                <p key="customer">
                    The user is a <span className="role-customer">Customer</span>.
                </p>
            );
        }
        if (roles.includes('SELLER')) {
            roleTexts.push(
                <p key="seller">
                    The user is a <span className="role-seller">Seller</span>.
                </p>
            );
        }
        if (roles.includes('ADMINISTRATOR')) {
            roleTexts.push(
                <p key="admin">
                    The user has <span className="role-admin">Administrative privileges</span>.
                </p>
            );
        }
        return roleTexts;
    };

    return (
        <div className="container users-container">
            <h1>Users List</h1>
            {loading ? (
                <p className="loading-message">Loading...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <ul>
                    {users.map(user => (
                        <li key={user.id} className="user-card" onClick={() => navigate(`/admin/users/${user.id}`)}>
                            <p><strong>Email:</strong> {user.email}</p>
                            {getRoleText(user.roles)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default UsersComponent;
