import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useOrder from "../hooks/useOrder";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
    const { orderId } = useParams(); // Get orderId from URL
    const { order: initialOrder, error, loading } = useOrder(orderId); // Fetch order data with custom hook
    const [order, setOrder] = useState(initialOrder); // Create state for order

    // Update order state when the fetched order changes
    useEffect(() => {
        setOrder(initialOrder);
    }, [initialOrder]);

    const value = {
        orderId,
        order,
        setOrder, // Include setOrder in the context value
        error,
        loading,
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => useContext(OrderContext);
