import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';

const AddHolidayCategoryForm = ({ shopId, fetchShopDetails, currentHolidays }) => {
    const [holidays, setHolidays] = useState([]);
    const [selectedHoliday, setSelectedHoliday] = useState(null);

    useEffect(() => {
        const fetchHolidays = async () => {
            try {
                const response = await authenticatedInstance.get('/categories/holidays');
                const availableHolidays = response.data.filter(
                    (holiday) => !currentHolidays.some((current) => current.id === holiday.id)
                );
                setHolidays(availableHolidays);
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, [currentHolidays]);

    const handleAddHoliday = async () => {
        if (selectedHoliday) {
            try {
                await authenticatedInstance.post(`/shops/${shopId}/category-holiday/${selectedHoliday}`);
                fetchShopDetails();
                setSelectedHoliday(null);
            } catch (error) {
                console.error('Error adding holiday:', error);
            }
        }
    };

    return (
        <div className="mt-4">
            <select
                onChange={(e) => setSelectedHoliday(e.target.value)}
                value={selectedHoliday || ''}
                className="border border-gray-300 rounded-lg px-3 py-2 w-full"
            >
                <option value="" disabled>Select a holiday</option>
                {holidays.map((holiday) => (
                    <option key={holiday.id} value={holiday.id}>
                        {holiday.name}
                    </option>
                ))}
            </select>
            <button className="mt-2 px-4 py-2 bg-primaryGreen text-white rounded-lg hover:bg-darkBrown transition" onClick={handleAddHoliday}>
                Add Holiday
            </button>
        </div>
    );
};

export default AddHolidayCategoryForm;
