import React, { useState } from 'react';

const SubscriptionSelectionComponent = ({ onSelectPlan }) => {
    const [productType, setProductType] = useState('PRODUCT'); // Default product type is 'PRODUCT'
    const [expandedCard, setExpandedCard] = useState(null); // Tracks which card is expanded

    // Colors based on productType
    const colors = {
        primary: productType === 'PRODUCT' ? '#B22a7a' : '#1e3f16',
        secondary: productType === 'PRODUCT' ? '#f49ac1' : '#C5E09D',
        tertiary: '#F0FCF8',
        textPrimary: productType === 'PRODUCT' ? '#B23278' : '#253329',
    };

    const handlePlanSelect = (plan) => {
        let finalProductType = productType;
        let productLimit = 1;

        switch (plan) {
            case 'PREMIUM':
                productLimit = 30;
                break;
            case 'STANDARD':
                productLimit = 10;
                break;
            case 'PREMIUM_DUAL':
                finalProductType = 'BOTH'; // Both product types
                productLimit = 30;
                break;
            default:
                productLimit = 1;
        }

        onSelectPlan({ plan, productType: finalProductType, productLimit });
    };

    const handleProductTypeChange = (type) => {
        setProductType(type);
    };

    const toggleDescription = (e, planKey) => {
        e.stopPropagation(); // Prevent the card onClick from triggering
        setExpandedCard((prevKey) => (prevKey === planKey ? null : planKey));
    };

    const descriptionLimit = 150;

    const plans = [
        {
            name: 'Standard Plan',
            price: 69.99,
            key: 'STANDARD',
            description: `Our Standard Plan is the perfect choice for entrepreneurs who are just starting out or are focused on offering a select range of products or services. This plan equips you with the essential tools to manage your store with ease, all backed by our dedicated 24/7 support.`,
            trial: 'Free Trial until March 1st, 2025 – Enjoy full access before your first payment!',
            productLimit: 'Product/Service Limit – Upload and manage up to 10 products or services.',
            calendar: 'Personalized Calendar – Track appointments with reminders for up to 3 years.',
            personalVideos: 'Personal Videos – Specialized for you and your unique shop.',
            emailReceipts: 'Email Receipts – Automatically send receipts for every purchase.',
            newsletter: 'Monthly Newsletter – Stay ahead with important dates, upcoming holidays, and seasonal tips.',
        },
        {
            name: 'Premium Plan',
            price: 99.99,
            key: 'PREMIUM',
            description: `The Premium Plan is designed for experienced entrepreneurs who are managing a larger catalog of products or services and are ready to scale their business. This plan offers advanced features to help you stay ahead of trends and meet customer demands with ease.`,
            trial: '90 Days Free Trial – Test all Premium features for 3 months with no charge!',
            productLimit: 'Product/Service Limit – Upload and manage up to 30 products or services, with the ability to mark items as "Sold Out".',
            calendar: 'Personalized Calendar – Track appointments with reminders, stored for up to 3 years.',
            personalVideos: 'Personal Videos – Specialized for you and your unique shop.',
            emailReceipts: 'Email Receipts – Automatically send professional receipts for every transaction.',
            newsletter: 'Monthly Newsletter – Get exclusive access to our monthly newsletter with business tips.',
            dataInsights: 'Data Insights – Weekly reports on your most viewed and best-selling products.',
            automatedEmails: 'Automated Emails – Keep your customers engaged with automatic reminders for events.',
        },
    ];

    return (
        <div
            className="subscription-selection-container min-h-screen flex flex-col items-center justify-center"
            style={{ backgroundColor: colors.tertiary }}
        >
            <h2 className="text-3xl font-bold mb-6" style={{ color: colors.primary }}>Choose Your Plan to Register</h2>

            {/* Trial Explanation */}
            <p className="text-center mb-8 text-lg" style={{ color: colors.textPrimary }}>
                Not sure which plan to pick? No worries! The free trial is till the 1st of March of any plan you select, and there's no immediate commitment.
                This just helps us tailor your experience.
            </p>

            <div className="plans grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-4xl">
                {plans.map((plan) => (
                    <div className="plan-card-wrapper" key={plan.key}>
                        <div
                            onClick={() => handlePlanSelect(plan.key)}
                            className="plan-card shadow-md rounded-lg p-6 transition duration-300 cursor-pointer transform hover:scale-105 relative"
                            style={{
                                backgroundColor: colors.secondary,
                                color: colors.textPrimary,
                                border: `2px solid ${colors.primary}`,
                            }}
                        >
                            {/* "Click to Select" Overlay at Top Right */}
                            <div
                                className="absolute top-2 right-2 bg-black bg-opacity-30 text-white px-3 py-1 rounded text-xs font-semibold"
                                style={{ color: colors.tertiary }}
                            >
                                Click to Select
                            </div>

                            <h3 className="text-xl font-semibold mb-2" style={{ color: colors.primary }}>
                                {plan.name}
                            </h3>
                            <p className="text-lg font-bold" style={{ color: colors.primary }}>
                                ${plan.price}
                            </p>

                            {/* Conditionally render part of the description */}
                            <div className="mt-4">
                                {expandedCard === plan.key ? (
                                    <>
                                        <p>{plan.description}</p>
                                        <div className="mt-2 text-sm">
                                            <p>{plan.trial}</p>
                                            <p>{plan.productLimit}</p>
                                            <p>{plan.calendar}</p>
                                            <p>{plan.personalVideos}</p>
                                            <p>{plan.emailReceipts}</p>
                                            <p>{plan.newsletter}</p>
                                            {plan.dataInsights && <p>{plan.dataInsights}</p>}
                                            {plan.automatedEmails && <p>{plan.automatedEmails}</p>}
                                        </div>
                                        <button
                                            className="text-sm mt-2 font-bold"
                                            onClick={(e) => toggleDescription(e, plan.key)}
                                            style={{ color: colors.primary }}
                                        >
                                            Show Less
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            {plan.description.length > descriptionLimit
                                                ? `${plan.description.substring(0, descriptionLimit)}...`
                                                : plan.description}
                                        </p>
                                        {plan.description.length > descriptionLimit && (
                                            <button
                                                className="text-sm mt-2 font-bold"
                                                onClick={(e) => toggleDescription(e, plan.key)}
                                                style={{ color: colors.primary }}
                                            >
                                                Show More
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>

                            {/* Product type selection for Standard and Premium plans */}
                            {(plan.key === 'STANDARD' || plan.key === 'PREMIUM') && (
                                <div className="mt-4">
                                    <label className="mr-2" style={{ color: colors.textPrimary }}>What will your shop be offering?</label>
                                    <select
                                        value={productType}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => handleProductTypeChange(e.target.value)}
                                        className="border border-gray-300 rounded p-2"
                                        style={{ backgroundColor: colors.tertiary, color: colors.textPrimary }}
                                    >
                                        <option value="PRODUCT">Products</option>
                                        <option value="SERVICE">Services</option>
                                    </select>
                                </div>
                            )}

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SubscriptionSelectionComponent;
