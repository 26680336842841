import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';

const OrderSortingContext = createContext();

export const useOrderSorting = () => {
    const context = useContext(OrderSortingContext);
    if (!context) {
        throw new Error("useOrderSorting must be used within an OrderSortingProvider");
    }
    return context;
};

export const OrderSortingProvider = ({ children }) => {
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedTitles, setSelectedTitles] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc'); // default sort order

    const sortOrders = (orders) => {
        // First filter orders based on selected status and titles
        const filteredOrders = orders.filter(order => {
            const matchesStatus = selectedStatus.length === 0 || selectedStatus.includes(order.status);
            const matchesTitle = selectedTitles.length === 0 || selectedTitles.includes(order.productTitle);
            return matchesStatus && matchesTitle;
        });

        // Then sort the filtered orders
        return [...filteredOrders].sort((a, b) => {
            let comparison = 0;
            if (sortOrder === 'asc') {
                comparison = dayjs(a.dateDelivery).isBefore(dayjs(b.dateDelivery)) ? -1 : 1;
            } else if (sortOrder === 'desc') {
                comparison = dayjs(a.dateDelivery).isAfter(dayjs(b.dateDelivery)) ? -1 : 1;
            } else if (sortOrder === 'statusAsc') {
                comparison = a.status.localeCompare(b.status); // Ascending status sort
            } else if (sortOrder === 'statusDesc') {
                comparison = b.status.localeCompare(a.status); // Descending status sort
            }
            return comparison;
        });
    };



    const value = {
        selectedStatus,
        setSelectedStatus,
        selectedTitles,
        setSelectedTitles,
        sortOrder,
        setSortOrder,
        sortOrders,
    };

    return (
        <OrderSortingContext.Provider value={value}>
            {children}
        </OrderSortingContext.Provider>
    );
};
