import React, { useState, useEffect } from 'react';
import AddFilterCategoryForm from './AddFilterCategoryForm';
import AddHolidayCategoryForm from './AddHolidayCategoryForm';
import authenticatedInstance from '../../custom-axios/axios-auth';

const CategoryList = ({ filters, services,holidays, shopId, fetchShopDetails, userId,  isSeller,sellerProfile }) => {
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
    const [shop, setShop] = useState(null);

    useEffect(() => {
        if (userId) {
            const fetchUser = async () => {
                try {
                    const response = await authenticatedInstance.get(`/home/findShopByUserId/${userId}`);
                    if (response.status === 200) {
                        setShop(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching user review:', error);
                }
            };
            fetchUser();
        }
    }, [userId]);

    const handleRemoveCategory = async (categoryId, type) => {

        try {
            const response = await authenticatedInstance.delete(`/shops/${shopId}/${type}/${categoryId}`);
            if (response.status === 200) {
                fetchShopDetails();
            } else {
                throw new Error('Failed to remove category');
            }
        } catch (error) {
            console.error('Error removing category:', error);
        }
    };

    return (
        <div className="space-y-2">
            {/* List of Categories (Filters + Holidays) */}
            <ul className="space-y-2">
                {[...filters, ...holidays, ...services].map((category) => (
                    <li key={category.id} className="flex justify-between items-center">
                        <span>{category.name}</span>
                        {isSeller && (
                            <button
                                className="text-red-500 hover:text-red-700"
                                onClick={() => {
                                    let categoryType = 'category-holiday'; // default
                                    if (services.includes(category) || filters.includes(category)) {
                                        categoryType = 'category-filter';
                                    }
                                    handleRemoveCategory(category.id, categoryType);
                                }}
                            >
                                Remove
                            </button>
                        )}
                    </li>
                ))}
            </ul>

            {/* Form components for adding filters and holidays */}
            {isSeller && (
                <div className="mt-6">
                    <AddFilterCategoryForm sellerProfile={sellerProfile} shopId={shopId} fetchShopDetails={fetchShopDetails} currentFilters={filters} />
                    <AddHolidayCategoryForm shopId={shopId} fetchShopDetails={fetchShopDetails} currentHolidays={holidays} />
                </div>
            )}
        </div>
    );
};

export default CategoryList;
