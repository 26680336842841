import React from 'react';
import useInitiateChat from "../../../hooks/useInitiateChat";

const ChatButton = ({ sellerProfile, userId }) => {
    const initiateChat = useInitiateChat();

    if (!userId || userId === sellerProfile.user.id) return null;

    return (
        <button
            onClick={() => initiateChat(sellerProfile)}
            className="text-primaryGreen hover:underline ml-1 font-raleway"
        >
            Chat with {sellerProfile.firstName} {sellerProfile.lastName}
        </button>
    );
};

export default ChatButton;
