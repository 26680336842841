// src/hooks/useJwtDecoder.js

import { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

// Custom hook to decode JWT and provide user information
const useJwtDecoder = () => {
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('JWT');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUser(decodedToken);
                setRoles(decodedToken.roles || []);
                setIsLoggedIn(true);
                setIsEnabled(decodedToken.isEnabled);
            } catch (error) {
                console.error('Failed to decode token:', error);
                setIsLoggedIn(false);
                setIsEnabled(false);
            }
        }
    }, []);

    return { user, roles, isLoggedIn, isEnabled };
};

export default useJwtDecoder;
