import React, { useState, useEffect } from "react";
import authenticatedInstance from "../../../custom-axios/axios-auth";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UpgradeSubscription from "./UpgradeSubscription";
import DowngradeSubscription from "./DowngradeSubscription";
import { FiArrowUpRight, FiArrowDownLeft } from "react-icons/fi";

// Load Stripe
const stripePromise = loadStripe("pk_live_51QEYaZ01STgJKTwletdR1ax7FDnUzI8BRDoLl3mNDOctvHjmDbjCx3zXVhV6ovuXbDFfSLjsITIOtIUfvXSh8CnM00MRXLdvfS");

// Available plans with priceId
const plans = [
    { name: "Standard", priceId: "price_1QEa1h01STgJKTwl5ggLTevf", price: 69.99, enumName: "STANDARD" },
    { name: "Premium", priceId: "price_1QEa2E01STgJKTwl0PfGTbBO", price: 99.99, enumName: "PREMIUM" },
];

const SubscriptionManagement = () => {
    const [currentPlan, setCurrentPlan] = useState(null); // Current plan priceId
    const [subscriptionId, setSubscriptionId] = useState(null); // Stripe subscriptionId
    const [isUpgrade, setIsUpgrade] = useState(true);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [filteredAvailablePlans, setFilteredAvailablePlans] = useState([]);

    // Fetch the current subscription details (subscriptionId and current plan priceId)
    useEffect(() => {
        authenticatedInstance
            .get("/subscription/current-subscription") // Call to get the current subscription data
            .then((response) => {
                const { id, priceId } = response.data; // Correct extraction based on the DTO
                setSubscriptionId(id); // Set the subscriptionId
                setCurrentPlan(priceId); // Set the current plan's priceId
            })
            .catch((error) => {
                setMessage(`Error: ${error.message}`);
            });
    }, []);

    // Filter plans based on upgrade or downgrade
    useEffect(() => {
        if (currentPlan) {
            const currentPlanDetails = plans.find((p) => p.priceId === currentPlan);
            if (currentPlanDetails) {
                const filteredPlans = plans.filter((plan) =>
                    isUpgrade
                        ? plan.price > currentPlanDetails.price // Show only more expensive plans for upgrades
                        : plan.price < currentPlanDetails.price // Show only cheaper plans for downgrades
                );
                setFilteredAvailablePlans(filteredPlans);
            }
        }
    }, [currentPlan, isUpgrade]);

    const handleUpgradeSuccess = (newPriceId) => {
        const upgradedPlan = plans.find((plan) => plan.priceId === newPriceId);
        if (upgradedPlan) {
            setMessage(`Successfully upgraded to ${upgradedPlan.name}`);
            setCurrentPlan(newPriceId); // Update current plan to the new plan
        }
    };

    if (!subscriptionId || !currentPlan) {
        return <div className="text-center text-lg mt-4">Loading current subscription...</div>;
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                <h1 className="text-2xl font-bold text-center mb-6">Manage Subscription</h1>

                <div className="mb-4">
                    <button
                        onClick={() => setIsUpgrade(true)}
                        className={`mr-4 py-2 px-4 rounded-lg ${isUpgrade ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-700"} hover:bg-indigo-700`}
                    >
                        <FiArrowUpRight className="inline mr-2" /> Upgrade
                    </button>
                    <button
                        onClick={() => setIsUpgrade(false)}
                        className={`py-2 px-4 rounded-lg ${!isUpgrade ? "bg-green-600 text-white" : "bg-gray-200 text-gray-700"} hover:bg-green-700`}
                    >
                        <FiArrowDownLeft className="inline mr-2" /> Downgrade
                    </button>
                </div>

                {isUpgrade ? (
                    <UpgradeSubscription
                        subscriptionId={subscriptionId} // Pass the subscriptionId correctly
                        currentPlan={currentPlan} // Pass the current plan's priceId
                        availablePlans={filteredAvailablePlans} // Pass the available plans for upgrade
                        setMessage={setMessage}
                        setLoading={setIsLoading}
                        onUpgradeSuccess={handleUpgradeSuccess} // Handle success after upgrade
                    />
                ) : (
                    <DowngradeSubscription
                        currentPlan={currentPlan}
                        availablePlans={filteredAvailablePlans}
                        setMessage={setMessage}
                        setLoading={setIsLoading}
                    />
                )}

                {message && <p className="mt-6 text-center text-red-500">{message}</p>}
            </div>
        </div>
    );
};

const AppWrapper = () => (
    <Elements stripe={stripePromise}>
        <SubscriptionManagement />
    </Elements>
);

export default AppWrapper;
