import React, {useEffect, useState} from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';

const AddFilterCategoryForm = ({sellerProfile, shopId, fetchShopDetails, currentFilters}) => {
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    useEffect(() => {
        const fetchFilters = async () => {
            try {
                var response;
                if (sellerProfile.shop.productType === "PRODUCT") {
                    response = await authenticatedInstance.get('/categories/filters/product');
                } else if (sellerProfile.shop.productType === "SERVICE") {
                    response = await authenticatedInstance.get('/categories/filters/service');
                }
                const availableFilters = response.data.filter(
                    (filter) => !currentFilters.some((current) => current.id === filter.id)
                );
                setFilters(availableFilters);
            } catch (error) {
                console.error('Error fetching filters:', error);
            }
        };

        fetchFilters();
    }, [currentFilters]);

    const handleAddFilter = async () => {
        if (selectedFilter) {
            try {
                await authenticatedInstance.post(`/shops/${shopId}/category-filter/${selectedFilter}`);
                fetchShopDetails();
                setSelectedFilter(null);
            } catch (error) {
                console.error('Error adding filter:', error);
            }
        }
    };

    return (
        <div className="mt-4">
            <select
                onChange={(e) => setSelectedFilter(e.target.value)}
                value={selectedFilter || ''}
                className="border border-gray-300 rounded-lg px-3 py-2 w-full"
            >
                <option value="" disabled>Select a filter</option>
                {filters.map((filter) => (
                    <option key={filter.id} value={filter.id}>
                        {filter.name}
                    </option>
                ))}
            </select>
            <button className="mt-2 px-4 py-2 bg-primaryGreen text-white rounded-lg hover:bg-darkBrown transition"
                    onClick={handleAddFilter}>
                Add Filter
            </button>
        </div>
    );
};

export default AddFilterCategoryForm;
