import { useState } from 'react';
import authenticatedInstance from "../custom-axios/axios-auth";
const useEditProductPrice = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to handle the price change request
    const changePrice = async (productId, newPrice) => {
        setIsLoading(true);
        setError(null);

        try {
            // Post request to backend to change the price
            const response = await authenticatedInstance.post('/products/change/price', {
                productId: productId,
                newPrice: newPrice
            });

            // Return the updated product from the response
            return response.data; // This is the ProductExportDTO
        } catch (error) {
            console.error('Error changing the price:', error);
            setError('Failed to update price');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    return { changePrice, isLoading, error };
};

export default useEditProductPrice;
