import React from 'react';

const AdminProfileComponent = ({ profile }) => {
    if (!profile) {
        return (
            <div className="profile-section">
                <h2>Admin Profile</h2>
                <button className="create-profile-button">Create Admin Profile</button>
            </div>
        );
    }

    return (
        <div className="profile-section">
            <h2>Admin Profile</h2>
            <p>This user has administrative privileges.</p>
        </div>
    );
};

export default AdminProfileComponent;
