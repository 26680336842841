import { useEffect } from 'react';

const useThemeSwitcher = (selected) => {
    useEffect(() => {
        const root = document.documentElement;

        root.style.setProperty('--svg-text-color', 'var(--primary-product-color)');
        root.style.setProperty('--h2-color', 'var(--primary-service-color)');
        root.style.setProperty('--h3-color', 'var(--primary-service-color)');
        root.style.setProperty('--border-color', 'black');
        root.style.setProperty('--products-btn-color', 'var(--secondary-product-color)');
        root.style.setProperty('--services-btn-color', 'var(--secondary-service-color)');
        root.style.setProperty('--p-text-color', 'var(--primary-product-color)');
        root.style.setProperty('--search-btn-color', 'var(--secondary-product-color)');
        root.style.setProperty('--search-btn-text', 'var(--text-product-color)');
        root.style.setProperty('--circle-bg-color', 'var(--tertiary-product-color)');
        root.style.setProperty('--circle-border-color', 'black');

        // if (selected === 'product') {
        //     // Use the product color variables
        //     root.style.setProperty('--svg-text-color', 'var(--primary-product-color)');
        //     root.style.setProperty('--h2-color', 'var(--primary-product-color)');
        //     root.style.setProperty('--h3-color', 'var(--secondary-product-color)');
        //     root.style.setProperty('--border-color', 'var(--secondary-product-color)');
        //     root.style.setProperty('--products-btn-color', 'var(--secondary-product-color)');
        //     root.style.setProperty('--services-btn-color', 'var(--secondary-service-color)');
        //     root.style.setProperty('--p-text-color', 'var(--secondary-product-color)');
        //     root.style.setProperty('--search-btn-color', 'var(--secondary-product-color)');
        //     root.style.setProperty('--search-btn-text', 'var(--text-product-color)');
        //     root.style.setProperty('--circle-bg-color', 'var(--tertiary-product-color)');
        //     root.style.setProperty('--circle-border-color', 'var(--primary-product-color)');
        // } else {
        //     // Use the service color variables
        //     root.style.setProperty('--svg-text-color', 'var(--primary-service-color)');
        //     root.style.setProperty('--h2-color', 'var(--primary-service-color)');
        //     root.style.setProperty('--h3-color', 'var(--secondary-service-color)');
        //     root.style.setProperty('--border-color', 'var(--secondary-service-color)');
        //     root.style.setProperty('--products-btn-color', 'var(--secondary-product-color)');
        //     root.style.setProperty('--services-btn-color', 'var(--secondary-service-color)');
        //     root.style.setProperty('--p-text-color', 'var(--secondary-service-color)');
        //     root.style.setProperty('--search-btn-color', 'var(--secondary-service-color)');
        //     root.style.setProperty('--search-btn-text', 'var(--text-service-color)');
        //     root.style.setProperty('--circle-bg-color', 'var(--tertiary-service-color)');
        //     root.style.setProperty('--circle-border-color', 'var(--primary-service-color)');
        // }
    }, [selected]);
};

export default useThemeSwitcher;
