import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import authenticatedInstance from '../../custom-axios/axios-auth';

const LogoutComponent = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    useEffect(() => {
        const logout = async () => {
            try {
                const token = localStorage.getItem('JWT');
                if (token) {
                    await authenticatedInstance.post('/auth/logout', {}, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                }
                // Remove the JWT token from local storage
                localStorage.removeItem('JWT');


                navigate('/', { replace: true });

            } catch (error) {
                console.error('Logout failed:', error);
                localStorage.removeItem('JWT');
                navigate(location.state?.from || '/', { replace: true });
            }
        };

        logout();
    }, [navigate, location]);

    return null;
};

export default LogoutComponent;
