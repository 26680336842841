import React, { useState, useEffect } from 'react';
import authenticatedInstance from "../../custom-axios/axios-auth";

const ChangeSubscriptionComponent = () => {
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    const plans = [
        {
            id: 'price_1QEa1h01STgJKTwl5ggLTevf',
            name: 'Standard',
            price: '$69.99 / month',
        },
        {
            id: 'price_1QEa2E01STgJKTwl0PfGTbBO',
            name: 'Premium',
            price: '$99.99 / month',
        },
    ];

    useEffect(() => {
        fetchCurrentSubscription();
    }, []);

    // Fetch the current subscription of the user
    const fetchCurrentSubscription = async () => {
        try {
            setLoading(true);
            const response = await authenticatedInstance.get('/subscription/current-subscription');
            setCurrentSubscription(response.data);
        } catch (err) {
            setError('Failed to load current subscription.');
        } finally {
            setLoading(false);
        }
    };

    // Handle the subscription change
    const handleChangeSubscription = async (newPriceId) => {
        if (currentSubscription.priceId === newPriceId) {
            setError('You are already on this plan.');
            return;
        }

        try {
            setLoading(true);
            const response = await authenticatedInstance.post('/subscription/change-subscription', {
                newPriceId,
            });
            setSuccess(response.data);
            setError(null);
            // Refetch subscription to get latest scheduled changes
            fetchCurrentSubscription();
        } catch (err) {
            setError('Failed to schedule subscription change.');
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="subscription-change-container p-6 bg-gray-100 min-h-screen flex flex-col items-center justify-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-8">
                Manage Your Subscription
            </h2>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <div className="text-red-600 text-lg mb-6">{error}</div>
            ) : success ? (
                <div className="text-green-600 text-lg mb-6">{success}</div>
            ) : null}

            {currentSubscription && (
                <div className="current-subscription bg-white p-6 rounded-lg shadow-md mb-8">
                    <h3 className="text-2xl font-semibold text-darkBrown mb-4">
                        Current Subscription
                    </h3>
                    <p>
                        <strong>Plan:</strong>{' '}
                        {plans.find((p) => p.id === currentSubscription.priceId)?.name ||
                            'Unknown'}
                    </p>
                    <p>
                        <strong>Status:</strong> {currentSubscription.status}
                    </p>
                    <p>
                        <strong>Billing Cycle Start:</strong>{' '}
                        {new Date(currentSubscription.currentPeriodStart).toLocaleDateString()}
                    </p>
                    <p>
                        <strong>Billing Cycle End:</strong>{' '}
                        {new Date(currentSubscription.currentPeriodEnd).toLocaleDateString()}
                    </p>
                </div>
            )}

            <div className="plans grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-4xl">
                {plans.map((plan) => (
                    <div key={plan.id} className="plan-card-wrapper">
                        <button
                            onClick={() => handleChangeSubscription(plan.id)}
                            className="plan-card bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition duration-300 border border-gray-200 hover:border-primaryGreen w-full"
                        >
                            <h3 className="text-xl font-semibold text-primaryGreen mb-4">
                                {plan.name}
                            </h3>
                            <p className="text-lg font-bold text-gray-700">{plan.price}</p>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChangeSubscriptionComponent;
