import React from 'react';

const SellerInfo = ({ seller }) => (
    <div>
        <h3 className="text-lg font-semibold mt-4 text-gray-800">Seller Information</h3>
        <div className="mb-2">
            <strong className="text-gray-700">Name:</strong> {seller.firstName} {seller.lastName}
        </div>
        <div className="mb-2">
            <strong className="text-gray-700">Shop Name:</strong> {seller.shop.name}
        </div>
    </div>
);

export default SellerInfo;
