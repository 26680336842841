// OrderStatusModal.js
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import getStatusMessage from "../../hooks/getStatusMessage";
import useRenderMessageContent from '../../hooks/useRenderMessageContent';
import StatusSelect from './StatusSelect';
import MessagePreview from './MessagePreview';
import NoteInput from './NoteInput';
import StatusActions from './StatusActions';


const OrderStatusModal = ({
                              isOpen,
                              closeModal,
                              submitStatusChange,
                              newStatus,
                              setNewStatus,
                              reason,
                              setReason,
                              statusOptions,
                              isSubmitting,
                              initialStatus,
                              selectedOrder
                          }) => {
    const [message, setMessage] = useState('');
    const [statusNotes, setStatusNotes] = useState({
        APPROVED: '',
        CANCELLED: '',
        PENDING: '',
    });

    const renderedMessage = useRenderMessageContent(message);

    useEffect(() => {
        console.log('Modal is Open:', isOpen);
        console.log('New Status:', newStatus);
        console.log('Reason:', reason);
        console.log('Selected Order:', selectedOrder);
    }, [isOpen, newStatus, reason, selectedOrder]);

    useEffect(() => {
        if (initialStatus) {
            setNewStatus(initialStatus);
        }
    }, [initialStatus, isOpen, setNewStatus]);

    useEffect(() => {
        if (newStatus === "APPROVED" && !reason.includes("Contact the seller")) {
            const formattedPhoneNumber = selectedOrder.seller.contactInformation.mobilePhone;
            setReason(`Contact the seller at: ${formattedPhoneNumber}.`);
        }
    }, [newStatus, reason, setReason, selectedOrder]);

    useEffect(() => {
        const generatedMessage = getStatusMessage(newStatus, selectedOrder, reason);
        setMessage(generatedMessage);
    }, [newStatus, reason, selectedOrder]);

    const handleNoteChange = (status, value) => {
        setStatusNotes(prevNotes => ({
            ...prevNotes,
            [status]: value
        }));
    };

    const handleStatusChange = (status) => {
        setNewStatus(status);
        setReason('');
    };

    useEffect(() => {
        if (!isOpen) {
            setStatusNotes({
                APPROVED: '',
                CANCELLED: '',
                PENDING: '',
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const updatedMessage = getStatusMessage(newStatus, selectedOrder, statusNotes[newStatus] || reason);
        setMessage(updatedMessage);
    }, [statusNotes, reason, newStatus, selectedOrder]);

    const handleSubmit = () => {
        submitStatusChange(newStatus, message);  // Pass newStatus and message
    };


    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Update Order Status"
            className="modal p-6 bg-white rounded-lg shadow-xl border-2 border-customPinkRed max-w-xl mx-auto my-10"
            overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
            <h2 className="text-xl font-bold text-customPinkRed font-poppins mb-4">Update Order Status</h2>
            <form className="space-y-4">
                <StatusSelect
                    statusOptions={statusOptions}
                    newStatus={newStatus}
                    handleStatusChange={handleStatusChange}
                />

                <MessagePreview renderedMessage={renderedMessage} />

                <NoteInput
                    newStatus={newStatus}
                    reason={reason}
                    statusNotes={statusNotes}
                    handleNoteChange={handleNoteChange}
                    setReason={setReason}
                />

                <StatusActions
                    closeModal={closeModal}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                />
            </form>
        </ReactModal>
    );
};

export default OrderStatusModal;
