import React, {useEffect, useState} from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';
import ReviewForm from "./ReviewForm";
import ReviewList from "./ReviewList";

const ReviewSection = ({sellerProfile, userId, fetchShopDetails}) => {
    const {shop, setShop} = sellerProfile;
    const [review, setReview] = useState({
        rating: '',
        comment: '',
        userId: '', // This will be set later
        shopId: shop.id,
        dateTime: new Date().toISOString()
    });
    const [userReview, setUserReview] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const isSeller = sellerProfile.user && userId === sellerProfile.user.id;
    useEffect(() => {
        if (userReview) {
            setReview({
                // id: userReview.id,
                rating: userReview.rating,
                comment: userReview.comment,
                userId: userId,
                shopId: shop.id,
                dateTime: new Date().toISOString()
            });
        }
    }, [userReview, userId, shop.id]);

    const fetchUserReview = async () => {
        if (userId) {
            try {
                const response = await authenticatedInstance.get(`/review/user-review?userId=${userId}&shopId=${shop.id}`);
                if (response.status === 200) {
                    const userReview = response.data;
                    setUserReview(userReview);
                    setReview({
                        ...userReview,
                        dateTime: new Date().toISOString()
                    });
                }
            } catch (error) {
                console.error('Error fetching user review:', error);
            }
        }
    };

    useEffect(() => {
        fetchUserReview();
    }, [userId, shop.id]);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setReview({...review, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const reviewData = {
                ...review,
                userId: userId,  // Set userId explicitly
                shopId: shop.id, // Set shopId from shop object
                dateTime: new Date().toISOString()
            };

            const response = await authenticatedInstance.post('/review', reviewData);

            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            fetchShopDetails();
            setIsEditing(false);
            fetchUserReview();
        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };

    const handleDeleteReview = async (reviewId) => {
        try {
            const response = await authenticatedInstance.delete(`/review/${reviewId}?userId=${userId}`);
            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }

            // Remove the deleted review from the shop state
            // Clear the review form
            setReview({rating: '', comment: ''});
            setUserReview(null);
            fetchShopDetails();

        } catch (error) {
            console.error('Error deleting review:', error);
        }
    };

    return (
        <div className="ms-6 me-6" id="reviews">
            {/*{shop.averageRating > 0 && <h2>Shop rating: {shop.averageRating}</h2>}*/}
            {userId && (
                <>

                    {userReview && !isSeller ? (
                        <>
                            <button className="custom-btn-primary" onClick={() => setIsEditing(true)}>Edit your review
                            </button>
                            {isEditing && (
                                <ReviewForm
                                    handleSubmit={handleSubmit}
                                    review={review}
                                    handleInputChange={handleInputChange}
                                    setIsEditing={setIsEditing}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {!isSeller &&
                                <button className="custom-btn-primary mt-4 mb-4" onClick={() => setIsEditing(true)}>Add
                                    Review</button>
                            }
                            {isEditing && (
                                <ReviewForm
                                    handleSubmit={handleSubmit}
                                    review={review}
                                    handleInputChange={handleInputChange}
                                    setIsEditing={setIsEditing}
                                />
                            )}
                        </>
                    )}
                </>
            )}
            <ReviewList reviews={shop.reviews} userId={userId} onDeleteReview={handleDeleteReview}/>
        </div>
    );
};

export default ReviewSection;
