import React, { useState, useEffect, useContext } from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';
import SellerDetailsComponent from './SellerDetailsComponent';
import ShopDetailsComponent from './ShopDetailsComponent';
import ProductsComponent from './ProductsComponent';
import CategoriesComponent from './CategoriesComponent';
import MessagesComponent from './MessagesComponent';
import SocialMediaLinksComponent from './SocialMediaLinksComponent';
import ContactInformationComponent from './ContactInformationComponent';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ThemeStyles.css';
import {UserContext} from "../../contexts/UserContext";
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";
import { SubscriptionContext } from "../../contexts/SubscriptionContext"; // Import Subscription Context


const SellerRegistrationComponent = () => {
    const [sellerFirstName, setSellerFirstName] = useState('');
    const [sellerLastName, setSellerLastName] = useState('');
    const [sellerBio, setSellerBio] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [defaultEmail, setDefaultEmail] = useState(null);
    const [shopName, setShopName] = useState('');
    const [shopDescription, setShopDescription] = useState('');
    const [shopZipCode, setShopZipCode] = useState('');

    const [products, setProducts] = useState([]);
    const [categoriesFilters, setCategoriesFilters] = useState([]);

    const [categoriesService, setCategoriesService] = useState([]);
    const [categoriesHolidays, setCategoriesHolidays] = useState([]);
    const [selectedServiceIds, setSelectedServiceIds] = useState([]);

    const [selectedFilterIds, setSelectedFilterIds] = useState([]);
    const [selectedHolidayIds, setSelectedHolidayIds] = useState([]);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [jwtToken, setJwtToken] = useState(null);

    const [socialMediaLinks, setSocialMediaLinks] = useState([{ platform: '', url: '' }]);
    const [mobilePhone, setMobilePhone] = useState('');
    const [email, setEmail] = useState('');

    const [shopProfilePicture, setShopProfilePicture] = useState(null); // New state for shop image

    const { login, fetchUserInfo, isUserInfoLoading, user } = useContext(UserContext);
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [registrationComplete, setRegistrationComplete] = useState(false); // New state to manage completion

    const { selectedPlan } = useContext(SubscriptionContext); // Get the selected plan from SubscriptionContext


    useEffect(() => {
        fetchCustomerDetails();
        fetchCategories();
        const token = localStorage.getItem('JWT');
        setJwtToken(token);
    }, []);
    useEffect(() => {
        AOS.init({ duration: 1000, once: true }); // Initialize AOS with a duration of 1000ms and run animation once
    }, []);
    const handleShopProfilePictureChange = (e) => {
        setShopProfilePicture(e.target.files[0]); // Handle shop profile picture change
    };
    const fetchCustomerDetails = async () => {
        try {
            const response = await authenticatedInstance.get('/customer/details');
            const { firstName, lastName, email } = response.data;
            setSellerFirstName(firstName);
            setSellerLastName(lastName);
            setDefaultEmail(email);
        } catch (error) {
            console.error('Error fetching customer details:', error);
            setError('Failed to load customer details.');
        }
    };
    const handleCloseMessages = () => {
        setError(null);
        setSuccess(null);
    };
    const fetchCategories = async () => {
        try {
            // Fetch holidays (same regardless of product type)
            const holidaysResponse = await authenticatedInstance.get('/categories/holidays');
            setCategoriesHolidays(holidaysResponse.data);

            // Fetch filters depending on the selected plan's product type
            if (selectedPlan.productType === 'PRODUCT') {
                const filtersResponse = await authenticatedInstance.get('/categories/filters/product');
                setCategoriesFilters(filtersResponse.data);
            } else if (selectedPlan.productType === 'SERVICE') {
                const filtersResponse = await authenticatedInstance.get('/categories/filters/service');
                setCategoriesService(filtersResponse.data);
            } else if (selectedPlan.productType === 'BOTH') {
                // Fetch both product and service categories if the plan allows both
                const productFiltersResponse = await authenticatedInstance.get('/categories/filters/product');
                const serviceFiltersResponse = await authenticatedInstance.get('/categories/filters/service');
                setCategoriesFilters([...productFiltersResponse.data, ...serviceFiltersResponse.data]);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setError('Failed to load categories.');
        }
    };


    const handleAddProduct = () => {
        // Set productType based on the selected plan
        let defaultProductType = '';

        if (selectedPlan.productType === 'PRODUCT') {
            defaultProductType = 'PRODUCT';
        } else if (selectedPlan.productType === 'SERVICE') {
            defaultProductType = 'SERVICE';
        }
        // If it's BOTH, leave it empty so user can select

        // Include default values for new properties productType and isHidden
        const newProduct = {
            title: '',
            price: '',
            description: '',
            productType: defaultProductType, // Set based on selected plan
            isHidden: false,
            imageFiles: []
        };
        setProducts([...products, newProduct]);
    };

    const handleProductChange = (index, key, value) => {
        const updatedProducts = [...products];
        updatedProducts[index][key] = value;
        setProducts(updatedProducts);
    };

    const handleImageChange = (productIndex, imageIndex, file) => {
        const updatedProducts = [...products];
        updatedProducts[productIndex].imageFiles[imageIndex] = {
            file: file, // Correctly map the file to the 'file' property of FileDTO
            fileName: file.name, // Optional: Set fileName if needed
            fileType: file.type, // Optional: Set fileType if needed
            category: 'PRODUCT_IMAGE', // Set category as needed
            relatedEntityId: productIndex // Optional: Link to the product ID
        };
        setProducts(updatedProducts);
    };


    const handleAddImage = (productIndex) => {
        const updatedProducts = [...products];
        updatedProducts[productIndex].imageFiles.push({ imageFile: null });
        setProducts(updatedProducts);
    };

    const handleRemoveProduct = (index) => {
        const updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);
    };

    const handleRemoveImage = (productIndex, imageIndex) => {
        const updatedProducts = [...products];
        updatedProducts[productIndex].imageFiles.splice(imageIndex, 1); // Remove from imageFiles
        setProducts(updatedProducts);
    };


    // For Category Filters
    const handleFilterCheckboxChange = (newSelectedFilterIds) => {
        setSelectedFilterIds(newSelectedFilterIds); // Directly update the selected filter IDs
    };

    const handleServiceCheckboxChange = (newSelectedServiceIds) => {
        setSelectedServiceIds(newSelectedServiceIds); // Directly update the selected filter IDs
    };

// For Holiday Filters
    const handleHolidayCheckboxChange = (newSelectedHolidayIds) => {
        setSelectedHolidayIds(newSelectedHolidayIds); // Directly update the selected holiday IDs
    };


    const handleProfilePictureChange = (e) => {
        setProfilePicture(e.target.files[0]);
    };

    const handleAddSocialMediaLink = () => {
        setSocialMediaLinks([...socialMediaLinks, { platform: '', url: '' }]);
    };

    const handleSocialMediaLinkChange = (index, key, value) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks[index][key] = value; // This will now store the exact enum value (e.g., 'FACEBOOK')
        setSocialMediaLinks(updatedLinks);
    };
    useEffect(() => {
    }, [profilePicture]);
    useEffect(() => {
    }, []);
    const handleRemoveSocialMediaLink = (index) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks.splice(index, 1);
        setSocialMediaLinks(updatedLinks);
    };

    const handleSubmit = async () => {
        const cleanedProducts = products.map(product => {
            return {
                ...product,
                imageFiles: product.imageFiles.filter(file => file !== null && file !== undefined)
            };
        });

        setProducts(cleanedProducts);


        if (!sellerFirstName.trim() || !sellerLastName.trim()) {
            setError('Seller first name and last name are required.');
            return;
        }

        // Validate Shop Information
        if (!shopName.trim() || !shopDescription.trim() || !shopZipCode.trim() || !shopProfilePicture) {
            setError('Shop name, description, price, ZIP code, and profile picture are required.');
            return;
        }

        // // Validate Products
        // if (products.length === 0) {
        //     setError('At least one product is required.');
        //     return;
        // }
        //
        // for (let i = 0; i < products.length; i++) {
        //     const product = products[i];
        //     if (!product.title.trim() || !product.description.trim() || !product.productType.trim() || !product.price.trim()) {
        //         setError(`Product ${i + 1} is missing title, price, description, or product type.`);
        //         return;
        //     }
        //     if (product.imageFiles.length === 0) {
        //         setError(`Product ${i + 1} must have at least one image file.`);
        //         return;
        //     }
        // }

        // Validate Contact Information
        if (!mobilePhone.trim() || !email.trim()) {
            setError('Mobile phone and email are required.');
            return;
        }

        // Validate Social Media Links
        if (!socialMediaLinks.length || socialMediaLinks.some(link => !link.platform.trim() || !link.url.trim())) {

            setError('At least one valid social media link is required.');
            return;
        }

        // Validate Profile Picture
        if (!profilePicture) {
            setError('Profile picture is required.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('sellerProfile.firstName', sellerFirstName.trim());
            formData.append('sellerProfile.lastName', sellerLastName.trim());
            formData.append('sellerProfile.bio', sellerBio.trim());

            // Make sure the profilePicture is appended properly
            formData.append('sellerProfile.profilePicture', profilePicture);
            formData.append('sellerProfile.profilePictureFile.file', profilePicture);
            formData.append('sellerProfile.profilePictureFile.fileName', profilePicture.fileName);
            formData.append('sellerProfile.profilePictureFile.fileType', profilePicture.fileType);




            formData.append('sellerProfile.user.email', email.trim());
            formData.append('sellerProfile.contactInformation.mobilePhone', mobilePhone.trim());
            formData.append('sellerProfile.contactInformation.email', email.trim());

            socialMediaLinks.forEach((link, index) => {
                formData.append(`sellerProfile.socialMediaLinks[${index}].platform`, link.platform.trim());
                formData.append(`sellerProfile.socialMediaLinks[${index}].url`, link.url.trim());
            });

            formData.append('shop.name', shopName.trim());
            formData.append('shop.description', shopDescription.trim());
            formData.append('shop.zipCode', shopZipCode.trim());
            formData.append('shop.productType', selectedPlan.productType); // Add shop product type from subscription plan
            if (shopProfilePicture) {
                formData.append('shop.profilePicture', shopProfilePicture);

                formData.append('shop.profilePictureFile.file', shopProfilePicture); // Add the file itself
                formData.append('shop.profilePictureFile.fileName', shopProfilePicture.name); // File name
                formData.append('shop.profilePictureFile.fileType', shopProfilePicture.type); // File type
                formData.append('shop.profilePictureFile.category', 'SHOP_IMAGE'); // Adjust this as needed
            }

            // selectedFilterIds.forEach(id => {
            //     formData.append('shop.categoryFilters', id);
            // });
            //
            // selectedServiceIds.forEach(id => {
            //     formData.append('shop.categoryService', id);
            // });
            //
            // selectedHolidayIds.forEach(id => {
            //     formData.append('shop.categoryHolidays', id);
            // });
            //
            // products.forEach((product, index) => {
            //     formData.append(`shop.products[${index}].title`, product.title.trim());
            //     formData.append(`shop.products[${index}].price`, product.price.trim());
            //     formData.append(`shop.products[${index}].description`, product.description.trim());
            //     formData.append(`shop.products[${index}].productType`, product.productType);
            //     formData.append(`shop.products[${index}].isHidden`, product.isHidden);
            //     product.imageFiles.forEach((file, imageIndex) => {
            //         if (file && file.file) { // Ensure file is valid and contains the 'file' property
            //             formData.append(`shop.products[${index}].imageFiles[${imageIndex}].file`, file.file);
            //             // Optionally, append fileName and fileType if needed
            //             formData.append(`shop.products[${index}].imageFiles[${imageIndex}].fileName`, file.fileName);
            //             formData.append(`shop.products[${index}].imageFiles[${imageIndex}].fileType`, file.fileType);
            //         }
            //     });
            // });
            formData.append('subscriptionPlan', selectedPlan.plan);

            const response = await authenticatedInstance.post('/auth/seller/registerSeller', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setSuccess('Seller registered successfully');

            // Retrieve the token from the response
            const token = response.data;

            // Log in the user and update UserContext with the token
            await login(token);

            // Fetch updated user info after logging in
            const decodedToken = jwtDecode(token);
            await fetchUserInfo(decodedToken.userId);

            // Mark registration as complete
            setRegistrationComplete(true);
        } catch (err) {
            setError('Seller registration failed.');
            console.error('Error registering seller:', err);
        }
    };
    useEffect(() => {
        if (registrationComplete && !isUserInfoLoading) {
            // If registration is complete and user info loading is false, navigate to shop details
            navigate('/seller/registration-success');
        }
    }, [registrationComplete, isUserInfoLoading, navigate, user]);


    return (
        <div className="seller-registration-container bg-white min-h-screen font-poppins p-6">
            {/* Welcoming Message */}
            <h1 className="title mb-8 text-center text-4xl font-bold text-darkBrown">
                Welcome to Your Seller Journey!
            </h1>
            <p className="text-center text-lg text-darkBrown max-w-2xl mx-auto mb-12 leading-relaxed">
                Ready to share your amazing homemade creations with the world? You’re in the right place!
                We’re here to help you bring your handcrafted products to people who will love them. Let's get started with just a few steps to set up your shop.
            </p>

            {/* Seller Details */}
            <div className="mb-12" data-aos="fade-up">
                <SellerDetailsComponent
                    sellerFirstName={sellerFirstName}
                    sellerLastName={sellerLastName}
                    sellerBio={sellerBio}
                    setSellerBio={setSellerBio}
                    profilePicture={profilePicture}
                    setProfilePicture={setProfilePicture}
                />
            </div>

            {/* Shop Details */}
            <div className="mb-12" data-aos="fade-up">
                <ShopDetailsComponent
                    shopName={shopName}
                    shopDescription={shopDescription}
                    shopZipCode={shopZipCode}
                    setShopName={setShopName}
                    setShopDescription={setShopDescription}
                    setShopZipCode={setShopZipCode}
                    shopProfilePicture={shopProfilePicture}
                    setShopProfilePicture={setShopProfilePicture}
                />
            </div>

            {/*/!* Products *!/*/}
            {/*<div className="mb-12" data-aos="fade-up">*/}
            {/*    <ProductsComponent*/}
            {/*        products={products}*/}
            {/*        handleProductChange={handleProductChange}*/}
            {/*        handleImageChange={handleImageChange}*/}
            {/*        handleAddImage={handleAddImage}*/}
            {/*        handleRemoveImage={handleRemoveImage}*/}
            {/*        handleAddProduct={handleAddProduct}*/}
            {/*        handleRemoveProduct={handleRemoveProduct}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*/!* Categories *!/*/}
            {/*<div className="mb-12" data-aos="fade-up">*/}
            {/*    <CategoriesComponent*/}
            {/*        categoriesFilters={categoriesFilters}*/}
            {/*        categoriesService={categoriesService}*/}
            {/*        selectedFilterIds={selectedFilterIds}*/}
            {/*        selectedServiceIds={selectedServiceIds}*/}
            {/*        handleFilterCheckboxChange={handleFilterCheckboxChange}*/}
            {/*        categoriesHolidays={categoriesHolidays}*/}
            {/*        selectedHolidayIds={selectedHolidayIds}*/}
            {/*        handleServiceCheckboxChange={handleServiceCheckboxChange}*/}
            {/*        handleHolidayCheckboxChange={handleHolidayCheckboxChange}*/}
            {/*    />*/}
            {/*</div>*/}

            {/* Social Media Links */}
            <div className="mb-12" data-aos="fade-up">
                <SocialMediaLinksComponent
                    socialMediaLinks={socialMediaLinks}
                    handleAddSocialMediaLink={handleAddSocialMediaLink}
                    handleSocialMediaLinkChange={handleSocialMediaLinkChange}
                    handleRemoveSocialMediaLink={handleRemoveSocialMediaLink}
                />
            </div>

            {/* Contact Information */}
            <div className="mb-12" data-aos="fade-up">
                <ContactInformationComponent
                    mobilePhone={mobilePhone}
                    email={email}
                    setMobilePhone={setMobilePhone}
                    setEmail={setEmail}
                    defaultEmail={defaultEmail}
                />
            </div>

            {/* Submit Button */}
            <div className="text-center mb-12" data-aos="fade-up">
                <button className="custom-btn-primary px-8 py-4 rounded-full shadow-md text-lg transition duration-300" onClick={handleSubmit}>
                    Become a Seller
                </button>
            </div>

            {/* Messages */}
            <MessagesComponent
                error={error}
                success={success}
                onClose={handleCloseMessages}
            />
        </div>
    );
};

export default SellerRegistrationComponent;
