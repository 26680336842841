import React from "react";
import NavBar from "../Navbar/NavBar";
import FooterWithSitemap from "../Footer/FooterWithSitemap";

const Layout = ({ children }) => {
    return (
        <>
            <NavBar />
            <main className="relative min-h-[90vh]">
                {children}
            </main>
            <FooterWithSitemap />
        </>
    );
};

export default Layout;
