import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ConversationsPage from './ConversationsPage';
import ChatPage from './ChatPage';
import NavBar from "../Navbar/NavBar";
import authenticatedInstance from "../../custom-axios/axios-auth";
import useOnlineStatus from '../OnlineStatus/useOnlineStatus'; // Import the online status hook
import { FaArrowLeft } from 'react-icons/fa'; // Import an icon for back button

const ChatApp = () => {
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [drafts, setDrafts] = useState({}); // Store drafts keyed by userId
    const [errorMessage, setErrorMessage] = useState(''); // Error message state
    const [showConversations, setShowConversations] = useState(true); // State to handle mobile view toggling
    const location = useLocation();
    const { userId, sellerId, sellerFullName, prefilledMessage, orderDetails } = location.state || {};

    // Use the online status hook for the selected seller
    const onlineStatus = useOnlineStatus(selectedConversation ? selectedConversation.userId : null);

    useEffect(() => {
        if (userId && sellerId) {
            // Check if user is trying to message themselves
            if (userId === sellerId) {
                setErrorMessage("You can't message yourself.");
                return; // Prevent fetching or creating a conversation
            }
            fetchOrCreateConversation(userId, sellerId, sellerFullName, prefilledMessage, orderDetails);
        }
    }, [userId, sellerId, sellerFullName, prefilledMessage, orderDetails]);

    const fetchOrCreateConversation = async (userId, sellerId, sellerFullName, prefilledMessage, orderDetails) => {
        try {
            const response = await authenticatedInstance.get(`/messages/${userId}/${sellerId}`);

            // Extract the array of messages from the 'content' field
            const formattedMessages = response.data.content.map(msg => ({
                ...msg,
                id: String(msg.id),
                senderId: String(msg.senderId),
                recipientId: String(msg.recipientId)
            }));

            const newConversation = {
                chatId: formattedMessages.length ? formattedMessages[0].chatId : `${userId}-${sellerId}`,
                userId: sellerId,
                userFullName: sellerFullName,
                hasUnseenMessages: false,
                prefilledMessage: prefilledMessage,
                orderDetails: orderDetails
            };

            setSelectedConversation(newConversation);
            setErrorMessage(''); // Clear error message
            setShowConversations(false); // Navigate to chat view after selecting a conversation
        } catch (error) {
            console.error('Error fetching or creating conversation:', error);
            setErrorMessage('Failed to fetch or create conversation.');
        }
    };

    // Save draft message for the current conversation
    const handleSaveDraft = (userId, draftMessage) => {
        setDrafts(prevDrafts => ({
            ...prevDrafts,
            [userId]: draftMessage,
        }));
    };

    // Handle conversation selection
    const handleSelectConversation = (conversation) => {
        if (selectedConversation) {
            handleSaveDraft(selectedConversation.userId, drafts[selectedConversation.userId]);
        }
        setSelectedConversation(conversation);
        setShowConversations(false); // Show the chat view on mobile
        setErrorMessage(''); // Clear the error message when selecting a new conversation
    };

    // Handle back button click to show conversations list
    const handleBackClick = () => {
        setShowConversations(true);
    };

    return (
        <div>
            <div className="chat-app container mx-auto shadow-lg rounded-lg h-screen flex flex-col md:flex-row">

                {/* Conversations panel - Shown by default on desktop, conditionally on mobile */}
                <div className={`conversations-panel md:w-2/5 border-r-2 overflow-y-auto bg-white 
                    ${showConversations ? 'block' : 'hidden'} md:block`}>
                    <ConversationsPage onSelectConversation={handleSelectConversation}/>
                </div>

                {/* Chat panel - Shown by default on desktop, conditionally on mobile */}
                <div className={`chat-panel flex-1 flex flex-col 
                    ${!showConversations ? 'block' : 'hidden'} md:block`}>
                    {selectedConversation ? (
                        <>
                            {/* Back button on mobile */}
                            <div className="md:hidden p-4 bg-white border-b">
                                <button
                                    onClick={handleBackClick}
                                    className="flex items-center text-darkBrown font-semibold"
                                >
                                    <FaArrowLeft className="mr-2"/> Back to Conversations
                                </button>
                            </div>
                            <ChatPage
                                conversation={selectedConversation}
                                draftMessage={drafts[selectedConversation.userId] || ''} // Pass the draft message
                                onSaveDraft={handleSaveDraft} // Function to save drafts
                                onlineStatus={onlineStatus} // Pass the online status to ChatPage
                            />
                        </>
                    ) : (
                        <div className="no-conversation-selected flex justify-center items-center text-gray-500 h-full">
                            <p>Select a conversation to start chatting</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatApp;
