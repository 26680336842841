import React from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';

const ApproveSubmissionComponent = ({ submissionId, onActionComplete }) => {
    const handleApprove = async () => {
        try {
            await authenticatedInstance.post('/admin/approveSubmission', { submissionId, approved: true });
            if (onActionComplete) {
                onActionComplete(); // Refresh the submissions
            }
        } catch (error) {
            console.error('Error approving submission:', error);
        }
    };

    const handleReject = async () => {
        try {
            await authenticatedInstance.post('/admin/approveSubmission', { submissionId, approved: false });
            if (onActionComplete) {
                onActionComplete(); // Refresh the submissions
            }
        } catch (error) {
            console.error('Error rejecting submission:', error);
        }
    };

    return (
        <div className="flex space-x-2">
            <button className="custom-btn-primary" onClick={(e) => { e.stopPropagation(); handleApprove(); }}>
                Approve
            </button>
            <button className="custom-btn-remove" onClick={(e) => { e.stopPropagation(); handleReject(); }}>
                Reject
            </button>
        </div>
    );
};

export default ApproveSubmissionComponent;
