import { useState } from 'react';
import authenticatedInstance from "../custom-axios/axios-auth";

const useMobilePhoneChange = (contactId) => {
    const [newMobilePhone, setNewMobilePhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleMobilePhoneChange = (value) => {
        setNewMobilePhone(value);
    };

    const updateMobilePhone = async () => {
        try {
            setIsLoading(true);
            setError('');
            const response = await authenticatedInstance.post('/seller/contact/change/mobilePhone', {
                contactId,
                newMobilePhone
            });
            setIsLoading(false);
            return response.data; // Returns the updated contact info
        } catch (err) {
            setIsLoading(false);
            setError('Failed to update mobile phone. Please try again.');
            console.error(err);
        }
    };

    return {
        newMobilePhone,
        handleMobilePhoneChange,
        updateMobilePhone,
        isLoading,
        error
    };
};

export default useMobilePhoneChange;
