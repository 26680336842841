import { useState, useEffect } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import authenticatedInstance from '../../custom-axios/axios-auth';

const useOnlineStatus = (sellerId) => {
    const [onlineStatus, setOnlineStatus] = useState({ isOnline: false, lastActivityTime: null });

    useEffect(() => {
        if (!sellerId) {
            setOnlineStatus({ isOnline: false, lastActivityTime: null }); // Reset online status
            return;
        }

        // Fetch initial online status from REST API
        const fetchOnlineStatus = async () => {
            try {
                const response = await authenticatedInstance.get(`/users/${sellerId}/online-status`);
                const { lastActivityTime, online } = response.data;

                // Convert lastActivityTime array to a Date object
                if (lastActivityTime && Array.isArray(lastActivityTime)) {
                    const convertedDate = new Date(
                        lastActivityTime[0], lastActivityTime[1] - 1, lastActivityTime[2],
                        lastActivityTime[3], lastActivityTime[4], lastActivityTime[5],
                        lastActivityTime[6] / 1000000
                    );
                    setOnlineStatus({
                        isOnline: online,
                        lastActivityTime: convertedDate,
                    });
                }
            } catch (error) {
                console.error('Error fetching initial online status:', error.response ? error.response.data : error.message);
            }
        };

        fetchOnlineStatus();

        // Set up WebSocket connection for real-time updates
        const socket = new SockJS(`${process.env.REACT_APP_BACKEND_URL}/ws`);
        // const socket = new SockJS(`http://ws.thehomemadeshop.com/ws`);
        const stompClient = Stomp.over(socket);

        stompClient.connect({}, () => {
            stompClient.subscribe('/topic/status-updates', (message) => {
                const statusUpdate = JSON.parse(message.body);

                // Update only for the correct seller
                if (statusUpdate.userId === sellerId) {
                    setOnlineStatus({
                        isOnline: statusUpdate.online,
                        lastActivityTime: new Date(
                            statusUpdate.lastActivityTime[0], statusUpdate.lastActivityTime[1] - 1, statusUpdate.lastActivityTime[2],
                            statusUpdate.lastActivityTime[3], statusUpdate.lastActivityTime[4], statusUpdate.lastActivityTime[5],
                            statusUpdate.lastActivityTime[6] / 1000000
                        ),
                    });
                }
            });
        });

        return () => {
            if (stompClient) stompClient.disconnect();
        };
    }, [sellerId]);

    return onlineStatus;
};

export default useOnlineStatus;
