import getOrderStatusClasses from "./getOrderStatusClasses";

const DayCard = ({ day, orders, currentMonth, onDayClick, handleOrderClick }) => {
    const maxVisibleOrders = 2;
    const visibleOrders = orders.slice(0, maxVisibleOrders);
    const remainingOrdersCount = orders.length - maxVisibleOrders;

    return (
        <div
            className={`p-2 rounded-lg cursor-pointer 
                h-32 flex flex-col justify-between
                ${day.month() === currentMonth ? 'bg-blue-100' : 'bg-gray-200 text-gray-500'}`}
            onClick={() => onDayClick(day)}
        >
            {/* Date Display */}
            <div className="text-lg font-bold mb-2">
                {day.date()}
            </div>

            {/* Orders List */}
            <div className="flex-grow space-y-1">
                {visibleOrders.map((order) => (
                    <div
                        key={order.id}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOrderClick(order.id);
                        }}
                        className={`text-sm rounded px-2 py-1 truncate ${getOrderStatusClasses(order.status)}`}
                        title={`${order.id}: ${order.productTitle}`}
                    >
                        {order.id}: {order.productTitle}
                    </div>
                ))}

                {/* Display remaining orders count if there are more */}
                {remainingOrdersCount > 0 && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            onDayClick(day);
                        }}
                        className="text-sm text-blue-700 cursor-pointer overflow-hidden whitespace-nowrap text-ellipsis"
                        title={`+${remainingOrdersCount} more orders`}
                    >
                        +{remainingOrdersCount} more orders
                    </div>
                )}
            </div>
        </div>
    );
};

export default DayCard;
