import React, {useState} from 'react';
import {FaFacebook, FaYoutube} from 'react-icons/fa';
import {useMediaQuery} from "react-responsive";

const SITEMAP = [
    {
        title: "Contact Us",
        links: [
            {name: "Phone: +1 630 487 7798", href: "tel:+16304877798"},
            {name: "Email: info@thehomemadeshop.com", href: "mailto:info@thehomemadeshop.com"},
            {name: "Address: 2200 South Main Street Suite 207, Lombard, Illinois 60148", href: "#"},
            {
                name: "Social",
                isHeader: true,
                subLinks: [
                    {
                        name: "Facebook",
                        icon: <FaFacebook/>,
                        href: "https://www.facebook.com/profile.php?id=61568084152962",
                        target: "_blank"
                    },
                    {
                        name: "YouTube",
                        icon: <FaYoutube/>,
                        href: "https://www.youtube.com/@TheHomeMadeShop",
                        target: "_blank"
                    },
                ],
            },
        ],
    },
    {
        title: "Sellers",
        links: [
            {name: "Become a Seller", href: "/seller/register"},
            {name: "Subscriptions", href: "/footer/subscriptions"},
            {name: "FAQ", href: "/footer/sellers-faq"},
            {name: "All Sellers", href: "/footer/all-sellers"},
            {name: "Tips", href: "/footer/sellers-tips"},
        ],
    },
    {
        title: "Customers",
        links: [
            {name: "FAQ", href: "/footer/customers-faq"},
            {name: "Find All Shops", href: "/footer/find-all-shops"},
            {name: "Find All Products", href: "/footer/find-all-products"},
            {name: "Find All Services", href: "/footer/find-all-services"},
            {name: "Find Your ZIP Code", href: "/footer/find-your-zip-code"},
            {name: "Find Nearby ZIP Codes", href: "/footer/find-nearby-zip-codes"},
        ],
    },
    {
        title: "Company",
        links: [
            {name: "About Us", href: "/footer/about-us"},
            {name: "Privacy Policy", href: "/footer/privacy-policy"},
            {name: "Terms", href: "/footer/terms"},
        ],
    },
];

const currentYear = new Date().getFullYear();

export function FooterWithSitemap() {
    const [expandedSection, setExpandedSection] = useState(null);
    const isSmallScreen = useMediaQuery({maxWidth: 1024}); // Small screen: 1024px and below

    const [expandedSections, setExpandedSections] = useState([]);

    const toggleSection = (title) => {
        setExpandedSections((prev) => {
            if (prev.includes(title)) {
                // If it's already expanded, remove it from the array
                return prev.filter((section) => section !== title);
            } else {
                // If it's not expanded, add it to the array
                return [...prev, title];
            }
        });
    };

    return (
        <footer className="w-full bg-secondaryProductColor text-primaryServiceColor p-6">
            <div className="mx-auto max-w-7xl px-4">
                {/* Footer Links */}
                <div
                    className="grid grid-cols-1 gap-8 py-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 justify-items-center">
                    {isSmallScreen ? (
                        SITEMAP.map(({title, links}, index) => (
                            <div key={index} className="w-full text-center sm:text-left">
                                <h4
                                    className="mb-4 font-bold uppercase text-sm text-primaryServiceColor cursor-pointer"
                                    onClick={() => toggleSection(title)}
                                >
                                    {title}
                                </h4>
                                <ul className={`space-y-2 ${expandedSections.includes(title) ? 'block' : 'hidden'}`}>
                                    {links.map((link, key) =>
                                        link.isHeader ? (
                                            <li key={key} className="text-primaryServiceColor font-bold">
                                                {link.name}
                                                <ul className="flex flex-wrap space-x-4 mt-2 justify-center sm:justify-start">
                                                    {link.subLinks.map((subLink, subKey) => (
                                                        <li key={subKey} className="mb-2 sm:mb-0">
                                                            <a
                                                                href={subLink.href}
                                                                target={subLink.target}
                                                                rel="noopener noreferrer"
                                                                className="text-primaryServiceColor hover:text-primaryProductColor"
                                                            >
                                                                {subLink.icon}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ) : (
                                            <li key={key}>
                                                <a
                                                    href={link.href}
                                                    className="text-sm transition-transform hover:scale-105 text-primaryServiceColor hover:text-primaryProductColor"
                                                >
                                                    {link.name}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        ))

                    ) : (
                        SITEMAP.map(({title, links}, index) => (
                            <div key={index} className="w-full text-center sm:text-left">
                                <h4 className="mb-4 font-bold uppercase text-sm text-primaryServiceColor">{title}</h4>
                                <ul className="space-y-2">
                                    {links.map((link, key) =>
                                        link.isHeader ? (
                                                <li key={key} className="text-primaryServiceColor font-bold">
                                                    {link.name}
                                                    <ul className="flex flex-wrap space-x-4 mt-2 justify-center sm:justify-start">
                                                        {link.subLinks.map((subLink, subKey) => (
                                                            <li key={subKey} className="mb-2 sm:mb-0">
                                                                <a href={subLink.href}
                                                                   target="_blank"   // Open in a new tab
                                                                   rel="noopener noreferrer"  // Ensure security
                                                                   className="text-primaryServiceColor hover:text-primaryProductColor">
                                                                    {subLink.icon}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>

                                                </li>
                                            )
                                            : (
                                                <li key={key}>
                                                    <a
                                                        href={link.href}
                                                        className="text-sm transition-transform hover:scale-105 text-primaryServiceColor hover:text-primaryProductColor"
                                                    >
                                                        {link.name}
                                                    </a>
                                                </li>
                                            )
                                    )}
                                </ul>
                            </div>
                        ))
                    )}
                </div>

                {/* Footer Bottom Section */}
                <div
                    className="flex flex-col items-center justify-between border-t border-primaryServiceColor py-4 md:flex-row">
                    <p className="text-center text-xs md:text-sm font-normal mb-4 md:mb-0">
                        &copy; {currentYear} TheHomeMadeShop.com. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default FooterWithSitemap;
