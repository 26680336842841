import React, {createContext, useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode'; // {jwtDecode} is the correct way to import this
import axios from '../custom-axios/axios-auth';

export const UserContext = createContext();

export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);
    const [hasPendingSellerRequest, setHasPendingSellerRequest] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [verificationMessage, setVerificationMessage] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);

    const BASE_FILE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/files/download`;

    // UseEffect to initialize user state from JWT token
    useEffect(() => {
        const token = localStorage.getItem('JWT');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUser(decodedToken);
                setRoles(decodedToken.roles || []);
                setIsLoggedIn(true);
                setIsEnabled(decodedToken.isEnabled);
                fetchUserInfo(decodedToken.userId);
            } catch (error) {
                console.error('Failed to decode token:', error);
            }
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn && user) {
            fetchNotifications(user.userId);
            fetchNotVerified(user.userId);
        }
    }, [isLoggedIn, user]);

    // Function to fetch notifications for unseen conversations
    const fetchNotifications = async (userId) => {
        try {
            const response = await axios.get(`/messages/unseen/${userId}`);
            if (response.status === 200) {
                setNotifications(response.data);
            } else {
                console.error('Failed to fetch notifications:', response.status);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const fetchNotVerified = async (userId) => {
        try {
            const response2 = await axios.get(`/messages/verification/${userId}`)
            if (response2.status === 200) {
                setVerificationMessage(response2.data);
            } else {
                console.error('Failed to fetch verification notification:', response2.status);
                setVerificationMessage(null);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }


    // Function to fetch user info from the backend
    const fetchUserInfo = async (userId) => {
        try {
            setIsUserInfoLoading(true); // Start loading

            // Updated the API endpoint to the correct one
            const response = await axios.get(`/users/${userId}/information`);

            if (response.status === 200) {
                let fetchedUserInfo = response.data;

                // Access nested 'info' inside 'fetchedUserInfo'
                if (fetchedUserInfo.info) {
                    const {imageLink} = fetchedUserInfo.info;


                    // Construct full image URL if imageLink is not null
                    fetchedUserInfo.info.imageLink = imageLink
                        ? `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${imageLink}`
                        : null;

                    // Set 'userInfo' and 'hasPendingSellerRequest'
                    setUserInfo(fetchedUserInfo.info);
                } else {
                    console.error('User info object is missing from the response.');
                }

                setHasPendingSellerRequest(fetchedUserInfo.hasPendingSellerRequest);
            } else {
                console.error('Failed to fetch user info, Response Status:', response.status);
            }
        } catch (error) {
            console.error('Error fetching user information:', error);
        } finally {
            setIsUserInfoLoading(false); // Stop loading
        }
    };


    // Function to log in a user
    const login = async (token) => {
        try {
            const decodedToken = jwtDecode(token);
            setUser(decodedToken);
            setRoles(decodedToken.roles || []);
            setIsLoggedIn(true);
            setIsEnabled(decodedToken.isEnabled);
            localStorage.setItem('JWT', token);
            // When the user logs in, clear the modalClosed flag
            localStorage.removeItem("modalClosed");
            await fetchUserInfo(decodedToken.userId); // Fetch user info after login
        } catch (error) {
            console.error('Invalid token during login:', error);
        }
    };

    // Function to log out a user
    const logout = () => {
        setUser(null);
        setRoles([]);
        setIsLoggedIn(false);
        setUserInfo(null);
        setHasPendingSellerRequest(false);
        localStorage.removeItem('JWT');
    };

    return (
        <UserContext.Provider value={{
            user,
            roles,
            isLoggedIn,
            isEnabled,
            userInfo,
            hasPendingSellerRequest,
            login,
            logout,
            fetchUserInfo,
            isUserInfoLoading,
            notifications,
            fetchNotifications,
            setNotifications,
            verificationMessage,
            setIsEnabled,
            setVerificationMessage
        }}>
            {children}
        </UserContext.Provider>


    );
};