import React, { useState } from 'react';
import dayjs from 'dayjs';
import CalendarDayModal from './CalendarDayModal';
import DayCard from './DayCard';
import useSellerOrdersByMonth from "../../../hooks/useSellerOrdersByDate";

function Calendar() {
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [selectedDayOrders, setSelectedDayOrders] = useState(null);

    const monthString = currentDate.format('YYYY-MM');
    const { orders, loading } = useSellerOrdersByMonth(monthString);

    const goToPreviousMonth = () => setCurrentDate(currentDate.subtract(1, 'month'));
    const goToNextMonth = () => setCurrentDate(currentDate.add(1, 'month'));

    const startOfMonth = currentDate.startOf('month');
    const endOfMonth = currentDate.endOf('month');
    const startDate = startOfMonth.startOf('week');
    const endDate = endOfMonth.endOf('week');

    const days = [];
    let day = startDate;
    while (day.isBefore(endDate)) {
        days.push(day);
        day = day.add(1, 'day');
    }

    const handleDayClick = (date) => {
        const dayOrders = orders.filter(order => dayjs(order.dateDelivery).isSame(date, 'day'));
        setSelectedDayOrders({ date: date.format('YYYY-MM-DD'), orders: dayOrders });
    };

    const handleOrderClick = (orderId) => {
        window.open(`${process.env.REACT_APP_FRONTEND_URL}/orders/${orderId}`, '_blank'); // Open order details in a new tab
    };

    const closeModal = () => {
        setSelectedDayOrders(null);
    };

    return (
        <div className="w-full max-w-full sm:max-w-7xl mx-auto bg-white shadow-xl rounded-lg p-4 sm:p-1">
            {/* Month and Year Navigation */}
            <div className="flex justify-between items-center mb-4 sm:mb-6">
                <button onClick={goToPreviousMonth} className="text-xl sm:text-2xl font-semibold">&lt;</button>
                <h2 className="text-2xl sm:text-3xl font-bold">{currentDate.format('MMMM YYYY')}</h2>
                <button onClick={goToNextMonth} className="text-xl sm:text-2xl font-semibold">&gt;</button>
            </div>

            {/* Days of the Week Header */}
            <div className="grid grid-cols-7 text-center font-semibold text-base sm:text-lg mb-2 sm:mb-4">
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                    <div key={day} className="text-gray-700">{day}</div>
                ))}
            </div>

            {/* Days Grid with Orders */}
            <div className="grid grid-cols-7 gap-1 sm:gap-3">
                {days.map((day, index) => {
                    const dayOrders = orders.filter(order => dayjs(order.dateDelivery).isSame(day, 'day'));
                    return (
                        <DayCard
                            key={index}
                            day={day}
                            orders={dayOrders}
                            currentMonth={currentDate.month()}
                            onDayClick={handleDayClick}
                            handleOrderClick={handleOrderClick} // Pass down the click handler
                        />
                    );
                })}
            </div>

            {/* Modal for Showing All Orders */}
            <CalendarDayModal
                selectedDayOrders={selectedDayOrders}
                closeModal={closeModal}
                handleOrderClick={handleOrderClick}
            />
        </div>
    );
}

export default Calendar;
