import React, { useState, useEffect, useContext } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaPen } from 'react-icons/fa';
import { SubscriptionContext } from '../../contexts/SubscriptionContext';

const ContactInformationComponent = ({ mobilePhone, email, setMobilePhone, setEmail, defaultEmail }) => {
    const [emailError, setEmailError] = useState('');
    const [isEditingEmail, setIsEditingEmail] = useState(false);

    const { selectedPlan } = useContext(SubscriptionContext);
    const { productType } = selectedPlan;

    const colors = {
        background: productType === 'PRODUCT' ? '#f49ac1' : '#C5E09D',
        border: productType === 'PRODUCT' ? '#B22a7a' : '#1e3f16',
        text: productType === 'PRODUCT' ? '#B23278' : '#253329',
        placeholder: '#ccc',
    };

    // Set email to default value initially
    useEffect(() => {
        if (!email) {
            setEmail(defaultEmail);
        }
    }, [defaultEmail, email, setEmail]);

    const handleMobilePhoneChange = (value) => {
        setMobilePhone(value);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(newEmail && !emailRegex.test(newEmail) ? 'Invalid email format' : '');
    };

    const toggleEmailEdit = () => {
        setIsEditingEmail(true); // Allow editing without clearing the email field
    };

    return (
        <div
            className="p-6 rounded-lg shadow-md max-w-3xl mx-auto font-poppins"
            style={{ backgroundColor: colors.background }}
        >
            <h2 className="text-2xl font-bold mb-6 text-center" style={{ color: colors.text }}>
                Contact Information
            </h2>

            {/* Mobile Phone Input */}
            <div className="mb-4">
                <label className="block text-lg font-semibold mb-2" style={{ color: colors.text }}>
                    Mobile Phone
                </label>
                <PhoneInput
                    country={'us'}
                    value={mobilePhone}
                    onChange={handleMobilePhoneChange}
                    enableSearch={true}
                    inputStyle={{
                        width: '100%',
                        padding: '10px',
                        borderRadius: '8px',
                        border: `1px solid ${colors.border}`,
                        backgroundColor: colors.placeholder,
                        color: colors.text,
                        paddingLeft: '50px',
                    }}
                    buttonStyle={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '0 10px',
                    }}
                    searchPlaceholder="Search by country"
                />
            </div>

            {/* Email Input */}
            <div className="mb-4">
                <label className="block text-lg font-semibold mb-2" style={{ color: colors.text }}>
                    Email
                </label>
                <div className="relative flex items-center">
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Enter email"
                        required
                        readOnly={!isEditingEmail} // Only editable if the pen icon is clicked
                        className="border p-3 rounded-md w-full"
                        style={{
                            backgroundColor: colors.placeholder,
                            borderColor: colors.border,
                            color: colors.text,
                        }}
                    />
                    {/*<span*/}
                    {/*    className="absolute right-3 cursor-pointer transition duration-300"*/}
                    {/*    style={{ color: colors.border }}*/}
                    {/*    onClick={toggleEmailEdit}*/}
                    {/*    title="Edit email"*/}
                    {/*>*/}
                    {/*    <FaPen size={18} />*/}
                    {/*</span>*/}
                </div>
                {emailError && <p className="text-red-500 text-sm mt-2">{emailError}</p>}
            </div>
        </div>
    );
};

export default ContactInformationComponent;
