import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import authenticatedInstance from "../../custom-axios/axios-auth";

const stripePromise = loadStripe("pk_live_51QEYaZ01STgJKTwletdR1ax7FDnUzI8BRDoLl3mNDOctvHjmDbjCx3zXVhV6ovuXbDFfSLjsITIOtIUfvXSh8CnM00MRXLdvfS");

const SubscriptionPlans = () => {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    // List of subscription plans
    const plans = [
        { name: "Standard", price: 69.99, priceId: "price_1QEa1h01STgJKTwl5ggLTevf" },
        { name: "Premium", price: 99.99, priceId: "price_1QEa2E01STgJKTwl0PfGTbBO" },
    ];

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                <h1 className="text-2xl font-bold text-center mb-6">Choose Your Plan</h1>
                {plans.map((plan) => (
                    <div key={plan.priceId} className="p-4 mb-4 bg-gray-100 rounded-lg shadow-md">
                        <h2 className="text-xl font-bold">{plan.name}</h2>
                        <p className="text-gray-700">${plan.price}/month</p>
                        <p className="text-sm text-gray-500">3-month trial available</p>
                        <button
                            onClick={() => setSelectedPlan(plan.priceId)}
                            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mt-4 w-full"
                            disabled={isLoading}
                        >
                            {isLoading ? "Processing..." : `Subscribe to ${plan.name}`}
                        </button>
                    </div>
                ))}
                {selectedPlan && (
                    <Elements stripe={stripePromise}>
                        <CheckoutForm
                            priceId={selectedPlan}
                            setMessage={setMessage}
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                        />
                    </Elements>
                )}
                <p className="mt-6 text-center text-gray-700">{message}</p>
            </div>
        </div>
    );
};

const CheckoutForm = ({ priceId, setMessage, setIsLoading, isLoading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);

        try {
            // Step 1: Create a payment method with the card details
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            if (error) {
                setError(error.message);
                setIsLoading(false);
                return;
            }

            // Step 2: Call the backend to create the subscription with a trial and SetupIntent
            const customerResponse = await authenticatedInstance.post("/subscription/create-customer", {
                paymentMethodId: paymentMethod.id,
            });

            const customerId = customerResponse.data.id;

            // Now pass the paymentMethodId when creating the subscription
            const subscriptionResponse = await authenticatedInstance.post("/subscription/create-subscription-with-trial", {
                customerId: customerId,
                priceId: priceId,
                trialDays: 90,
                paymentMethodId: paymentMethod.id, // Add paymentMethodId here
            });

            const { clientSecret } = subscriptionResponse.data;

            // Step 3: Confirm the SetupIntent for 3D Secure
            const confirmResult = await stripe.confirmCardSetup(clientSecret, {
                payment_method: paymentMethod.id,
            });

            if (confirmResult.error) {
                setMessage(`Setup failed: ${confirmResult.error.message}`);
            } else {
                setMessage("Subscription created and payment method authenticated!");
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement className="p-2 border border-gray-300 rounded-lg" />
            <button
                type="submit"
                disabled={!stripe || isLoading}
                className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 mt-4 w-full"
            >
                {isLoading ? "Processing..." : "Submit Payment"}
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
    );
};


export default SubscriptionPlans;