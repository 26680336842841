import React, { useState, useEffect } from 'react';
import authenticatedInstance from "../../custom-axios/axios-auth";
import AdminHomeImageSelection from './AdminHomeImageSelection';
import AdminHomeImageUploader from './AdminHomeImageUploader';

const AdminHomePageManager = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch images from the backend
        const fetchImages = async () => {
            try {
                const response = await authenticatedInstance.get('/admin/homepage-images');
                setImages(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching images:', error);
                setLoading(false);
            }
        };
        fetchImages();
    }, []);

    const handleSaveSelection = async (updatedImages) => {
        // Prepare the data to match the DTO expected by the backend
        const updateDTOs = updatedImages.map((img) => ({
            id: img.id,
            selected: img.selected,
            position: img.position,
        }));

        try {
            await authenticatedInstance.put('/admin/homepage-images/bulk-update', updateDTOs);
            alert('Images updated successfully');
            setImages(updatedImages); // Update state with the new image list
        } catch (err) {
            console.error('Error during image update:', err); // Add console to help debug
            alert('Failed to update images');
        }
    };


    const handleImageUpload = async (newImage) => {
        try {
            const formData = new FormData();
            formData.append('file', newImage);
            const response = await authenticatedInstance.post('/admin/homepage-images', formData);

            // Add the new image to the current list
            let updatedImages = [...images, response.data];

            // Automatically select the new image if there are fewer than 5 selected images
            const selectedImagesCount = updatedImages.filter((img) => img.selected).length;

            if (selectedImagesCount < 5) {
                updatedImages = updatedImages.map((img) => {
                    if (img.id === response.data.id) {
                        return {
                            ...img,
                            selected: true,
                            position: selectedImagesCount + 1 // Assign next available position
                        };
                    }
                    return img;
                });
            }

            setImages(updatedImages);
        } catch (err) {
            console.error('Failed to upload image:', err);
            alert('Failed to upload image');
        }
    };

    const handleDeleteImage = async (id) => {
        try {
            await authenticatedInstance.delete(`/admin/homepage-images/${id}`);
            setImages(images.filter((image) => image.id !== id));
        } catch (err) {
            console.error('Failed to delete image:', err);
            alert('Failed to delete image');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-6 font-poppins">
            <h1 className="text-3xl font-bold mb-4">Admin Homepage Image Manager</h1>

            {/* Uploader Component */}
            <AdminHomeImageUploader onUploadImage={handleImageUpload} onDeleteImage={handleDeleteImage} images={images} />

            {/* Selection Component */}
            <AdminHomeImageSelection images={images} onSaveSelection={handleSaveSelection} />
        </div>
    );
};

export default AdminHomePageManager;
