import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { UserContext } from "../../contexts/UserContext";
import authenticatedInstance from "../../custom-axios/axios-auth";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const NavLeftSection = () => {
    const {
        user,
        roles,
        isLoggedIn,
        isEnabled,
        hasPendingSellerRequest
    } = useContext(UserContext);

    const navigate = useNavigate();
    useEffect(() => {
    }, [roles, hasPendingSellerRequest, isEnabled]);

    const handleShopClick = async () => {
        try {
            const response = await authenticatedInstance.get(`/home/findShopByUserId/${user?.userId}`);
            if (response.status === 200) {
                const shop = response.data;
                navigate(`/shop-details/${shop.id}`);
            }
        } catch (error) {
            console.error('Error fetching shop:', error);
        }
    };

    const handleOrdersClick = (role) => {
        if (role === 'CUSTOMER') {
            navigate('/customer/orders');
        } else if (role === 'SELLER') {
            navigate('/seller/orders');
        }
    };

    return (
        <div className="flex items-center justify-start w-full">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0">
                <h1 className="text-primaryProductColor font-rageItalic text-4xl leading-none">
                    TheHomeMadeShop.com
                </h1>
            </Link>

            {/* Desktop Menu Links (visible above 880px) */}
            <div className="hidden lg:flex sm:ml-6">
                <div className="flex space-x-4">
                    {isLoggedIn && (
                        <>
                            {roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && isEnabled && (
                                <Link to="/seller/register"
                                      className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                    Register as Seller!
                                </Link>
                            )}
                            {hasPendingSellerRequest && (
                                <Link to="/submission"
                                      className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                    View Submission
                                </Link>
                            )}
                            {roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && !roles.includes('ROLE_ADMINISTRATOR') && (
                                <button onClick={handleShopClick}
                                        className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                    View Your Shop
                                </button>
                            )}
                            {roles.includes('ROLE_ADMINISTRATOR') && (
                                <Link to="/admin"
                                      className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                    View Shops
                                </Link>
                            )}

                            {/* Orders Logic */}
                            {roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_ADMINISTRATOR') && roles.includes('ROLE_SELLER') && !hasPendingSellerRequest ? (
                                <Menu as="div" className="relative z-50">
                                    <Menu.Button className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                        Orders
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute mt-2 w-auto min-w-[12rem] max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => handleOrdersClick('CUSTOMER')}
                                                        className={classNames(
                                                            active ? 'bg-secondaryServiceColor text-white' : 'text-primaryServiceColor',
                                                            'block w-full text-left px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Your Orders
                                                    </button>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => handleOrdersClick('SELLER')}
                                                        className={classNames(
                                                            active ? 'bg-secondaryServiceColor text-white' : 'text-primaryServiceColor',
                                                            'block w-full text-left px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Shop Orders
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            ) : roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_ADMINISTRATOR') ? (
                                <button
                                    onClick={() => handleOrdersClick('CUSTOMER')}
                                    className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                >
                                    Your Orders
                                </button>
                            ) : roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && !roles.includes('ROLE_ADMINISTRATOR') ? (
                                <button
                                    onClick={() => handleOrdersClick('SELLER')}
                                    className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                >
                                    Shop Orders
                                </button>
                            ) : null}
                        </>
                    )}
                </div>
            </div>

            {/* Hamburger Menu (visible below 880px) */}
            <div className="lg:hidden">
                <Disclosure>
                    {({ open }) => (
                        <>
                            <Disclosure.Button
                                className="inline-flex items-center justify-center rounded-md p-2 text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Open main menu</span>
                                {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> :
                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                            </Disclosure.Button>

                            <Disclosure.Panel className="lg:hidden">
                                <div className="absolute left-0 right-0 top-full bg-secondaryServiceColor shadow-lg rounded-lg px-4 py-3 space-y-2 z-50">
                                    {isLoggedIn && (
                                        <>
                                            {roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && isEnabled && (
                                                <Link to="/seller/register" className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                    Register as Seller!
                                                </Link>
                                            )}
                                            {hasPendingSellerRequest && (
                                                <Link to="/submission" className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                    View Submission
                                                </Link>
                                            )}
                                            {roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && !roles.includes('ROLE_ADMINISTRATOR') && (
                                                <button
                                                    onClick={handleShopClick}
                                                    className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                                                >
                                                    View Your Shop
                                                </button>
                                            )}
                                            {roles.includes('ROLE_ADMINISTRATOR') && (
                                                <Link to="/admin" className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                    View Shops
                                                </Link>
                                            )}

                                            {/* Orders Logic for Mobile */}
                                            {roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_ADMINISTRATOR') &&roles.includes('ROLE_SELLER') && !hasPendingSellerRequest ? (
                                                <Menu as="div" className="relative">
                                                    <Menu.Button className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                        Orders
                                                    </Menu.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => handleOrdersClick('CUSTOMER')}
                                                                        className={classNames(
                                                                            active ? 'bg-secondaryServiceColor text-white' : 'text-primaryServiceColor',
                                                                            'block px-4 py-2 text-sm'
                                                                        )}
                                                                    >
                                                                        Your Orders
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => handleOrdersClick('SELLER')}
                                                                        className={classNames(
                                                                            active ? 'bg-secondaryServiceColor text-white' : 'text-primaryServiceColor',
                                                                            'block px-4 py-2 text-sm'
                                                                        )}
                                                                    >
                                                                        Shop Orders
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            ) : roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_ADMINISTRATOR') ? (
                                                <button
                                                    onClick={() => handleOrdersClick('CUSTOMER')}
                                                    className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                                                >
                                                    Your Orders
                                                </button>
                                            ) : roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && !roles.includes('ROLE_ADMINISTRATOR') ? (
                                                <button
                                                    onClick={() => handleOrdersClick('SELLER')}
                                                    className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                                                >
                                                    Shop Orders
                                                </button>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    );
};

export default NavLeftSection;
