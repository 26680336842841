
const StatusSelect = ({ statusOptions, newStatus, handleStatusChange }) => {
    return (
        <div>
            <label className="block text-darkBrown font-semibold text-sm mb-2">Change status to:</label>
            <select
                value={newStatus}
                onChange={(e) => handleStatusChange(e.target.value)}
                className="w-full p-2 border border-lightBrown rounded-md focus:outline-none focus:ring-2 focus:ring-primaryGreen"
            >
                <option value="">Select Status</option>
                {statusOptions.map((status, idx) => (
                    <option key={idx} value={status}>
                        {status}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default StatusSelect;