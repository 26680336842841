import React, { useState, useContext } from 'react';
import SubscriptionSelectionComponent from './SubscriptionSelectionComponent';
import SellerRegistrationComponent from './SellerRegistrationComponent';
import { SubscriptionContext } from '../../contexts/SubscriptionContext';

const SellerRegistrationWrapper = () => {
    const { selectedPlan, setSelectedPlan } = useContext(SubscriptionContext);
    const [hasSelectedPlan, setHasSelectedPlan] = useState(false);

    const handlePlanSelection = (planDetails) => {
        setSelectedPlan(planDetails);  // Update the context with the selected plan details (plan, productType, and productLimit)
        setHasSelectedPlan(true);  // Set local state to indicate that the plan has been selected
    };

    // Reset the selected plan and go back to SubscriptionSelectionComponent
    const handleGoBack = () => {
        setHasSelectedPlan(false);
    };

    return (
        <div>
            {hasSelectedPlan ? (
                    <div>
                        {/* "Go Back" button in Seller Registration to return to Subscription Selection */}
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={handleGoBack}
                                className="px-4 py-2 bg-primaryServiceColor text-white rounded-md hover:bg-secondaryServiceColor transition">
                                Back to Subscription Selection
                            </button>
                        </div>
                        <SellerRegistrationComponent/>
                    </div>

            ) : (
                <div>
                    <SubscriptionSelectionComponent onSelectPlan={handlePlanSelection}/>
                    {/*/!* Display this button only if we are viewing Subscription Selection *!/*/}
                    {/*<div className="flex justify-center mt-4">*/}
                    {/*    <button*/}
                    {/*        onClick={handleGoBack}*/}
                    {/*        className="px-4 py-2 bg-primaryProductColor text-white rounded-md hover:bg-secondaryProductColor transition">*/}
                    {/*        Proceed to Registration*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    );
};

export default SellerRegistrationWrapper;
