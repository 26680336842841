import React, {useEffect, useRef, useState} from 'react';
import authenticatedInstance from "../../custom-axios/axios-auth";
import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';
import ReactModal from 'react-modal';
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from 'react-virtualized';
import './MessageList.css'
import Avatar from "@mui/material/Avatar";

const MessageList = ({userId, conversation, chatUser, currentUser}) => {
    const [messages, setMessages] = useState([]);  // To store all messages
    const [hasMore, setHasMore] = useState(true);  // To control pagination
    const [page, setPage] = useState(0);           // Track the current page
    const [firstPageLoaded, setFirstPageLoaded] = useState(false); // Track if the first page is loaded
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [reportMessageId, setReportMessageId] = useState(null); // Track the reported message
    const [hoveredMessageId, setHoveredMessageId] = useState(null); // Track hovered message
    const BASE_FILE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/files/download`;

    const [clickedMessageId, setClickedMessageId] = useState(null); // delete this

    const messageIds = useRef(new Set());          // Track message IDs to avoid duplicates
    const chatMessagesRef = useRef(null);
    const stompClient = useRef(null);
    const listRef = useRef(null);
    const previousConversationId = useRef(null);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState({type: '', url: ''});

    // When conversation changes, reset messages and fetch the first page
    useEffect(() => {
        if (conversation && userId) {
            // Clear messages and reset pagination
            setMessages([]);  // Clear existing messages
            setPage(0);       // Reset the page
            messageIds.current.clear();  // Clear message IDs set
            setHasMore(true); // Reset pagination flag
            setFirstPageLoaded(false);  // Reset the first page flag

            // Load the first page of the new conversation
            const loadFirstPage = async () => {
                await loadMessages(userId, conversation.userId, 0);
                setFirstPageLoaded(true);  // Mark the first page as loaded
            };

            loadFirstPage();
        }
    }, [conversation, userId]);

    // Load messages when page changes
    useEffect(() => {
        if (conversation && userId && page > 0 && firstPageLoaded) {
            // Only load subsequent pages if we are past the first page and the first page is fully loaded
            loadMessages(userId, conversation.userId, page);
        }
    }, [page, firstPageLoaded]);
    useEffect(() => {
        if (messages.length > 0) {
            scrollToBottom();
        }
    }, [messages]);

    // Function to load messages from the backend
    const loadMessages = async (currentUserId, userId, page) => {

        try {
            const response = await authenticatedInstance.get(`/messages/${currentUserId}/${userId}`, {
                params: {page: page, size: 20}  // Pagination parameters
            });


            const formattedMessages = response.data.content.map(msg => ({
                ...msg,
                id: String(msg.id),
                senderId: String(msg.senderId),
                recipientId: String(msg.recipientId),
                type: msg.type,
                content: msg.content,
                fileName: msg.fileName,
                fileType: msg.fileType
            }));

            setMessages(prevMessages => {
                const newMessageIds = formattedMessages.map(msg => msg.id);
                const uniqueMessages = prevMessages.filter(
                    msg => !newMessageIds.includes(msg.id)
                );
                return [...formattedMessages, ...uniqueMessages];
            });

            if (response.data.last) {
                setHasMore(false);  // No more pages
            }

            // Mark messages as seen
            markMessagesAsSeen(currentUserId, conversation.userId);

        } catch (error) {
            console.error('Error fetching chat messages:', error);
        }
    };


    // WebSocket Management
    useEffect(() => {
        const socket = new SockJS(`${process.env.REACT_APP_BACKEND_URL}/ws`);
        // const socket = new SockJS(`http://ws.thehomemadeshop.com/ws`);
        const client = Stomp.over(socket);
        stompClient.current = client;

        client.connect({}, () => {
            const chatTopic1 = `/topic/chat/${userId}_${conversation.userId}`;
            const chatTopic2 = `/topic/chat/${conversation.userId}_${userId}`;

            const subscription1 = client.subscribe(chatTopic1, message => {
                handleIncomingMessage(message, userId, conversation.userId);
            });

            const subscription2 = client.subscribe(chatTopic2, message => {
                handleIncomingMessage(message, userId, conversation.userId);
            });

            return () => {
                subscription1.unsubscribe();
                subscription2.unsubscribe();
                if (stompClient.current) {
                    stompClient.current.disconnect();
                }
            };
        });

        return () => {
            if (stompClient.current) {
                stompClient.current.disconnect();
            }
        };
    }, [conversation, userId]);

    const handleIncomingMessage = (message, currentUserId, conversationUserId) => {
        const msg = JSON.parse(message.body);
        if (!messageIds.current.has(String(msg.id))) {
            messageIds.current.add(String(msg.id));
            setMessages(prevMessages => [...prevMessages, msg]);
            scrollToBottom();

            if (msg.recipientId === currentUserId) {
                markMessagesAsSeen(currentUserId, conversationUserId);
            }
        }
    };

    // Mark Messages as Seen
    const markMessagesAsSeen = (userId, recipientId) => {
        authenticatedInstance.post('/messages/seen', null, {
            params: {
                senderId: recipientId,
                recipientId: userId
            }
        }).catch(error => {
            console.error('Error marking messages as seen:', error);
        });
    };

    // Scroll to bottom helper
    const scrollToBottom = () => {
        if (listRef.current) {
            setTimeout(() => {
                listRef.current.scrollToRow(messages.length - 1);
            }, 100); // Delay to ensure the list is fully rendered
        }
    };
    const handleScroll = () => {
        const chatMessages = chatMessagesRef.current;
        if (!chatMessages || !hasMore || !firstPageLoaded) return;  // Ensure all conditions are met

        const {scrollTop} = chatMessages;

        if (scrollTop === 0) {

            const previousHeight = chatMessages.scrollHeight;

            // Load the next page of messages (increment page)
            setPage(prevPage => prevPage + 1);

            // Adjust scroll position after loading new messages
            requestAnimationFrame(() => {
                setTimeout(() => {
                    const newHeight = chatMessages.scrollHeight;
                    chatMessages.scrollTop = newHeight - previousHeight; // Maintain scroll position
                }, 0);
            });
        }
    };

    const openModal = (type, url) => {
        setModalContent({type, url});
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const getFileUrl = (msg) => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL; // Base URL
        const fileId = msg.content; // Assuming this is the fileId

        if (msg.fileType.startsWith('video/')) {
            return `${backendUrl}/api/files/stream/${fileId}`; // Use the stream URL
        } else {
            return `${backendUrl}/api/files/download/${fileId}`; // Use the download URL
        }
    };

    const renderMessageContent = (content) => {
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g; // Regex to match [text](url) pattern
        let match;
        const parts = [];
        let lastIndex = 0;

        while ((match = linkRegex.exec(content)) !== null) {
            const [fullMatch, text, url] = match;
            const start = match.index;

            // Push preceding text
            if (start > lastIndex) {
                parts.push(content.substring(lastIndex, start));
            }

            // Push the link
            parts.push(
                <a
                    key={start}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                >
                    {text}
                </a>
            );

            lastIndex = linkRegex.lastIndex;
        }

        // Push the remaining text
        if (lastIndex < content.length) {
            parts.push(content.substring(lastIndex));
        }

        return <>{parts}</>;
    };


    const openReportModal = (messageId) => {
        setReportMessageId(messageId);
        setIsReportModalOpen(true);
    };

    const closeReportModal = () => {
        setIsReportModalOpen(false);
        setReportMessageId(null); // Clear the messageId when closing
    };

    const submitReport = ({messageId, reason}) => {
        authenticatedInstance.post('/report-message', {messageId, reason, userId})
            .then(() => {
                alert('Report submitted successfully');
                closeReportModal();
            })
            .catch((error) => {
                console.error('Error submitting report:', error);
                alert('Error submitting report');
            });
    };
    const handleMessageClick = (messageId) => {
        setClickedMessageId((prevMessageId) => (prevMessageId === messageId ? null : messageId));
    };

    const escapeHtml = (unsafe) => {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };
    const getOrderStatusBorderColor = (status) => {
        switch (status) {
            case 'AWAITING_APPROVAL':
                return 'border-yellow-500'; // Yellow border for awaiting approval
            case 'APPROVED':
                return 'border-green-500'; // Green border for approved orders
            case 'ACTIVE':
                return 'border-blue-500'; // Blue border for active orders
            case 'COMPLETED':
                return 'border-purple-500'; // Purple border for completed orders
            case 'CANCELLED':
                return 'border-red-500'; // Red border for cancelled orders
            case 'EXPIRED':
                return 'border-gray-500'; // Gray border for expired orders
            case 'DELIVERED':
                return 'border-teal-500'; // Teal border for delivered orders
            default:
                return 'border-transparent'; // Default: no border
        }
    };
    // Virtualized row renderer for messages
    const rowRenderer = ({index, key, parent, style}) => {
        const msg = messages[index];
        const isSender = msg.senderId === userId;

        // Function to trigger re-measuring the row height after media load
        const handleMediaLoad = () => {
            if (listRef.current) {
                cache.clear(index); // Clear the cache for this row
                listRef.current.recomputeRowHeights(index); // Recompute the row height after the image/video is loaded
            }
        };

        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div
                    style={style}
                    className={`mb-4 flex ${isSender ? 'justify-end' : 'justify-start'}`}
                    onClick={() => handleMessageClick(msg.id)}
                >
                    {!isSender && (
                        chatUser?.imageLink ? (
                            <Avatar alt={chatUser?.fullName[0]}
                                    className="w-8 h-8 rounded-full mr-4"
                                    src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${chatUser?.imageLink}`}/>
                        ) : (
                            <Avatar className="w-8 h-8 rounded-full ml-4"
                            >{chatUser?.fullName[0]}</Avatar>
                        )
                    )}
                    <div
                        className={`margin-bottom-10 py-2 px-4 rounded-xl max-w-xs shadow-lg font-quicksand 
                ${isSender ? 'bg-primaryGreen text-darkBrown rounded-br-none' : 'bg-lightBrown text-white rounded-bl-none'} 
                ${msg.order?.status?.status ? getOrderStatusBorderColor(msg.order.status.status) : ''}`}
                        style={{ borderWidth: msg.order?.status?.status ? '2px' : '0' }} // Ensures a visible border
                    >
                        {msg.type === 'FILE' ? (
                            <div className="relative">
                                {msg.fileType.startsWith('image/') ? (
                                    <img
                                        src={getFileUrl(msg)}
                                        alt={msg.fileName}
                                        className="w-full h-auto rounded-lg cursor-pointer object-contain"
                                        onClick={() => openModal('image', getFileUrl(msg))}
                                        onLoad={handleMediaLoad}
                                    />
                                ) : msg.fileType.startsWith('video/') ? (
                                    <video
                                        src={getFileUrl(msg)}
                                        controls
                                        className="w-full h-auto rounded-lg"
                                        onClick={() => openModal('video', getFileUrl(msg))}
                                        onLoadedData={handleMediaLoad}
                                    />
                                ) : (
                                    <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}/api/files/download/${msg.content}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-darkBrown hover:underline font-poppins"
                                    >
                                        {msg.fileName || 'Download File'}
                                    </a>
                                )}
                            </div>
                        ) : (
                            <div className="whitespace-normal">{renderMessageContent(msg.content)}</div>
                        )}
                    </div>
                    {isSender && (
                        currentUser?.imageLink ? (
                            <Avatar alt={currentUser?.fullName[0]}
                                    className="w-8 h-8 rounded-full ml-2"
                                    src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${currentUser?.imageLink}`}/>
                        ) : (
                            <Avatar className="w-8 h-8 rounded-full ml-2"
                            >{currentUser?.fullName[0]}</Avatar>
                        )
                    )}
                    {clickedMessageId === msg.id && (
                        <button
                            className="ml-2 text-customRed hover:text-red-700 transition duration-300"
                            onClick={() => openReportModal(msg.id)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M12 8v4m0 4h.01M19 12a7 7 0 10-14 0 7 7 0 0014 0z"/>
                            </svg>
                        </button>
                    )}
                </div>
            </CellMeasurer>
        );
    };


    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
        minHeight: 50,
        keyMapper: (rowIndex) => messages[rowIndex]?.id || rowIndex,
    });

    return (
        <>
            <div className="messages" ref={chatMessagesRef} style={{height: '100%', width: '100%'}}>
                {messages.length === 0 &&
                    <p className="text-darkBrown text-center mt-6 font-raleway">No messages available</p>}
                <AutoSizer>
                    {({height, width}) => (
                        <List
                            ref={listRef}
                            width={width}
                            height={height}
                            rowCount={messages.length}
                            rowHeight={cache.rowHeight}
                            deferredMeasurementCache={cache}
                            rowRenderer={rowRenderer}
                            onScroll={handleScroll}
                        />
                    )}
                </AutoSizer>
            </div>

            {/* File Modal */}
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="File Modal"
                className="modal p-6 bg-white rounded-lg shadow-xl border-2 border-lightBrown max-w-3xl mx-auto my-10"
                overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                {modalContent.type === 'image' ? (
                    <div className="flex flex-col items-center">
                        <img
                            src={modalContent.url}
                            alt="Modal content"
                            className="max-w-full max-h-[70vh] rounded-lg shadow-md object-contain"
                        />
                        <button onClick={closeModal} className="custom-btn-outline mt-4">
                            Close
                        </button>
                    </div>
                ) : modalContent.type === 'video' ? (
                    <div className="flex flex-col items-center">
                        <video
                            src={modalContent.url}
                            controls
                            className="w-full max-h-[70vh] rounded-lg shadow-md"
                        />
                        <button onClick={closeModal} className="custom-btn-outline mt-4">
                            Close
                        </button>
                    </div>
                ) : null}
            </ReactModal>


            {/* Report Modal */}
            <ReactModal
                isOpen={isReportModalOpen}
                onRequestClose={closeReportModal}
                contentLabel="Report Message"
                className="modal p-6 bg-white rounded-lg shadow-xl border-2 border-customPinkRed max-w-xl mx-auto my-10"
                overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                <h2 className="text-xl font-bold text-customPinkRed font-poppins mb-4">Report Message</h2>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    const reason = e.target.reason.value;
                    submitReport({messageId: reportMessageId, reason});
                }}>
                    <label className="block mb-2 text-darkBrown font-nunito">
                        Reason for reporting:
                        <textarea
                            name="reason"
                            rows="4"
                            className="w-full p-2 mt-1 border border-lightBrown rounded-md focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                            required
                        />
                    </label>
                    <div className="flex justify-end space-x-4">
                        <button type="button" onClick={closeReportModal} className="custom-btn-secondary">
                            Cancel
                        </button>
                        <button type="submit" className="custom-btn-critical">
                            Submit Report
                        </button>
                    </div>
                </form>
            </ReactModal>
        </>
    );
};

export default MessageList;