import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from "../../custom-axios/axios";
import { debounce } from 'lodash';

const Autocomplete = ({ setZip, zipCode }) => {
    const [input, setInput] = useState(zipCode || '');
    const [suggestions, setSuggestions] = useState([]);
    const [cache, setCache] = useState({});
    const dropdownRef = useRef(null);

    // Debounced function to avoid making requests on every keystroke
    const debouncedSearch = useCallback(
        debounce((query) => fetchSuggestions(query), 300),
        []
    );

    useEffect(() => {
        if (zipCode) {
            setInput(zipCode);
        }
    }, [zipCode]);

    useEffect(() => {
        if (input.length > 1) {
            if (cache[input]) {
                setSuggestions(cache[input]);
            } else {
                debouncedSearch(input);
            }
        } else {
            setSuggestions([]);
        }
    }, [input]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
        setZip(e.target.value);
    };

    const fetchSuggestions = async (query) => {
        try {
            const response = await axios.get(`/search/autocomplete?input=${query}`);
            const result = response.data;

            setCache((prevCache) => ({
                ...prevCache,
                [query]: result,
            }));

            setSuggestions(result);
        } catch (error) {
            console.error("Error fetching suggestions:", error);
        }
    };

    const handleSelectSuggestion = (zipCode) => {
        setInput(zipCode);
        setZip(zipCode);
        setSuggestions([]);
    };

    // Handle click outside dropdown to close suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the clicked element is inside the dropdownRef
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSuggestions([]); // Clear suggestions if clicked outside
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative z-10 w-full" ref={dropdownRef}>  {/* Wrap both input and suggestions */}
            <input
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Enter ZIP code, city, or county"
                className="border border-gray-300 p-3 rounded-md w-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primaryGreen"
            />
            {suggestions.length > 0 && (
                <ul
                    className="absolute z-20 bg-white border border-gray-300 w-full max-h-40 overflow-y-auto mt-1 rounded-md shadow-lg"
                >
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSelectSuggestion(suggestion.postalCode)}
                            className="cursor-pointer p-2 hover:bg-primaryGreen hover:text-white transition-colors"
                        >
                            <strong>{suggestion.postalCode}</strong> - {suggestion.cityName}, {suggestion.countyName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Autocomplete;
