const getStatusMessage = (newStatus, selectedOrder, note) => {
    if (!selectedOrder) return '';

    const baseUrl = process.env.REACT_APP_FRONTEND_URL;
    const orderUrl = `${baseUrl}/orders/${selectedOrder.id}`;
    const productUrl = `${baseUrl}/product/${selectedOrder.productId}`;

    let message = `[Order ${selectedOrder.id}](${orderUrl}) for Product: [${selectedOrder.productTitle}](${productUrl})`;

    switch (newStatus) {
        case 'APPROVED':
            message += ` has been approved.`;
            break;

        case 'CANCELLED':
            message += ` has been cancelled.`;
            break;

        case 'COMPLETED':
            message += ` has been completed.`;
            break;

        case 'DELIVERED':
            message += ` has been delivered.`;
            break;

        case 'EXPIRED':
            message += ` has expired.`;
            break;

        default:
            message += ` has been updated to ${newStatus}.`;
            break;
    }

    // Conditionally add the note if it's not empty
    if (note && note.trim() !== '') {
        message += `\nNote: ${note}`;
    }

    return message;
};

export default getStatusMessage;
