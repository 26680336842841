import React, { useState, useEffect } from 'react';

const AdminHomeImageSelection = ({ images, onSaveSelection }) => {
    const [updatedImages, setUpdatedImages] = useState(images);
    const [warning, setWarning] = useState(false);

    const baseUrl = "http://localost:8081";

    useEffect(() => {
        setUpdatedImages(images);
    }, [images]);

    // Calculate available positions for selected images
    const getAvailablePositions = () => {
        const positionsInUse = updatedImages
            .filter((img) => img.selected)
            .map((img) => img.position);
        const availablePositions = new Set([1, 2, 3, 4, 5]);
        positionsInUse.forEach((pos) => availablePositions.delete(pos));
        return availablePositions;
    };

    // Handle selecting/unselecting images
    const handleSelectImage = (id) => {
        const selectedImage = updatedImages.find((img) => img.id === id);
        let newImages;

        if (selectedImage.selected) {
            // Unselecting the image
            newImages = updatedImages.map((img) =>
                img.id === id ? { ...img, selected: false, position: null } : img
            );
        } else {
            // Selecting the image
            const availablePositions = [...getAvailablePositions()];
            if (availablePositions.length > 0) {
                newImages = updatedImages.map((img) =>
                    img.id === id ? { ...img, selected: true, position: availablePositions[0] } : img
                );
            } else {
                alert("Cannot select more than 5 images.");
                return;
            }
        }

        const selectedCount = newImages.filter((img) => img.selected).length;
        setWarning(selectedCount < 5);
        setUpdatedImages(newImages);
    };

    // Handle position change by swapping positions
    const handlePositionChange = (id, newPosition) => {
        const imageToMove = updatedImages.find((img) => img.id === id);
        const imageAtNewPosition = updatedImages.find((img) => img.position === newPosition);

        // Swap the positions
        const imagesToUpdate = updatedImages.map((img) => {
            if (img.id === id) return { ...img, position: newPosition };
            if (img.id === imageAtNewPosition?.id) return { ...img, position: imageToMove.position };
            return img;
        });
        setUpdatedImages(imagesToUpdate);
    };

    // Handle saving selection
    const handleSave = () => {
        const selectedImagesCount = updatedImages.filter((img) => img.selected).length;
        if (selectedImagesCount !== 5) {
            alert('You must select exactly 5 images.');
            return;
        }
        onSaveSelection(updatedImages);
    };

    // Separate selected and unselected images
    const selectedImages = updatedImages.filter((img) => img.selected).sort((a, b) => a.position - b.position);
    const unselectedImages = updatedImages.filter((img) => !img.selected);

    return (
        <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Select and Order Homepage Images</h2>

            {warning && <div className="text-red-500 mb-4">You must select exactly 5 images.</div>}

            {/* Selected Images */}
            <div>
                <h3 className="text-xl font-semibold mb-2">Selected Images (Top 5)</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
                    {selectedImages.map((image) => (
                        <div key={image.id} className="relative border p-2 border-green-500">
                            <img
                                src={image.imageUrl.startsWith("http") ? image.imageUrl : `${baseUrl}${image.imageUrl}`}
                                alt="Home Page"
                                className="w-full h-32 object-cover mb-2"
                            />
                            <div className="flex items-center justify-between mb-2">
                                <button
                                    onClick={() => handleSelectImage(image.id)}
                                    className="px-4 py-1 rounded bg-red-500 text-white"
                                >
                                    Unselect
                                </button>
                                <input
                                    type="number"
                                    value={image.position}
                                    onChange={(e) => handlePositionChange(image.id, parseInt(e.target.value))}
                                    className="w-12 border rounded px-2"
                                    min="1"
                                    max="5"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Unselected Images */}
            <div>
                <h3 className="text-xl font-semibold mb-2">Available Images</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {unselectedImages.map((image) => (
                        <div key={image.id} className="relative border p-2">
                            <img
                                src={image.imageUrl.startsWith("http") ? image.imageUrl : `${baseUrl}${image.imageUrl}`}
                                alt="Home Page"
                                className="w-full h-32 object-cover mb-2"
                            />
                            <div className="flex items-center justify-between mb-2">
                                <button
                                    onClick={() => handleSelectImage(image.id)}
                                    className="px-4 py-1 rounded bg-green-500 text-white"
                                >
                                    Select
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Save Changes Button */}
            <button
                onClick={handleSave}
                className="mt-6 px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50"
                disabled={updatedImages.filter((img) => img.selected).length !== 5}
            >
                Save Changes
            </button>
        </div>
    );
};

export default AdminHomeImageSelection;
