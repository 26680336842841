import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import { FaTrash } from 'react-icons/fa';  // Import FontAwesome Trash icon
import getCroppedImg from './getCroppedImg';

Modal.setAppElement('#root');

const ImageCropperComponent = ({ label, aspectRatio, onImageChange }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    // Handle file selection using react-dropzone
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewImage(reader.result);
                setShowCropModal(true);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

    // Handle crop area change
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // Save cropped image
    const handleSaveCrop = useCallback(async () => {
        try {
            if (!previewImage || !croppedAreaPixels) {
                console.error('No image or crop area available');
                return;
            }

            const croppedImage = await getCroppedImg(previewImage, croppedAreaPixels);
            onImageChange(croppedImage);

            // Update preview image with cropped image data URL
            const reader = new FileReader();
            reader.readAsDataURL(croppedImage);
            reader.onloadend = () => {
                setPreviewImage(reader.result);
                setShowCropModal(false);
            };
        } catch (e) {
            console.error('Error cropping the image:', e);
        }
    }, [previewImage, croppedAreaPixels, onImageChange]);

    // Handle removing profile picture
    const handleRemovePicture = () => {
        setPreviewImage(null);
        onImageChange(null);
    };

    // Handle clicking on the image to crop again
    const handleImageClick = () => {
        if (previewImage) {
            setShowCropModal(true);
        }
    };

    return (
        <div className="mb-6">
            {/* Only show Dropzone when there is no image */}
            {!previewImage && (
                <div
                    {...getRootProps()}
                    className={`border-dashed border-2 rounded-lg p-6 cursor-pointer 
                        ${isDragActive ? 'border-primaryGreen bg-gray-200' : 'border-gray-300 bg-gray-50'}
                        focus:outline-none transition-all`}
                >
                    <input {...getInputProps()} />
                    <div className="text-center">
                        {isDragActive ? (
                            <p className="text-sm text-darkBrown">Drop the files here...</p>
                        ) : (
                            <div>
                                <p className="text-sm text-darkBrown font-semibold">Click to upload or drag and drop</p>
                                <p className="text-xs text-gray-500">SVG, PNG, JPG, or GIF (MAX. 800x400px)</p>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Preview and Remove Image Section */}
            {previewImage && (
                <div className="mt-4 flex flex-col items-center">
                    <img
                        src={previewImage}
                        alt="Preview"
                        className="w-48 h-36 object-cover rounded-md border cursor-pointer"
                        onClick={handleImageClick}
                    />
                    <button
                        onClick={handleRemovePicture}
                        className="mt-4 flex items-center justify-center px-4 py-2 text-white bg-customRed rounded-lg shadow-md hover:bg-customPinkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-customPinkRed transition duration-300"
                    >
                        <FaTrash className="mr-2" /> Remove Image
                    </button>
                </div>
            )}

            {/* Crop Modal */}
            <Modal
                isOpen={showCropModal}
                onRequestClose={() => setShowCropModal(false)}
                contentLabel="Crop Image"
                className="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-lg mx-auto h-full md:h-auto flex flex-col items-center justify-center overflow-y-auto"
                overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
            >
                <h3 className="text-lg font-bold mb-4 text-darkBrown text-center">Crop Image</h3>
                <div className="relative w-full h-64">
                    <Cropper
                        image={previewImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspectRatio}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>
                <div className="mt-4 flex justify-center space-x-4 w-full">
                    <button
                        onClick={handleSaveCrop}
                        className="custom-btn-primary w-full md:w-auto"
                    >
                        Save Cropped Image
                    </button>
                    <button
                        onClick={() => setShowCropModal(false)}
                        className="custom-btn-remove w-full md:w-auto"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ImageCropperComponent;
