import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const PrivateRoute = ({ allowedRoles, excludedRoles = [] }) => {
    const location = useLocation();
    const token = localStorage.getItem('JWT');

    if (!token) {
        // Redirect to login page and pass the current location in state
        return <Navigate to="/login" state={{ from: location }} />;
    }

    try {
        const decodedToken = jwtDecode(token);
        const userRoles = decodedToken.roles;

        // Check if the user has any role that is excluded
        const isExcluded = userRoles.some(role => excludedRoles.includes(role));
        if (isExcluded) {
            return <Navigate to="/unauthorized" />;
        }

        // Check if the user has any role that is allowed
        const isAuthorized = userRoles.some(role => allowedRoles.includes(role));
        return isAuthorized ? <Outlet /> : <Navigate to="/unauthorized" />;
    } catch (error) {
        console.error('Invalid token:', error);
        return <Navigate to="/login" state={{ from: location }} />;
    }
};

export default PrivateRoute;
