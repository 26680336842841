import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import authenticatedInstance from "../../custom-axios/axios-auth";
import { Link } from "react-router-dom"; // Import Link for "See More Subscription Info" button

const stripePromise = loadStripe("pk_live_51QEYaZ01STgJKTwletdR1ax7FDnUzI8BRDoLl3mNDOctvHjmDbjCx3zXVhV6ovuXbDFfSLjsITIOtIUfvXSh8CnM00MRXLdvfS");

const StandardSubscription = () => {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const selectedPlan = "price_1QEa1h01STgJKTwl5ggLTevf"; // Standard price ID
    const [hasTrial, setHasTrial] = useState(false);

    useEffect(() => {
        // Check if today's date is before March 1, 2025
        const today = new Date();
        const trialEndDate = new Date(2025, 2, 1); // March 1, 2025 (0-indexed month)

        setHasTrial(today < trialEndDate); // Set trial eligibility based on date
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-tertiaryProductColor p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                <h1 className="text-2xl font-bold text-center text-primaryProductColor mb-4">Standard Plan - $69.99/month</h1>
                <p className="text-gray-700 text-center mb-2">Ideal for sellers with a small range. Includes up to 10 listings, a personalized calendar, and email receipts.</p>
                {hasTrial && (
                    <p className="text-green-500 text-sm mt-2 text-center">Free trial available until March 1, 2025!</p>
                )}
                <Link
                    to="/footer/subscriptions"
                    className="text-primaryServiceColor font-semibold hover:underline block text-center mt-2 mb-4"
                >
                    See More Subscription Info
                </Link>

                <Elements stripe={stripePromise}>
                    <CheckoutForm
                        priceId={selectedPlan}
                        setMessage={setMessage}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        hasTrial={hasTrial}
                    />
                </Elements>

                <p className="mt-6 text-center text-gray-700">{message}</p>
            </div>
        </div>
    );
};

const CheckoutForm = ({ priceId, setMessage, setIsLoading, isLoading, hasTrial }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);

        try {
            const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            if (paymentMethodError) {
                setError(paymentMethodError.message);
                setIsLoading(false);
                return;
            }

            const customerResponse = await authenticatedInstance.post("/subscription/create-customer", {
                paymentMethodId: paymentMethod.id,
            });

            const customerId = customerResponse.data.id;

            const subscriptionResponse = await authenticatedInstance.post("/subscription/create-subscription-with-trial", {
                customerId: customerId,
                priceId: priceId,
                trialDays: hasTrial ? 0 : 0,
                paymentMethodId: paymentMethod.id,
            });

            const { clientSecret, latestInvoiceId } = subscriptionResponse.data;

            if (hasTrial) {
                const confirmResult = await stripe.confirmCardSetup(clientSecret, {
                    payment_method: paymentMethod.id,
                });

                if (confirmResult.error) {
                    setMessage(`Setup failed: ${confirmResult.error.message}`);
                } else {
                    setMessage("Subscription created with trial! You're all set.");
                }
            } else {
                const confirmResult = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: paymentMethod.id,
                });

                if (confirmResult.error) {
                    setMessage(`Payment failed: ${confirmResult.error.message}`);
                } else {
                    await authenticatedInstance.post("/subscription/confirm-payment", {
                        invoiceId: latestInvoiceId,
                    });
                    setMessage("Subscription created and payment confirmed!");
                }
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-4">
            <CardElement className="p-3 border border-gray-300 rounded-lg" />
            <button
                type="submit"
                disabled={!stripe || isLoading}
                className="bg-primaryServiceColor text-white py-2 px-4 rounded-lg hover:bg-secondaryServiceColor mt-4 w-full"
            >
                {isLoading ? "Processing..." : "Submit Payment"}
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
    );
};

export default StandardSubscription;
