import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';
import { useParams, useNavigate } from 'react-router-dom';
import useJwtDecoder from '../../hooks/useJwtDecoder';

const SubmissionDetailsComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [submission, setSubmission] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user } = useJwtDecoder(); // Use the custom hook to decode JWT and get user information
    const [isContainMode, setIsContainMode] = useState(false);

    useEffect(() => {
        const fetchSubmissionDetails = async () => {
            try {
                const response = await authenticatedInstance.get(`/admin/${id}`);
                setSubmission(response.data);
            } catch (error) {
                console.error('Error fetching submission details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubmissionDetails();
    }, [id]);

    const handleChatClick = async () => {
        try {
            if (user) {
                navigate('/chat', {
                    state: {
                        userId: user.userId,
                        sellerId: submission.sellerProfile.user.id,
                        sellerFullName: `${submission.sellerProfile.firstName} ${submission.sellerProfile.lastName}`
                    }
                });
            }
        } catch (error) {
            console.error('Error initiating chat:', error);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p className="text-lg font-semibold text-darkBrown">Loading...</p>
            </div>
        );
    }
    const toggleImageMode = () => {
        setIsContainMode((prevMode) => !prevMode);
    };
    return (
        <div className="submission-details max-w-5xl mx-auto py-10 px-6 bg-bgGrayOrange min-h-screen">
            <h1 className="text-3xl font-bold text-darkBrown font-raleway mb-8 text-center">Submission Details</h1>

            {/* Shop Details Card */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-8 border border-lightBrown">
                <h2 className="text-2xl font-bold text-darkBrown font-poppins mb-4">Shop Information</h2>
                <p className="text-darkBrown mb-2"><strong>Shop Name:</strong> {submission.shop.name}</p>
                <p className="text-darkBrown mb-2"><strong>Shop Description:</strong> {submission.shop.description}</p>
                <p className="text-darkBrown mb-2"><strong>Shop ZIP Code:</strong> {submission.shop.zipCode}</p>
                <p className="text-darkBrown"><strong>Status:</strong> {submission.status}</p>

                {/* Shop Image */}
                {submission.shop.profilePictureFile?.id && (
                    <div className="shop-image mb-4">
                        <img
                            src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${submission.shop.profilePictureFile.id}`}
                            alt={`Shop ${submission.shop.name}`}
                            className="w-32 h-32 object-cover rounded-lg border border-lightBrown shadow-md"
                        />
                    </div>
                )}
            </div>

            {/* Seller Profile Card */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-8 border border-lightBrown">
                <h2 className="text-2xl font-bold text-darkBrown font-poppins mb-4">Seller Profile</h2>
                <p className="text-darkBrown mb-2"><strong>Seller Name:</strong> {submission.sellerProfile.firstName} {submission.sellerProfile.lastName}</p>
                <p className="text-darkBrown mb-2"><strong>Seller Bio:</strong> {submission.sellerProfile.bio}</p>

                {/* Chat Button (only visible if user is logged in and not chatting with themselves) */}
                {user && user.userId !== submission.sellerProfile.user.id && (
                    <button
                        onClick={handleChatClick}
                        className="text-primaryGreen hover:underline ml-1 font-raleway"
                    >
                        Chat with {submission.sellerProfile.firstName} {submission.sellerProfile.lastName}
                    </button>
                )}

                {submission.sellerProfile.profilePictureFile && (
                    <div className="profile-picture mb-4">
                        <img
                            src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${submission.sellerProfile.profilePictureFile.id}`}
                            alt={`Seller ${submission.sellerProfile.firstName} ${submission.sellerProfile.lastName}`}
                            className="w-32 h-32 object-cover rounded-full border border-lightBrown shadow-md"
                        />
                    </div>
                )}


                {submission.sellerProfile.contactInformation && (
                    <div className="contact-information mb-4">
                        <h3 className="text-lg font-semibold text-darkBrown mb-2">Contact Information:</h3>
                        <p className="text-darkBrown mb-1"><strong>Mobile Phone:</strong> {submission.sellerProfile.contactInformation.mobilePhone}</p>
                        <p className="text-darkBrown"><strong>Email:</strong> {submission.sellerProfile.contactInformation.email}</p>
                    </div>
                )}

                {submission.sellerProfile.socialMediaLinks && submission.sellerProfile.socialMediaLinks.length > 0 && (
                    <div className="social-media-links mb-4">
                        <h3 className="text-lg font-semibold text-darkBrown mb-2">Social Media Links:</h3>
                        <ul className="list-disc list-inside text-darkBrown">
                            {submission.sellerProfile.socialMediaLinks.map((link, index) => (
                                <li key={index}>
                                    <strong>{link.platform}:</strong>{' '}
                                    <a
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primaryGreen hover:underline"
                                    >
                                        {link.url}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            {/* Products Card */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-8 border border-lightBrown">
                <h2 className="text-2xl font-bold text-darkBrown font-poppins mb-4">Products</h2>
                <ul className="space-y-6">
                    {submission.shop.products.map((productItem, index) => (
                        <li key={index} className="product-item">
                            <div className="bg-bgGrayOrange p-4 rounded-md shadow-sm">
                                <p className="text-lg font-bold text-darkBrown mb-2">{productItem.title}</p>
                                <p className="text-darkBrown mb-2">
                                    <strong>Description:</strong> {productItem.description}</p>
                                <p className="text-lg font-bold text-darkBrown mb-2">Price: {productItem.price}$</p>
                                <button
                                    onClick={toggleImageMode}
                                    className="mb-4 px-3 py-1 bg-lightBrown text-white rounded-md"
                                >
                                    Toggle View
                                </button>
                                <div className="product-images grid grid-cols-2 gap-4">
                                    {productItem.imageFiles && productItem.imageFiles.map((imageFile, imgIndex) => (
                                        <img
                                            key={imgIndex}
                                            src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${imageFile.id}`}
                                            alt={`Product ${productItem.id} Image ${imgIndex + 1}`}
                                            className={`w-full h-40 ${isContainMode ? 'object-contain' : 'object-cover'} rounded-md shadow-sm border border-lightBrown`}
                                        />
                                    ))}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Category Filters Card */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-8 border border-lightBrown">
                <h2 className="text-2xl font-bold text-darkBrown font-poppins mb-4">Category Filters</h2>
                <ul className="list-disc list-inside text-darkBrown">
                    {submission.shop.categoryFilters.map((category) => (
                        <li key={category.id}>{category.name}</li>
                    ))}
                </ul>
            </div>

            {/* Category Holidays Card */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-8 border border-lightBrown">
                <h2 className="text-2xl font-bold text-darkBrown font-poppins mb-4">Category Holidays</h2>
                <ul className="list-disc list-inside text-darkBrown">
                    {submission.shop.categoryHolidays.map((category) => (
                        <li key={category.id}>{category.name}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SubmissionDetailsComponent;
