// RegistrationSuccessPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const RegistrationSuccessPage = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-tertiaryProductColor text-darkBrown font-nunito p-6">
            <h1 className="text-3xl font-bold mb-4 text-primaryProductColor text-center">
                Thank You for Registering as a Seller!
            </h1>
            <p className="text-lg text-center mb-6 max-w-2xl">
                We are thrilled to welcome you to TheHomeMadeShop.com community!
                Your registration was successful, and your shop is now pending approval.
            </p>
            <p className="text-lg text-center mb-8 max-w-2xl">
                Once your shop is approved, you’ll receive a notification, and you’ll be able to subscribe to one of our plans
                to make your shop visible to customers and enjoy all the benefits of being a seller.
            </p>
            <button
                onClick={() => navigate('/')}
                className="px-6 py-3 bg-primaryServiceColor text-white rounded-full hover:bg-secondaryServiceColor transition duration-300"
            >
                Go to Home
            </button>
        </div>
    );
};

export default RegistrationSuccessPage;
