import React, { useState } from "react";
import authenticatedInstance from "../custom-axios/axios-auth";

const FeatureAccess = () => {
    const [message, setMessage] = useState("");

    // Function to check access for a standard feature
    const checkStandardFeatureAccess = async () => {
        try {
            const response = await authenticatedInstance.get(`/features/standard-feature`);
            setMessage(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setMessage("Access denied: You need Standard access.");
            } else {
                setMessage("Error fetching access.");
            }
        }
    };

    // Function to check access for a premium feature
    const checkPremiumFeatureAccess = async () => {
        try {
            const response = await authenticatedInstance.get(`/features/premium-feature`);
            setMessage(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setMessage("Access denied: You need Premium access.");
            } else {
                setMessage("Error fetching access.");
            }
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
                <h1 className="text-2xl font-bold text-center mb-6">Check Feature Access</h1>
                <div className="flex flex-col space-y-3">
                    <button
                        onClick={checkStandardFeatureAccess}
                        className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Check Standard Feature Access
                    </button>
                    <button
                        onClick={checkPremiumFeatureAccess}
                        className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                    >
                        Check Premium Feature Access
                    </button>
                </div>
                <p className="mt-6 text-center text-gray-700">{message}</p>
            </div>
        </div>
    );
};

export default FeatureAccess;
