// src/components/GoogleLogin/GoogleLoginButton.js
import React from 'react';
import axios from '../../custom-axios/axios';
import { GoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = ({ onLoginSuccess }) => {
    const handleSuccess = async (credentialResponse) => {
        const tokenId = credentialResponse.credential;
        try {
            const backendResponse = await axios.post('/auth/google/authenticate', { tokenId });
            const token = backendResponse.data.jwtToken;
            localStorage.setItem('JWT', token);
            onLoginSuccess(token); // Notify parent component of successful login
        } catch (error) {
            console.error('Failed to authenticate with Google:', error);
        }
    };

    const handleError = () => {
    };

    return (
        <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
        />
    );
};

export default GoogleLoginButton;
