import React, { createContext, useState, useContext } from 'react';

// Create a context
const SearchContext = createContext();

// Create a custom hook to use the SearchContext
export const useSearchContext = () => {
    return useContext(SearchContext);
};

// Provider component to wrap around the app
export const SearchProvider = ({ children }) => {
    const [selectedType, setSelectedType] = useState('product');  // Default to 'product'
    const [zipCode, setZipCode] = useState('');  // Default ZIP code

    return (
        <SearchContext.Provider value={{ selectedType, setSelectedType, zipCode, setZipCode }}>
            {children}
        </SearchContext.Provider>
    );
};
