import React from 'react';

const ShopCard = ({ shop, handleShopClick, BASE_IMAGE_URL }) => {
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <svg key={i} className={`w-4 h-4 ${i <= rating ? 'text-yellow-300' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
            );
        }
        return stars;
    };

    return (
        <div
            className="bg-white border border-lightBrown rounded-lg shadow-lg p-2 sm:p-4 cursor-pointer hover:shadow-xl transition transform hover:-translate-y-1 duration-300"
            onClick={() => handleShopClick(shop)}
            style={{ minHeight: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
            <div>
                <h2 className="text-lg sm:text-xl font-semibold text-darkBrown mb-1 sm:mb-2 line-clamp-1">{shop.name}</h2>
                <img
                    src={
                        shop.profilePictureFile?.id
                            ? `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${shop.profilePictureFile.id}`
                            : 'https://img.freepik.com/free-photo/vibrant-colors-nature-close-up-wet-purple-daisy-generated-by-artificial-intellingence_25030-63819.jpg?size=626&ext=jpg&ga=GA1.1.2113030492.1719878400&semt=ais_user'
                    }
                    alt={shop.name}
                    className="w-full h-32 sm:h-48 object-cover rounded-lg mb-2 sm:mb-4"
                />
                <p className="text-gray-700 text-xs sm:text-sm line-clamp-3 mb-1">
                    {shop.description.length > 80 ? `${shop.description.substring(0, 80)}...` : shop.description}
                </p>
            </div>

            <div className="flex items-center mt-1 sm:mt-2 w-full"> {/* Reduced mt from 2 to 1 */}
                <div className="flex items-center space-x-1">
                    {renderStars(shop.averageRating)}
                    <p className="text-xs sm:text-sm font-medium text-gray-500 dark:text-gray-400">
                        {shop.averageRating.toFixed(1)} out of {shop.reviews.length} reviews
                    </p>
                </div>
            </div>

            <div className="flex items-center mt-1 sm:mt-2"> {/* Reduced mt from 2 to 1 */}
                {shop.sellerInfo?.imageLink ? (
                    <img
                        src={shop.sellerInfo.imageLink.startsWith('http')
                            ? shop.sellerInfo.imageLink
                            : `${BASE_IMAGE_URL}${shop.sellerInfo.imageLink}`}
                        alt={shop.sellerInfo.fullName}
                        className="w-6 h-6 sm:w-8 sm:h-8 rounded-full mr-1 sm:mr-2"
                    />
                ) : (
                    <div className="w-6 h-6 sm:w-8 sm:h-8 bg-gray-300 rounded-full mr-1 sm:mr-2"></div>
                )}
                <p className="text-xs sm:text-sm text-darkBrown">{shop.sellerInfo?.fullName}</p>
            </div>
        </div>
    );

};

export default ShopCard;
