import React, { useContext } from 'react';
import BuyerInfo from './BuyerInfo';
import SellerInfo from './SellerInfo';
import ChatButton from "../../../Chat/button/ChatButton";
import { UserContext } from '../../../../contexts/UserContext';

const ProfileInfo = ({ profileInfo, isSeller }) => {
    const { user } = useContext(UserContext);

    return (
        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
            {isSeller ? (
                <>
                    <BuyerInfo buyer={profileInfo} />
                    <ChatButton sellerProfile={profileInfo} userId={user.userId} />
                </>
            ) : (
                <>
                    <SellerInfo seller={profileInfo} />
                    <ChatButton sellerProfile={profileInfo} userId={user.userId} />
                </>
            )}
        </div>
    );
};

export default ProfileInfo;
