import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const useInitiateChat = () => {
    const navigate = useNavigate();

    const initiateChat = (sellerProfile) => {
        try {
            const token = localStorage.getItem('JWT');
            if (token) {
                const decoded = jwtDecode(token);
                const userId = String(decoded.userId);
                navigate('/chat', {
                    state: {
                        userId: userId,
                        sellerId: sellerProfile.user.id,
                        sellerFullName: `${sellerProfile.firstName} ${sellerProfile.lastName}`
                    }
                });
            }
        } catch (error) {
            console.error('Error initiating chat:', error);
        }
    };

    return initiateChat;
};

export default useInitiateChat;
