import React, {useState} from "react";
import axios from "../../custom-axios/axios";
import {useNavigate} from "react-router-dom";
import modalImage from "../../modalslika.png"
const HolidayModal = ({ isOpen, onClose,categories }) => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    if (!isOpen) return null; // Don't render the modal if it's not open
    const handleClick = async () => {
        try {
            const categoryNames = categories.map(category => category.name).join(',');

            // Send the names as a query parameter in the GET request
            const response = await axios.get(`/home/search/holidays?holiday=${categoryNames}`);
            if(response.status === 200) {
                setError(null);  // Clear any errors if request succeeds
                navigate(`/results`, {state: {shops: response.data, type: 'product'}});
            }
        } catch (err) {
            setError('No shops found for this ZIP code.');
        }
    };
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div
                className="p-6 rounded-lg shadow-lg max-w-sm w-full"
                style={{
                    backgroundImage: `url(${modalImage})`, // Use the imported image
                    backgroundSize: 'cover', // Ensures the image covers the whole background
                    backgroundPosition: 'center', // Centers the image within the modal
                    color: 'white', // Optional: Make text white for better contrast against the background image
                }}
            ><h2 className="text-xl text-black font-bold text-center mb-4">Have you prepared for the next holiday?</h2>  {/* List categories dynamically */}
                <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">Upcoming holidays:</h3>
                    <ul className="list-disc pl-5">
                        {categories && categories.length > 0 ? (
                            categories.map((category, index) => (
                                <li key={index} className="text-sm text-primaryServiceColor">
                                    {category.name}
                                </li>
                            ))
                        ) : (
                            <li className="text-sm text-primaryServiceColor">No categories available</li>
                        )}
                    </ul>
                </div>

                {/* Close Button */}
                <div className="flex justify-center">
                    <button
                        onClick={onClose}
                        className="bg-white text-black px-6 py-2 rounded-lg hover:bg-primaryServiceColor transition-colors me-2"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleClick}
                        className="bg-primaryProductColor text-white px-6 py-2 rounded-lg hover:bg-primaryServiceColor transition-colors ms-2"
                    >
                        See offers
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HolidayModal;
