import React, { useState, useEffect, useRef } from 'react';
import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { jwtDecode } from 'jwt-decode';
import authenticatedInstance from "../../custom-axios/axios-auth";
import Avatar from "@mui/material/Avatar";

const ConversationsPage = ({ onSelectConversation }) => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversationId, setSelectedConversationId] = useState(null); // Track selected conversation
    const stompClientRef = useRef(null);
    const userIdRef = useRef(null);
    const BASE_FILE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/files/download`;

    useEffect(() => {
        const token = localStorage.getItem('JWT');
        if (token) {
            const decoded = jwtDecode(token);
            userIdRef.current = String(decoded.userId);

            fetchConversations();
            setupWebSocket();
        }

        return () => {
            if (stompClientRef.current) {
                stompClientRef.current.disconnect();
            }
        };
    }, []);

    const fetchConversations = () => {
        authenticatedInstance.get(`/conversations/${userIdRef.current}`)
            .then(response => {
                const conversations = response.data.map(convo => ({
                    chatId: convo.chatId,
                    userId: String(convo.userId),
                    chatInformation: convo.chatInformation,  // Use chatInformation DTO
                    hasUnseenMessages: convo.hasUnseenMessages
                }));



                setConversations(conversations);
            })
            .catch(error => {
                console.error('Error fetching conversations:', error);
            });
    };

    const setupWebSocket = () => {
        const socket = new SockJS(`${process.env.REACT_APP_BACKEND_URL}/ws`);
        // const socket = new SockJS(`http://ws.thehomemadeshop.com/ws`);
        const client = Stomp.over(socket);
        stompClientRef.current = client;

        client.connect({}, () => {
            client.subscribe(`/user/${userIdRef.current}/queue/messages`, message => {
                const msg = JSON.parse(message.body);
                fetchConversations();
            });

            client.subscribe(`/user/${userIdRef.current}/queue/seen`, notification => {
                fetchConversations();
            });

            // Subscribe to additional topics including user ID
            client.subscribe(`/topic/chat/extra/${userIdRef.current}`, message => {
                fetchConversations();
            });
        }, error => {
            console.error('WebSocket connection error:', error);
        });
    };

    const handleConversationClick = (convo) => {
        setSelectedConversationId(convo.chatId); // Set selected conversation
        onSelectConversation(convo); // Trigger the onSelectConversation callback
    };

    return (
        <div className="conversations-page bg-white min-h-screen p-5">
            <h1 className="text-2xl font-semibold mb-4">Conversations</h1>

            <div className="conversations-list space-y-4">
                {conversations.map(convo => (
                    <div
                        key={convo.chatId}
                        className={`conversation-card flex items-center p-4 rounded-lg shadow-md cursor-pointer transition-all duration-300 ${
                            selectedConversationId === convo.chatId
                                ? 'bg-blue-400 text-white'
                                : 'bg-white hover:bg-gray-100'
                        }`}
                        onClick={() => handleConversationClick(convo)}
                    >
                        {/* User's Profile Image */}
                        {/*<img*/}
                        {/*    src={`${BASE_FILE_URL}/${convo.chatInformation.imageLink || '/path/to/default-profile-image.jpg'}`}*/}
                        {/*    alt={`${convo.chatInformation.fullName}'s profile`}*/}
                        {/*    className="w-12 h-12 rounded-full mr-4"*/}
                        {/*/>*/}
                        {convo?.chatInformation?.imageLink ? (
                            <Avatar className="w-12 h-12 rounded-full mr-4" alt={convo?.chatInformation?.fullName[0]} src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${convo.chatInformation.imageLink}`}/>
                        ) : (
                            <Avatar className="w-12 h-12 rounded-full mr-4" >{convo?.chatInformation?.fullName[0]}</Avatar>
                        )}

                        {/* User's Name */}
                        <div className="flex-1">
                            <div className="text-lg font-semibold">
                                {convo.chatInformation.fullName}
                            </div>
                            <span className="text-gray-500 text-sm">
                                {convo.hasUnseenMessages ? "New messages available" : ""}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConversationsPage;
