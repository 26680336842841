import React from 'react';
import dayjs from 'dayjs';
import getOrderStatusClasses from "./getOrderStatusClasses";
import { useOrderSorting } from "../../../contexts/OrderSortingContext";
import OrderSortingControls from './OrderSortingControls';

const CalendarDayModal = ({ selectedDayOrders, closeModal, handleOrderClick }) => {
    const { sortOrders } = useOrderSorting();

    if (!selectedDayOrders) return null;

    const sortedOrders = sortOrders(selectedDayOrders.orders);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="bg-white p-4 sm:p-6 rounded-lg w-full max-w-lg sm:max-w-lg md:max-w-xl lg:max-w-2xl">
                <h3 className="text-lg sm:text-xl md:text-2xl font-bold mb-4">
                    Orders for {dayjs(selectedDayOrders.date).format('MMMM DD, YYYY')}
                </h3>
                <OrderSortingControls allTitles={selectedDayOrders.orders.map(order => order.productTitle)} />
                <ul className="space-y-2">
                    {sortedOrders.map((order) => (
                        <li
                            key={order.id}
                            onClick={() => handleOrderClick(order.id)}
                            className={`text-sm md:text-base lg:text-base 
                                ${getOrderStatusClasses(order.status)} 
                                rounded px-3 py-2 cursor-pointer flex justify-between items-center`}
                        >
                            <div className="flex-grow">
                                <div>
                                    Order ID: {order.id}, Product: {order.productTitle}
                                </div>
                                {order.quantity > 0 && <div>Quantity: {order.quantity}</div>}
                                <div>Delivery Date: {dayjs(order.dateDelivery).format('MMMM DD, YYYY h:mm A')}</div>
                            </div>
                            <div className="ml-4 text-right">
                                <span className="font-semibold">{order.status}</span>
                            </div>
                        </li>
                    ))}
                </ul>
                <button
                    onClick={closeModal}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default CalendarDayModal;
