import React, { useState, useContext } from 'react';
import axios from '../../custom-axios/axios';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLoginButton from "../FacebookLogin/FacebookLogin";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import './LoginComponent.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const { login } = useContext(UserContext); // Use login function from UserContext
    const navigate = useNavigate();
    const location = useLocation();

    const [passwordVisible, setPasswordVisible] = useState(false);

    // Get the "from" location from state or default to home if not available
    const from = location.state?.from?.pathname || '/';

    const handleLogin = async () => {
        try {
            const response = await axios.post('/auth/customer/login', { email, password });
            const token = response.data.jwtToken;
            login(token); // Call login function from UserContext
            setError(null);
            setSuccess("Login successful");
            navigate(from, { replace: true });
        } catch (err) {
            console.error(err);
            setError('Invalid email or password.');
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        const tokenId = credentialResponse.credential;
        try {
            const response = await axios.post('/auth/google/authenticate', { tokenId });
            const token = response.data.jwtToken;
            login(token); // Call login function from UserContext
            setError(null);
            setSuccess("Login successful with Google");
            navigate(from, { replace: true });
        } catch (error) {
            console.error('Failed to login with Google:', error);
            setError('Google login failed.');
        }
    };

    const handleGoogleError = () => {
        setError('Google login failed.');
    };

    const handleFacebookSuccess = async (token) => {
        try {
            // Assuming token is received as a valid Facebook token from the FacebookLoginButton
            const response = await axios.post('/auth/facebook/authenticate', { accessToken: token });
            const jwtToken = response.data.jwtToken;

            login(jwtToken); // Call login function from UserContext
            setError(null);
            setSuccess("Login successful with Facebook");

            navigate(from, { replace: true }); // Redirect to the previous page or home
        } catch (error) {
            console.error('Failed to login with Facebook:', error);
            setError('Facebook login failed.');
        }
    };

    return (
        <div className="bg-bgGrayOrange min-h-screen flex items-center justify-center font-poppins p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h1 className="text-3xl font-bold text-darkBrown mb-6 text-center">Welcome Back!</h1>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-darkBrown mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                        onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                    />
                </div>

                <div className="mb-6 relative">
                    <label className="block text-sm font-medium text-darkBrown mb-2" htmlFor="password">
                        Password
                    </label>
                    <input
                        type={passwordVisible ? 'text' : 'password'}  // Toggle between 'password' and 'text'
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                        onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                    />
                    <button
                        type="button"
                        className="absolute right-3 top-9 text-gray-500"
                        onClick={() => setPasswordVisible(!passwordVisible)}  // Toggle visibility
                    >
                        {passwordVisible ? <FaEyeSlash/> : <FaEye/>}
                    </button>
                </div>


                <button
                    onClick={handleLogin}
                    className="custom-btn-primary w-full">
                    Log In
                </button>

                {/*<div className="flex items-center justify-center my-6">*/}
                {/*    <span className="text-sm text-gray-500">or continue with</span>*/}
                {/*</div>*/}

                {/*/!* Social Login Buttons *!/*/}
                {/*<div className="flex space-x-4 justify-center">*/}
                {/*    <GoogleLogin*/}
                {/*        onSuccess={handleGoogleSuccess}*/}
                {/*        onError={handleGoogleError}*/}
                {/*        render={({onClick}) => (*/}
                {/*            <button*/}
                {/*                onClick={onClick}*/}
                {/*                className="flex items-center justify-center w-full p-3 text-sm text-darkBrown border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300"*/}
                {/*            >*/}
                {/*                <FaGoogle className="mr-2 text-lg"/> Google*/}
                {/*            </button>*/}
                {/*        )}*/}
                {/*    />*/}
                {/*    <button*/}
                {/*        onClick={handleFacebookSuccess}*/}
                {/*        className="flex items-center justify-center w-full p-3 text-sm text-darkBrown border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300"*/}
                {/*    >*/}
                {/*        <FaFacebook className="mr-2 text-lg text-blue-600"/> Facebook*/}
                {/*    </button>*/}
                {/*</div>*/}

                {error && (
                    <p className="text-red-600 text-sm mt-4 text-center">{error}</p>
                )}
                {success && (
                    <p className="text-green-600 text-sm mt-4 text-center">{success}</p>
                )}

                {/* Register Prompt */}
                <div className="mt-6 text-center">
                    <span className="text-sm text-gray-500">Don't have an account? </span>
                    <Link
                        to="/register"
                        className="text-sm text-primaryGreen font-medium hover:underline"
                    >
                        Register here
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;
