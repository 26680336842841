import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OrderFilter = ({ selectedDate, selectedStatus, handleDateChange, handleStatusChange }) => {
    return (
        <div className="order-filter bg-bgGrayOrange p-6 rounded-lg shadow-md border border-lightBrown mb-8 font-poppins">
            {/* Date Picker */}
            <div className="mb-6">
                <label htmlFor="order-date-picker" className="block text-darkBrown font-bold mb-2">Select Delivery Date:</label>
                <DatePicker
                    id="order-date-picker"
                    selected={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="Select a date"
                    isClearable
                    className="w-full p-3 border border-lightBrown rounded-lg focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                />
            </div>

            {/* Status Dropdown */}
            <div className="mb-6">
                <label htmlFor="status-filter" className="block text-darkBrown font-bold mb-2">Filter by Status:</label>
                <select
                    id="status-filter"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    className="w-full p-3 border border-lightBrown rounded-lg bg-white text-darkBrown focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                >
                    <option value="All">All</option>
                    <option value="ACTIVE">Active</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="CANCELLED">Cancelled</option>
                    <option value="EXPIRED">Expired</option>
                    <option value="DELIVERED">Delivered</option>
                    <option value="AWAITING_APPROVAL">Awaiting Approval</option>
                    <option value="APPROVED">Approved</option>
                </select>
            </div>

            {/* Apply Filters Button */}
            <div className="flex justify-end">
                <button
                    className="custom-btn-primary"
                    onClick={() => handleDateChange(selectedDate)} // Example action - update to the required action
                >
                    Apply Filters
                </button>
            </div>
        </div>
    );
};

export default OrderFilter;
