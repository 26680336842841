import React from 'react';

const BuyerInfo = ({ buyer }) => (
    <div>
        <h3 className="text-lg font-semibold mt-4 text-gray-800">Buyer Information</h3>
        <div className="mb-2">
            <strong className="text-gray-700">Name:</strong> {buyer.firstName} {buyer.lastName}
        </div>
    </div>
);

export default BuyerInfo;
