import React, { useState, useRef, useEffect } from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';
import './ShopDetails.css';
import CategoryList from "./CategoryList";

const ShopInfo = ({ sellerProfile, userId, fetchShopDetails }) => {
    const { shop } = sellerProfile;
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [newName, setNewName] = useState(shop.name);
    const [newDescription, setNewDescription] = useState(shop.description);
    const [originalName, setOriginalName] = useState(shop.name);
    const [originalDescription, setOriginalDescription] = useState(shop.description);
    const [profilePicture, setProfilePicture] = useState(
        shop?.profilePictureFile?.id
            ? `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${shop.profilePictureFile.id}`
            : 'https://via.placeholder.com/150'
    );



    const [isModalOpen, setIsModalOpen] = useState(false); // Categories Modal State
    const [showFullDescription, setShowFullDescription] = useState(false); // Show More/Show Less State


    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const fileInputRef = useRef(null);
    useEffect(() => {
    }, [shop]);

    const handleNameChange = (e) => {
        setNewName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setNewDescription(e.target.value);
    };

    const saveChanges = async (field, value) => {
        try {
            const updatedField = { value };
            const response = await authenticatedInstance.put(`/shops/${shop.id}/${field}`, updatedField);
            if (response.status === 200) {
                if (field === 'name') {
                    setIsEditingName(false);
                    setOriginalName(value);
                    shop.name = value;
                } else if (field === 'description') {
                    setIsEditingDescription(false);
                    setOriginalDescription(value);
                    shop.description = value;
                }
            } else {
                throw new Error(`Failed to update ${field}`);
            }
        } catch (error) {
            console.error(`Error saving ${field}:`, error);
        }
    };

    const cancelEdit = (field) => {
        if (field === 'name') {
            setNewName(originalName);
            setIsEditingName(false);
        } else if (field === 'description') {
            setNewDescription(originalDescription);
            setIsEditingDescription(false);
        }
    };

    const handleOutsideClick = (e) => {
        if (nameRef.current && !nameRef.current.contains(e.target)) {
            if (isEditingName && newName !== originalName) {
                if (window.confirm("Do you want to save the changes to the name?")) {
                    saveChanges('name', newName);
                } else {
                    cancelEdit('name');
                }
            } else {
                setIsEditingName(false);
            }
        }

        if (descriptionRef.current && !descriptionRef.current.contains(e.target)) {
            if (isEditingDescription && newDescription !== originalDescription) {
                if (window.confirm("Do you want to save the changes to the description?")) {
                    saveChanges('description', newDescription);
                } else {
                    cancelEdit('description');
                }
            } else {
                setIsEditingDescription(false);
            }
        }
    };

    const handleProfilePictureClick = () => {
        if (isSeller) {
            fileInputRef.current.click(); // Trigger file input click for the seller
        }
    };

    const handleProfilePictureChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                const response = await authenticatedInstance.put(`/shops/${shop.id}/profile-picture`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                if (response.status === 200) {
                    const updatedFile = response.data;
                    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${updatedFile.id}`;

                    setProfilePicture(imageUrl); // Update profile picture locally
                } else {
                    throw new Error('Failed to update profile picture');
                }
            } catch (error) {
                console.error('Error updating profile picture:', error);
            }
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isEditingName, isEditingDescription, newName, newDescription, originalName, originalDescription]);

    const isSeller = sellerProfile.user && userId === sellerProfile.user.id;

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(
                    <svg key={i} className="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                    </svg>
                );
            } else {
                stars.push(
                    <svg key={i} className="w-4 h-4 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                    </svg>
                );
            }
        }
        return stars;
    };

    return (
        <div className="shop-details flex flex-col items-center space-y-6 p-6 rounded-lg font-poppins">
            {/* Shop Profile Picture */}
            <div className="relative">
                <img
                    src={profilePicture || 'https://via.placeholder.com/150'}
                    alt="Shop Profile"
                    className="details-image w-40 h-40 rounded-full object-cover border-4 border-lightBrown shadow-md"
                    onClick={handleProfilePictureClick}
                />
                {userId === sellerProfile.user.id && (
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{display: 'none'}}
                        onChange={handleProfilePictureChange}
                        accept="image/*"
                    />
                )}
            </div>

            {/* Shop Name */}
            <div ref={nameRef} className="text-center">
                {userId === sellerProfile.user.id ? (
                    isEditingName ? (
                        <input
                            type="text"
                            value={newName}
                            onChange={handleNameChange}
                            onBlur={() => setIsEditingName(false)}
                            autoFocus
                            className="border-2 border-gray-300 rounded-lg p-2 w-full font-quicksand"
                        />
                    ) : (
                        <h1
                            onClick={() => setIsEditingName(true)}
                            className="editable text-3xl font-bold text-darkBrown hover:text-lightBrown cursor-pointer font-montserrat"
                        >
                            {shop.name}
                        </h1>
                    )
                ) : (
                    <h1 className="text-3xl font-bold text-darkBrown font-montserrat">{shop.name}</h1>
                )}
            </div>

            {/* Rating Section */}
            <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center items-center text-xs text-gray-500 mt-2 w-full px-8 space-y-2 sm:space-y-0 sm:space-x-2 font-raleway">
                <div className="flex items-center">
                    {renderStars(shop.averageRating)}
                    <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                        {shop.averageRating.toFixed(1)} out of 5.00
                    </p>
                </div>
                <div className="flex items-center space-x-2">
                    <a href="#reviews" className="text-sm font-medium text-blue-600 hover:underline">
                        {shop.reviews.length} reviews
                    </a>

                    {/* Separator */}
                    <span className="hidden sm:inline-block mx-2">|</span>

                    {/* Categories Button */}
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="text-sm text-primaryGreen hover:underline"
                    >
                        Categories
                    </button>
                </div>
            </div>

            {/* Shop Description with 'Show more'/'Show less' functionality */}
            <div ref={descriptionRef} className="text-left mt-4 w-full px-8 font-nunito">
                {userId === sellerProfile.user.id ? (
                    isEditingDescription ? (
                        <textarea
                            value={newDescription}
                            onChange={handleDescriptionChange}
                            onBlur={() => setIsEditingDescription(false)}
                            autoFocus
                            className="border-2 border-gray-300 rounded-lg p-2 w-full h-24"
                        />
                    ) : (
                        <p
                            onClick={() => setIsEditingDescription(true)}
                            className="text-gray-700 hover:text-lightBrown cursor-pointer"
                        >
                            {newDescription.length > 100 && !showFullDescription ? (
                                <>
                                    {newDescription.slice(0, 100)}...{' '}
                                    <span
                                        className="text-blue-500 cursor-pointer"
                                        onClick={() => setShowFullDescription(true)}
                                    >
                                    Show more
                                </span>
                                </>
                            ) : showFullDescription ? (
                                <>
                                    {newDescription}{' '}
                                    <span
                                        className="text-blue-500 cursor-pointer"
                                        onClick={() => setShowFullDescription(false)}
                                    >
                                    Show less
                                </span>
                                </>
                            ) : (
                                newDescription
                            )}
                        </p>
                    )
                ) : (
                    <p className="text-gray-700">
                        {shop.description && shop.description.length > 100 && !showFullDescription ? (
                            <>
                                {shop.description.slice(0, 100)}...{' '}
                                <span
                                    className="text-blue-500 cursor-pointer"
                                    onClick={() => setShowFullDescription(true)}
                                >
                                Show more
                            </span>
                            </>
                        ) : showFullDescription ? (
                            <>
                                {shop.description}{' '}
                                <span
                                    className="text-blue-500 cursor-pointer"
                                    onClick={() => setShowFullDescription(false)}
                                >
                                Show less
                            </span>
                            </>
                        ) : (
                            shop.description || 'No description provided'
                        )}
                    </p>
                )}
            </div>

            {/* Categories Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative font-lora">
                        <h2 className="text-2xl font-bold mb-4 text-center">Categories</h2>

                        {/* CategoryList Content */}
                        <CategoryList
                            filters={shop.categoryFilters}
                            services={shop.categoryService}
                            holidays={shop.categoryHolidays}
                            shopId={shop.id}
                            fetchShopDetails={fetchShopDetails}
                            userId={userId}
                            isSeller={userId === sellerProfile.user.id}
                            sellerProfile={sellerProfile}
                        />

                        {/* Close Modal Button */}
                        <button
                            className="mt-4 px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 w-full text-center"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );



};

export default ShopInfo;
