import React from 'react';

const OrderCards = ({ orders, openStatusModal }) => {
    // Function to render formatted message content with links
    const renderMessageContent = (content) => {
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = linkRegex.exec(content)) !== null) {
            const [fullMatch, text, url] = match;
            const start = match.index;

            // Push preceding text
            if (start > lastIndex) {
                parts.push(content.substring(lastIndex, start));
            }

            // Push link
            parts.push(
                <a
                    key={start}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                >
                    {text}
                </a>
            );

            lastIndex = linkRegex.lastIndex;
        }

        // Push remaining text
        if (lastIndex < content.length) {
            parts.push(content.substring(lastIndex));
        }

        return parts;
    };

    return (
        <div className="orders-grid grid gap-6 p-6 bg-bgGrayOrange">
            {orders.map(order => (
                <div key={order.id} className="order-card bg-white p-6 rounded-lg shadow-md border border-lightBrown font-poppins">
                    <div className="mb-4 flex justify-between items-center">
                        <h2 className="text-lg font-bold text-darkBrown">
                            <a href={`${process.env.REACT_APP_FRONTEND_URL}/orders/${order.id}`}
                               className="hover:underline">
                                Order #{order.id}
                            </a>
                        </h2>
                        {/* Button logic based on order status */}
                        <button
                            onClick={() => openStatusModal(order)}
                            disabled={order.status && order.status.status !== 'AWAITING_APPROVAL'}
                            className={`custom-btn-outline ${
                                order.status && order.status.status !== 'AWAITING_APPROVAL' ? 'opacity-60 cursor-not-allowed' : ''
                            }`}
                        >
                            {order.status ? order.status.status : 'N/A'}
                        </button>
                    </div>
                    <div className="order-details">
                        <p className="mb-2 text-darkBrown">
                            <span className="font-semibold">Product:</span>{' '}
                            {order.productId ? (
                                <a href={`${process.env.REACT_APP_FRONTEND_URL}/product/${order.productId}`} className="text-primaryGreen underline hover:text-green-600">
                                    {order.productTitle} (Product ID: {order.productId})
                                </a>
                            ) : 'N/A'}
                        </p>
                        <p className="mb-2 text-darkBrown">
                            <span className="font-semibold">Buyer:</span> {order.buyer ? `${order.buyer.firstName} ${order.buyer.lastName}` : 'N/A'}
                        </p>
                        {order.quantity > 0 && (
                            <p className="mb-2 text-darkBrown">
                                <span className="font-semibold">Quantity:</span> {order.quantity}
                            </p>
                        )}
                        <p className="mb-2 text-darkBrown">
                            <span className="font-semibold">Date Ordered:</span> {new Date(order.dateOrdered).toLocaleString()}
                        </p>
                        <p className="mb-2 text-darkBrown">
                            <span className="font-semibold">Date Delivery:</span> {new Date(order.dateDelivery).toLocaleString()}
                        </p>
                        {order.message && (
                            <div className="mt-4 p-4 bg-primaryGreen rounded-lg text-darkBrown">
                                <p className="font-semibold">Latest Message:</p>
                                <div className="message-content">
                                    {renderMessageContent(order.message.content)}
                                </div>
                                <p className="text-sm mt-2 text-gray-700">Sent on: {new Date(order.message.timestamp).toLocaleString()}</p>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrderCards;
