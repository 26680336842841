import React from 'react';
import { useMediaQuery } from 'react-responsive';
import HomePage from './HomePage'; // Your new HomePage for large screens
import MobileHomePage from "./MobileHomePage";
const ResponsiveHome = () => {
    // Define breakpoints for responsiveness
    const isLargeScreen = useMediaQuery({ minWidth: 1025 }); // Large screen: 1025px and up
    const isSmallScreen = useMediaQuery({ maxWidth: 1024 }); // Small screen: 1024px and below

    return (

        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

            {/* Render HomePage for large screens, and SearchComponent for small screens */}
            {isLargeScreen && <HomePage/>}
            {isSmallScreen && <MobileHomePage/>}
        </div>
    );
};

export default ResponsiveHome;
