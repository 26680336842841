// src/components/Home/imageMappings.js

export const productImageMapping = {
    'CLOTHES': 'clothes.webp',
    'JEWELLERY': 'jewlery.webp',
    'DECORATIONS': 'decorations-product.webp',
    'CAKES': 'cake.webp',
    'FLOWERS': 'flowers.webp',
    'ART': 'art.webp',
    'BEAUTY': 'beauty-product.webp',
};

export const serviceImageMapping = {
    'PARTY PLANNER': 'party-planner.webp',
    'DECORATIONS': 'decorations-service.webp',
    'MAKE UP AND HAIR': 'beauty-service.webp',
    'HOME MAINTENANCE': 'maintenance.webp',
    'PHOTOGRAPHY': 'photography.webp',
    'MUSIC / DJ': 'music-dj.webp',
};
