import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../../custom-axios/axios-auth';
import { useParams, useNavigate } from 'react-router-dom';

const SellerProfileFormComponent = () => {
    const { id } = useParams();
    const [profile, setProfile] = useState({
        user: { email: '' },
        contactInformation: { mobilePhone: '', email: '' },
        firstName: '',
        lastName: '',
        bio: '',
        socialMediaLinks: [{ platform: '', url: '' }],
        shop: {
            name: '',
            description: '',
            zipCode: '',
            products: [{ title: '', description: '', images: [{ imageFile: null, imagePath: '' }] }],
            categoryFilters: [{ id: null, name: '' }],
            categoryHolidays: [{ id: null, name: '' }],
            reviews: [{ rating: 0, comment: '', userId: null, dateTime: '', shopId: null, reviewId: null }]
        },
        profilePicture: { imageFile: null, imagePath: '', pathName: '' }
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                const response = await authenticatedInstance.get(`/admin/users/seller/${id}`);
                const profileData = response.data;

                setProfile({
                    user: { email: profileData.user?.email || '' },
                    contactInformation: {
                        mobilePhone: profileData.contactInformation?.mobilePhone || '',
                        email: profileData.contactInformation?.email || ''
                    },
                    firstName: profileData.firstName || '',
                    lastName: profileData.lastName || '',
                    bio: profileData.bio || '',
                    socialMediaLinks: profileData.socialMediaLinks.length ? profileData.socialMediaLinks : [{ platform: '', url: '' }],
                    shop: {
                        name: profileData.shop?.name || '',
                        description: profileData.shop?.description || '',
                        zipCode: profileData.shop?.zipCode || '',
                        products: profileData.shop?.products.length ? profileData.shop.products : [{ title: '', description: '', images: [{ imageFile: null, imagePath: '' }] }],
                        categoryFilters: profileData.shop?.categoryFilters.length ? profileData.shop.categoryFilters : [{ id: null, name: '' }],
                        categoryHolidays: profileData.shop?.categoryHolidays.length ? profileData.shop.categoryHolidays : [{ id: null, name: '' }],
                        reviews: profileData.shop?.reviews.length ? profileData.shop.reviews : [{ rating: 0, comment: '', userId: null, dateTime: '', shopId: null, reviewId: null }]
                    },
                    profilePicture: profileData.profilePicture || { imageFile: null, imagePath: '', pathName: '' }
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile details:', error);
                setError('Failed to load profile details.');
                setLoading(false);
            }
        };

        fetchProfileDetails();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split('.');
        if (keys.length > 1) {
            setProfile((prevProfile) => ({
                ...prevProfile,
                [keys[0]]: {
                    ...prevProfile[keys[0]],
                    [keys[1]]: value
                }
            }));
        } else {
            setProfile({
                ...profile,
                [name]: value
            });
        }
    };

    const handleSocialMediaLinkChange = (index, key, value) => {
        const updatedLinks = [...profile.socialMediaLinks];
        updatedLinks[index][key] = value;
        setProfile({ ...profile, socialMediaLinks: updatedLinks });
    };

    const handleAddSocialMediaLink = () => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            socialMediaLinks: [...prevProfile.socialMediaLinks, { platform: '', url: '' }]
        }));
    };

    const handleRemoveSocialMediaLink = (index) => {
        const updatedLinks = [...profile.socialMediaLinks];
        updatedLinks.splice(index, 1);
        setProfile({ ...profile, socialMediaLinks: updatedLinks });
    };

    const handleProductChange = (index, key, value) => {
        const updatedProducts = [...profile.shop.products];
        updatedProducts[index][key] = value;
        setProfile({ ...profile, shop: { ...profile.shop, products: updatedProducts } });
    };

    const handleAddProduct = () => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            shop: {
                ...prevProfile.shop,
                products: [...prevProfile.shop.products, { title: '', description: '', images: [{ imageFile: null, imagePath: '' }] }]
            }
        }));
    };

    const handleRemoveProduct = (index) => {
        const updatedProducts = [...profile.shop.products];
        updatedProducts.splice(index, 1);
        setProfile({ ...profile, shop: { ...profile.shop, products: updatedProducts } });
    };

    const handleReviewChange = (index, key, value) => {
        const updatedReviews = [...profile.shop.reviews];
        updatedReviews[index][key] = value;
        setProfile({ ...profile, shop: { ...profile.shop, reviews: updatedReviews } });
    };

    const handleAddReview = () => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            shop: {
                ...prevProfile.shop,
                reviews: [...prevProfile.shop.reviews, { rating: 0, comment: '', userId: null, dateTime: '', shopId: null, reviewId: null }]
            }
        }));
    };

    const handleRemoveReview = (index) => {
        const updatedReviews = [...profile.shop.reviews];
        updatedReviews.splice(index, 1);
        setProfile({ ...profile, shop: { ...profile.shop, reviews: updatedReviews } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await authenticatedInstance.put(`/admin/users/seller/${id}`, profile);
            alert('Profile updated successfully');
            navigate(`/admin/users/${id}`);
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Failed to update profile.');
        }
    };

    if (loading) {
        return <p className="loading-message">Loading...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <div className="container profile-form-container">
            <h1>Edit Seller Profile</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={profile.firstName}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={profile.lastName}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="bio">Bio</label>
                    <textarea
                        id="bio"
                        name="bio"
                        value={profile.bio}
                        onChange={handleInputChange}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="user.email"
                        value={profile.user.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="mobilePhone">Mobile Phone</label>
                    <input
                        type="text"
                        id="mobilePhone"
                        name="contactInformation.mobilePhone"
                        value={profile.contactInformation.mobilePhone}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="socialMediaLinks">Social Media Links</label>
                    {profile.socialMediaLinks.map((link, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                name={`socialMediaLinks[${index}].platform`}
                                value={link.platform}
                                onChange={(e) => handleSocialMediaLinkChange(index, 'platform', e.target.value)}
                                placeholder="Platform"
                            />
                            <input
                                type="text"
                                name={`socialMediaLinks[${index}].url`}
                                value={link.url}
                                onChange={(e) => handleSocialMediaLinkChange(index, 'url', e.target.value)}
                                placeholder="URL"
                            />
                            <button type="button" onClick={() => handleRemoveSocialMediaLink(index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddSocialMediaLink}>Add Social Media Link</button>
                </div>
                <div className="form-group">
                    <label htmlFor="products">Products</label>
                    {profile.shop.products.map((product, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                name={`products[${index}].title`}
                                value={product.title}
                                onChange={(e) => handleProductChange(index, 'title', e.target.value)}
                                placeholder="Title"
                            />
                            <input
                                type="text"
                                name={`products[${index}].description`}
                                value={product.description}
                                onChange={(e) => handleProductChange(index, 'description', e.target.value)}
                                placeholder="Description"
                            />
                            {/* Add image input fields here */}
                            <button type="button" onClick={() => handleRemoveProduct(index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddProduct}>Add Product</button>
                </div>
                <div className="form-group">
                    <label htmlFor="reviews">Reviews</label>
                    {profile.shop.reviews.map((review, index) => (
                        <div key={index}>
                            <input
                                type="number"
                                name={`reviews[${index}].rating`}
                                value={review.rating}
                                onChange={(e) => handleReviewChange(index, 'rating', e.target.value)}
                                placeholder="Rating"
                            />
                            <input
                                type="text"
                                name={`reviews[${index}].comment`}
                                value={review.comment}
                                onChange={(e) => handleReviewChange(index, 'comment', e.target.value)}
                                placeholder="Comment"
                            />
                            <button type="button" onClick={() => handleRemoveReview(index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddReview}>Add Review</button>
                </div>
                <button type="submit" className="submit-button">Save Changes</button>
            </form>
        </div>
    );
};

export default SellerProfileFormComponent;
