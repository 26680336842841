import React, { createContext, useState } from 'react';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
    const [selectedPlan, setSelectedPlan] = useState({
        plan: null,
        productType: 'PRODUCT',
        productLimit: 0,
    });

    return (
        <SubscriptionContext.Provider value={{ selectedPlan, setSelectedPlan }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
