import React from 'react';
import './ThemeStyles.css';


const MessagesComponent = ({ error, success, onClose }) => {
    if (!error && !success) {
        return null; // Don't render if there are no messages
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center relative transition-all duration-300 ease-in-out font-poppins">
                {/* Message Content */}
                {error && (
                    <div className="text-customRed font-bold mb-4 text-lg">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="text-primaryGreen font-bold mb-4 text-lg">
                        {success}
                    </div>
                )}

                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="custom-btn-primary w-full mt-4"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default MessagesComponent;

