import React, {useEffect, useState} from 'react';
import axios from '../../custom-axios/axios';
import {jwtDecode} from "jwt-decode";
import {FaEye, FaEyeSlash} from "react-icons/fa";

const EmailChange = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [email, setEmail] = useState(null);
    useEffect(() => {
        const token = localStorage.getItem('JWT');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUserId(decodedToken.userId)
            } catch (error) {
                console.error('Failed to decode token:', error);
            }
        }
    }, []);
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic regex for email validation
        return emailRegex.test(email);
    };
    const handleSubmit = async () => {

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        } else {
            setEmailError('');
        }

        setIsLoading(true); // Start loading spinner
        try {
            const token = localStorage.getItem('JWT');
            const response = await axios.post(`/auth/changeemail`,
                {
                    email: email // Send the new email in the request body
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include JWT token in the Authorization header
                    }
                });
            if (response.status === 200) {
                const newToken = response.data;  // Assuming the response body contains the new JWT token
                localStorage.setItem('JWT', newToken); // Store the new token in localStorage
                setError(null);
                setSuccess("Email changed successfully.");
            }

        } catch (err) {
            setError('Email change failed.');
        } finally {
            setIsLoading(false); // Stop loading spinner
        }
    };


    return (
        <div
            className="register-container min-h-screen flex items-center justify-center bg-bgGrayOrange p-4 font-poppins">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h1 className="text-3xl font-bold text-darkBrown mb-6 text-center">Change your email</h1>

                {isLoading ? (
                    <div className="text-center">
                        <p>Loading...</p>
                    </div>
                ) : (
                    <>
                        <div className="mb-6 relative">
                            <label className="block text-sm font-medium text-darkBrown mb-2"> New
                                Email</label>
                            <input
                                type='text' // Toggle between 'password' and 'text'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="New email"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-primaryGreen"
                                onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                            />
                            {emailError && (
                                <p className="text-red-500 text-sm mt-2">{emailError}</p>
                            )}
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="custom-btn-primary w-full"
                        >
                            Submit
                        </button>

                        {error && (
                            <p className="text-red-600 text-sm mt-4 text-center">{error}</p>
                        )}
                        {success && (
                            <p className="text-green-600 text-sm mt-4 text-center">{success}</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default EmailChange;
