import React, {useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Disclosure, Menu, Transition} from '@headlessui/react';
import {Bars3Icon, BellIcon, ChatBubbleOvalLeftEllipsisIcon} from '@heroicons/react/24/outline';
import Avatar from '@mui/material/Avatar';
import {UserContext} from '../../contexts/UserContext';
import authenticatedInstance from "../../custom-axios/axios-auth";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const NavMobileSection = () => {
    const {
        isLoggedIn,
        userInfo,
        notifications,
        verificationMessage,
        isEnabled,
        setNotifications,
        logout,
        user,
        roles,
        hasPendingSellerRequest
    } = useContext(UserContext);

    const navigate = useNavigate();
    const handleNotificationClick = (notification) => {
        navigate('/chat', {
            state: {
                userId: user?.userId,
                sellerId: notification.userId,
                sellerFullName: notification.userFullName,
            },
        });

        setNotifications((prevNotifications) =>
            prevNotifications.filter((n) => n.userId !== notification.userId)
        );
    };
    const handleShopClick = async () => {
        try {
            const response = await authenticatedInstance.get(`/home/findShopByUserId/${user?.userId}`);
            if (response.status === 200) {
                const shop = response.data;
                navigate(`/shop-details/${shop.id}`);
            }
        } catch (error) {
            console.error('Error fetching shop:', error);
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/', {replace: true});
    };

    return (
        <Disclosure as="nav"
                    className="bg-secondaryServiceColor sm:hidden w-full relative navbar-container"> {/* Hidden on larger screens */}
            <div className="relative flex items-center justify-between p-1 exs:p-4 navbar-container">
                <div className="flex items-center justify-start w-full">
                    <Link to="/" className="flex-shrink-0">
                        <h1 className="text-xl leading-none font-rageItalic text-primaryProductColor navbar-logo">
                            TheHomeMadeShop.com
                        </h1>
                    </Link>
                </div>

                {isLoggedIn && (
                    <>
                        <Menu as="div" className="relative">
                            <Menu.Button
                                className="relative rounded-full bg-secondaryServiceColor p-2 me-1 text-primaryServiceColor notification-btn hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1 focus:ring-offset-secondaryServiceColor">
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                {notifications.length > 0 && (
                                    <span
                                        className="absolute -top-1 -right-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-600 text-primaryServiceColor">
                                    {notifications.length}
                                </span>
                                )}
                                {verificationMessage && !isEnabled && (
                                    <span
                                        className="absolute -top-1 -right-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-600 text-primaryServiceColor">
                                       {notifications.length > 0 ? notifications.length + 1 : 1}
                                </span>
                                )}
                            </Menu.Button>
                            <Transition as={React.Fragment}>
                                <Menu.Items
                                    className="absolute right-0 mt-2 w-64 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                    <div className="py-1">
                                        {notifications.length > 0 ? (
                                            notifications.map((notification) => (
                                                <Menu.Item key={notification.userId}>
                                                    {({active}) => (
                                                        <button
                                                            onClick={() => handleNotificationClick(notification)}
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-primaryServiceColor')}
                                                        >
                                                            {`${notification.userFullName}: ${notification.messagePreview}`}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))
                                        ) : (
                                            <div className="block px-4 py-2 text-sm text-primaryServiceColor">No new
                                                notifications</div>
                                        )}
                                        {verificationMessage && (
                                            <div
                                                className="block px-4 py-2 text-sm text-primaryServiceColor">{verificationMessage}</div>
                                        )}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>

                        {/* Chat Icon */}
                        <button
                            type="button"
                            onClick={() => navigate('/chat')}
                            className="chat-btn rounded-full bg-secondaryServiceColor p-2 text-primaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1 focus:ring-offset-secondaryServiceColor"
                        >
                            <span className="sr-only">View chat</span>
                            <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </>
                )}
                {isLoggedIn ? (
                    <>
                        <Menu as="div" className="relative ml-1 exs:m-2">
                            <Menu.Button
                                className="inline-flex items-center justify-center rounded-md p-2 text-primaryServiceColor hover:bg-primaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ml-auto">
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                            </Menu.Button>

                            {/* Dropdown for Profile / Logout */}
                            <Transition as={React.Fragment}>
                                <Menu.Items
                                    className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">


                                    {roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && isEnabled && (
                                        <Menu.Item>
                                            {({active}) => (
                                                <Link to="/seller/register"
                                                      className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                    Register as Seller!
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    )}
                                    {hasPendingSellerRequest && (
                                        <Menu.Item>
                                            {({active}) => (
                                                <Link to="/submission"
                                                      className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                    View submission
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    )}
                                    {roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && !roles.includes('ROLE_ADMINISTRATOR') && (
                                        <Menu.Item>
                                            {({active}) => (
                                                <button onClick={handleShopClick}
                                                      className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                    View Your Shop
                                                </button>
                                            )}
                                        </Menu.Item>
                                    )}
                                    {roles.includes('ROLE_ADMINISTRATOR') && (
                                        <Menu.Item>
                                            {({active}) => (
                                                <Link to="/admin"
                                                      className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                    View Shops
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    )}
                                    {
                                        roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_ADMINISTRATOR') && roles.includes('ROLE_SELLER') && !hasPendingSellerRequest ? (
                                            <>
                                                <Menu.Item>
                                                    {({active}) => (
                                                        <Link
                                                            to="/customer/orders" // Close on click
                                                            className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                                                        >
                                                            Your Orders
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({active}) => (
                                                        <Link
                                                            to="/seller/orders"
                                                            className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                                                        >
                                                            Shop Orders
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            </>
                                        ) : roles.includes('ROLE_CUSTOMER') && !roles.includes('ROLE_ADMINISTRATOR') ? (
                                            <Menu.Item>
                                                {({active}) => (
                                                    <Link
                                                        to="/customer/orders" // Close on click
                                                        className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                                                    >
                                                        Your Orders
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        ) : roles.includes('ROLE_SELLER') && !hasPendingSellerRequest && !roles.includes('ROLE_ADMINISTRATOR') ? (
                                            <Menu.Item>
                                                {({active}) => (
                                                    <Link
                                                        to="/seller/orders"
                                                        className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                                                    >
                                                        Shop Orders
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        ) : null
                                    }

                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </>
                ) : (
                    <>
                        <Menu as="div" className="relative ml-2">
                            <Menu.Button
                                className="inline-flex items-center justify-center rounded-md p-2 text-primaryServiceColor hover:bg-primaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ml-auto">
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                            </Menu.Button>

                            {/* Dropdown for Profile / Logout */}
                            <Transition as={React.Fragment}>
                                <Menu.Items
                                    className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item>
                                        {({active}) => (
                                            <Link to="/register"
                                                  className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                Register
                                            </Link>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({active}) => (
                                            <Link to="/login"
                                                  className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white">
                                                Log in
                                            </Link>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </>
                )
                }
                {
                    isLoggedIn && (
                        <Menu as="div" className="relative ml-4">
                            <Menu.Button
                                className="flex rounded-full bg-primaryServiceColor text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondaryServiceColor">
                                <span className="sr-only">Open user menu</span>
                                {userInfo?.imageLink && userInfo.imageLink !== 'default/chat-icon.png' ? (
                                    <Avatar alt={userInfo?.fullName?.[0]} src={userInfo.imageLink}/>
                                ) : (
                                    <Avatar>{userInfo?.fullName?.[0]}</Avatar>
                                )}
                            </Menu.Button>

                            {/* Dropdown for Profile / Logout */}
                            <Transition as={React.Fragment}>
                                <Menu.Items
                                    className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <Link
                                                to="/settings"
                                                className="block px-4 py-2 text-sm text-primaryServiceColor"
                                            >
                                                Settings
                                            </Link>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={handleLogout}
                                                className="block w-full text-left px-4 py-2 text-sm text-primaryServiceColor"
                                            >
                                                Logout
                                            </button>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>

                        </Menu>
                    )
                }
            </div>
        </Disclosure>
    )
        ;
};

export default NavMobileSection;
