import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/cssfile.css'
import './css/fonts.css'
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@material-tailwind/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
      <GoogleOAuthProvider clientId="719553471057-pd7votii1leke8bie4bg04cigk5ie5r8.apps.googleusercontent.com">
          <ThemeProvider>
              <App />
          </ThemeProvider>
      </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
