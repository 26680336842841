import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './ShopDetails.css';
import { jwtDecode } from 'jwt-decode';
import authenticatedInstance from '../../custom-axios/axios-auth';
import SellerDetails from './SellerDetails';
import ProductList from './ProductList';
import ReviewSection from './ReviewSection';
import ShopInfo from './ShopInfo';
import { Helmet } from 'react-helmet';


const ShopDetails = () => {
    const { id } = useParams();
    const [sellerProfile, setSellerProfile] = useState(null);
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('JWT');
        if (token) {
            const decoded = jwtDecode(token);
            setUserId(decoded.userId);
        }
    }, []);

    const fetchShopDetails = async () => {
        try {
            const response = await authenticatedInstance.get(`/home/search/${id}/approved`);

            if (response.status === 200) {
                const data = response.data;
                if (!data.shop.reviews) {
                    data.shop.reviews = [];
                }
                setSellerProfile(data);
            } else {
                throw new Error('Unexpected response');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Access denied: Seller profile is not approved.');
                navigate('/unauthorized');  // Redirect to an unauthorized page if not approved
            } else if (error.response && error.response.status === 404) {
                console.error('Shop not found');
                navigate('/not-found');  // Redirect to a not found page if the profile doesn't exist
            } else {
                console.error('Error fetching shop details:', error);
            }
        }
    };

    const handleBackClick = () => {
        navigate('/results', { state: location.state });
    };

    useEffect(() => {
        setSellerProfile(null); // Reset sellerProfile when ID changes
        fetchShopDetails();
    }, [id]);

    if (!sellerProfile) {
        return <p>Loading shop details...</p>;
    }

    return (
        <div>
            <Helmet>
                <title>{sellerProfile?.shop?.name}</title>
                <meta name="description" content={sellerProfile?.shop?.description} />
            </Helmet>
            <div className="details-container flex flex-col lg:flex-row lg:space-x-6 space-y-6 lg:space-y-0">
                {/* In small screens, flex will be column. In large screens (lg), flex will be row */}
                <div className="w-full lg:w-1/2"> {/* Full width on small screens, half on large screens */}
                    <ShopInfo sellerProfile={sellerProfile} userId={userId} fetchShopDetails={fetchShopDetails}/>
                </div>
                <div className="w-full lg:w-1/2"> {/* Full width on small screens, half on large screens */}
                    <SellerDetails sellerProfile={sellerProfile} userId={userId}/>
                </div>
            </div>
            {/* The rest of the components stay as is */}
            <ProductList products={sellerProfile.shop.products} userId={userId} sellerProfile={sellerProfile}/>
            {/*<CategoryList filters={sellerProfile.shop.categoryFilters} holidays={sellerProfile.shop.categoryHolidays}*/}
            {/*              shopId={sellerProfile.shop.id} fetchShopDetails={fetchShopDetails} userId={userId}/>*/}
            <ReviewSection sellerProfile={sellerProfile} userId={userId} fetchShopDetails={fetchShopDetails}
                           id="reviews"/>
        </div>

    );
};

export default ShopDetails;