import React, { useState } from "react";
import authenticatedInstance from "../../../custom-axios/axios-auth";

const ManageSubscription = () => {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const cancelSubscription = async (cancelImmediately = false) => {
        setIsLoading(true);
        try {
            const endpoint = cancelImmediately
                ? "/subscription/cancel" // Cancel immediately
                : "/subscription/cancel-at-period-end"; // Cancel at end of billing period

            const response = await authenticatedInstance.post(endpoint);

            if (response.status === 200) {
                setMessage("Subscription cancelled successfully.");
            } else {
                setMessage("Failed to cancel subscription.");
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
        setIsLoading(false);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                <h1 className="text-2xl font-bold text-center mb-6">Manage Subscription</h1>
                <button
                    onClick={() => cancelSubscription(true)}
                    className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 w-full mb-4"
                    disabled={isLoading}
                >
                    {isLoading ? "Processing..." : "Cancel Subscription Immediately"}
                </button>
                <button
                    onClick={() => cancelSubscription(false)}
                    className="bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 w-full"
                    disabled={isLoading}
                >
                    {isLoading ? "Processing..." : "Cancel at End of Billing Period"}
                </button>
                <p className="mt-6 text-center text-gray-700">{message}</p>
            </div>
        </div>
    );
};

export default ManageSubscription;
