

const NoteInput = ({
                       newStatus,
                       reason,
                       statusNotes,
                       handleNoteChange,
                       setReason
                   }) => {
    return (
        <div>
            <label className="block text-darkBrown font-semibold text-sm mb-2">Additional note (Optional):</label>
            <textarea
                value={newStatus === 'APPROVED' ? reason : statusNotes[newStatus] || ''}
                onChange={(e) => {
                    if (newStatus === 'APPROVED') {
                        setReason(e.target.value); // For APPROVED, directly edit the reason
                    } else {
                        handleNoteChange(newStatus, e.target.value); // For other statuses, edit the corresponding note
                    }
                }}
                placeholder="Provide a reason for the status update"
                className="w-full p-2 border border-lightBrown rounded-md focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                rows="4"
            />
        </div>
    );
};
export default NoteInput;