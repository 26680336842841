import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth'; // Import your authenticated axios instance
import { useParams, useNavigate } from 'react-router-dom';
import CustomerProfileComponent from "../Profiles/CustomerProfileComponent";
import SellerProfileComponent from "../Profiles/SellerProfileComponent";
import AdminProfileComponent from "../Profiles/AdminProfileComponent";
import './UserDetailsComponent.css';

const UserDetailsComponent = () => {
    const { id } = useParams(); // Get the user id from the route params
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await authenticatedInstance.get(`/admin/users/${id}`);
                setUserDetails(response.data); // Assuming response.data is the UserDetailsExportDTO
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user details:', error);
                setError('Failed to load user details.');
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [id]);

    const handleEditProfile = (profileType, profileId) => {
        navigate(`/admin/users/${profileType}/${profileId}/edit`);
    };

    if (loading) {
        return <p className="loading-message">Loading...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    const { email, roles, customerProfile, sellerProfile, adminProfile } = userDetails;

    return (
        <div className="container user-details-container">
            <h1>User Details</h1>
            <p><strong>Email:</strong> {email}</p>
            <p><strong>Roles:</strong> {roles.join(', ')}</p>

            {customerProfile ? (
                <div className="profile-section">
                    <CustomerProfileComponent profile={customerProfile} />
                    <button onClick={() => handleEditProfile('customer', customerProfile.id)} className="edit-profile-button">Edit Customer Profile</button>
                </div>
            ) : (
                <div className="profile-section">
                    <button onClick={() => handleEditProfile('customer', id)} className="edit-profile-button">Create Customer Profile</button>
                </div>
            )}

            {sellerProfile ? (
                <div className="profile-section">
                    <SellerProfileComponent profile={sellerProfile} />
                    <button onClick={() => handleEditProfile('seller', sellerProfile.id)} className="edit-profile-button">Edit Seller Profile</button>
                </div>
            ) : (
                <div className="profile-section">
                    <button onClick={() => handleEditProfile('seller', id)} className="edit-profile-button">Create Seller Profile</button>
                </div>
            )}

            {adminProfile ? (
                <div className="profile-section">
                    <AdminProfileComponent profile={adminProfile} />
                    <button onClick={() => handleEditProfile('admin', adminProfile.id)} className="edit-profile-button">Edit Admin Profile</button>
                </div>
            ) : (
                <div className="profile-section">
                    <button onClick={() => handleEditProfile('admin', id)} className="edit-profile-button">Create Admin Profile</button>
                </div>
            )}
        </div>
    );
};

export default UserDetailsComponent;
