import React, {useState} from 'react';
import './HomePageCards.css';
import {productImageMapping, serviceImageMapping} from './imageMappings';
import axios from "../../custom-axios/axios";
import {useNavigate} from "react-router-dom"; // Adjust the path as necessary


const HomePageCards = ({cardTitles, selected}) => {
    const [error, setError] = useState(null); // Error state for invalid ZIP codes
    const navigate = useNavigate();
    const handleClick = async (category) => {
        try {
            const response = await axios.get(`/home/search/category?category=${category}&type=${selected}`);
            setError(null);  // Clear any errors if request succeeds
            navigate(`/results`, {state: {shops: response.data, type: selected}});
        } catch (err) {
            setError('No shops found for this ZIP code.');
        }
    };

    return (
        <div className="homePage-cards-container">
            {cardTitles.map((title, index) => {
                // Select the correct image mapping based on whether it's a product or service
                const imageMapping = selected === 'product' ? productImageMapping : serviceImageMapping;
                const imageSrc = imageMapping[title]; // Get the image source from the relevant mapping

                return (
                    <div
                        key={index}
                        className={`homePage-card flex justify-center flex-col items-center ${index % 2 === 0 ? 'homePage-card-up' : 'homePage-card-down'}`}
                        style={{
                            backgroundColor: selected === 'product' ? 'var(--secondary-product-color)' : 'var(--secondary-service-color)',
                            border: `2px solid black`,
                            color: selected === 'product' ? 'var(--primary-product-color)' : 'var(--primary-service-color)',
                        }}
                        onClick={() => handleClick(title)}
                    >
                        <h3 className="homePage-card-title"
                            style={{
                                color: selected === 'product' ? 'var(--primary-product-color)' : 'var(--primary-service-color)',
                            }}>
                            {title}
                        </h3>
                        {/* Dynamically render the image based on selected type */}
                        {imageSrc ? (
                            <img
                                src={`${process.env.PUBLIC_URL}/images/home/${imageSrc}`}
                                alt={title}
                                className="homePage-card-image"
                            />
                        ) : (
                            <div className="homePage-card-image-placeholder">No image available</div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default HomePageCards;
