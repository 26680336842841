import React, { useEffect, useState } from 'react';
import authenticatedInstance from "../../custom-axios/axios-auth";
import { jwtDecode } from 'jwt-decode';
import OrderFilter from './OrderFilter';
import OrderCards from './OrderCards';  // Use OrderCards instead of OrderTable
import OrderStatusModal from './OrderStatusModal';
import './OrdersPage.css';

const CustomerOrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userId, setUserId] = useState(null);
    const [customerId, setCustomerId] = useState(null);  // Customer ID based on user
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [allOrders, setAllOrders] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [reason, setReason] = useState('');

    // Fetch orders based on customer profile
    const fetchOrders = async (deliveryDate = null, status = 'All') => {
        try {
            setLoading(true);
            setOrders([]);  // Clear orders to reset display

            const token = localStorage.getItem('JWT');
            if (token) {
                const decoded = jwtDecode(token);
                const userId = decoded.userId;
                setUserId(userId);

                // Fetch customer ID directly without sending userId
                const customerResponse = await authenticatedInstance.get('/customer/customer-id');

                if (customerResponse.status === 200) {
                    const customerId = customerResponse.data;
                    setCustomerId(customerId);

                    let ordersResponse;
                    if (status !== 'All') {
                        ordersResponse = await authenticatedInstance.get(`/orders/customer/${customerId}/status/${status}`);
                    } else {
                        ordersResponse = await authenticatedInstance.get(`/orders/customer/${customerId}`);
                    }

                    if (ordersResponse.status === 200 && Array.isArray(ordersResponse.data)) {
                        setAllOrders(ordersResponse.data);  // Save all orders
                        filterOrdersByDate(ordersResponse.data, deliveryDate);  // Filter by date
                    }
                }
            }
        } catch (error) {
            setError('Failed to fetch orders');
        } finally {
            setLoading(false);
        }
    };

    // Filter orders by date
    const filterOrdersByDate = (orders, deliveryDate) => {
        if (deliveryDate) {
            const filteredOrders = orders.filter(order => {
                const orderDate = new Date(order.dateDelivery);
                const formattedOrderDate = orderDate.toISOString().split('T')[0]; // Convert order date to 'YYYY-MM-DD'
                return formattedOrderDate === deliveryDate;
            });
            setOrders(filteredOrders);
        } else {
            setOrders(orders);  // Show all orders if no date is selected
        }
    };

    // Handle status filter change
    const handleStatusChange = (e) => {
        const selectedStatus = e.target.value;
        setSelectedStatus(selectedStatus);
        fetchOrders(null, selectedStatus);  // Fetch orders based on selected status
    };

    // Handle date change for filtering
    const handleDateChange = (date) => {
        if (date) {
            setSelectedDate(date);

            // Extract the date components to avoid timezone shift issues
            const selectedDateWithoutTimezone = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

            filterOrdersByDate(allOrders, selectedDateWithoutTimezone);  // Pass formatted date for filtering
        } else {
            setSelectedDate(null); // Explicitly set the date to null
            setOrders(allOrders);  // Reset to all orders if no date is selected
        }
    };

    // Open modal to cancel order
    const openStatusModal = (order) => {
        setSelectedOrder(order);
        setNewStatus('CANCELLED');  // Customers can only cancel orders
        setIsModalOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
        setNewStatus('');
        setReason('');
    };

    // Submit status change and send message to seller
    const submitStatusChange = () => {
        if (!selectedOrder) {
            alert("An error occurred. No order selected.");
            return;
        }

        const message = `Order for Product: [${selectedOrder.productTitle}](http://thehomemadeshop.com/product/${selectedOrder.productId}) has been canceled.\nReason: ${reason}`;
        const payload = {
            orderId: selectedOrder.id,
            newStatus: newStatus,
            senderId: userId,
            recipientId: selectedOrder.seller.user.id,
            message: message,
            timestamp: new Date().toISOString(),
        };

        authenticatedInstance.post('/orders/update-status-with-message', payload)
            .then(() => {
                alert('Order has been canceled and the message sent.');
                closeModal();
                fetchOrders();  // Optionally refresh orders after updating status
            })
            .catch(error => {
                alert('Failed to cancel the order or send the message.');
            });
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="orders-page">
            <h1 className="text-2xl font-bold text-darkBrown font-raleway mb-6">Customer Orders</h1>
            <OrderFilter
                selectedDate={selectedDate}
                selectedStatus={selectedStatus}
                handleDateChange={handleDateChange}
                handleStatusChange={handleStatusChange}
            />
            <OrderCards orders={orders} openStatusModal={openStatusModal} />
            <OrderStatusModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                submitStatusChange={submitStatusChange}
                newStatus={newStatus}
                setNewStatus={setNewStatus}
                reason={reason}
                setReason={setReason}
                statusOptions={['CANCELLED']}  // Only "Canceled" option for customers
            />
        </div>
    );
};

export default CustomerOrdersPage;
