import React, { useContext } from 'react';
import ImageCropperComponent from './ImageCropper';
import { SubscriptionContext } from '../../contexts/SubscriptionContext';

const ShopDetailsComponent = ({
                                  shopName,
                                  shopDescription,
                                  shopZipCode,
                                  setShopName,
                                  setShopDescription,
                                  setShopZipCode,
                                  setShopProfilePicture,
                              }) => {
    const { selectedPlan } = useContext(SubscriptionContext);

    // Define colors based on productType
    const colors = {
        background: selectedPlan.productType === 'PRODUCT' ? '#f49ac1' : '#C5E09D', // secondaryProductColor or secondaryServiceColor
        border: selectedPlan.productType === 'PRODUCT' ? '#B22a7a' : '#1e3f16', // primaryProductColor or primaryServiceColor
        text: selectedPlan.productType === 'PRODUCT' ? '#B23278' : '#253329', // textProductColor or textServiceColor
    };

    return (
        <div
            className="p-6 rounded-lg shadow-md max-w-3xl mx-auto font-poppins"
            style={{
                backgroundColor: colors.background,
                border: `2px solid ${colors.border}`,
            }}
        >
            <h2
                className="text-2xl font-bold mb-6 text-center"
                style={{ color: colors.text }}
            >
                Shop Details
            </h2>

            {/* Shop Name */}
            <div className="mb-4">
                <label
                    className="block text-lg font-semibold mb-2"
                    style={{ color: colors.text }}
                >
                    Shop Name
                </label>
                <input
                    type="text"
                    value={shopName}
                    onChange={(e) => setShopName(e.target.value)}
                    placeholder="Enter shop name"
                    required
                    className="border border-gray-300 p-3 rounded-md w-full bg-gray-100"
                />
            </div>

            {/* Shop Description */}
            <div className="mb-4">
                <label
                    className="block text-lg font-semibold mb-2"
                    style={{ color: colors.text }}
                >
                    Shop Description
                </label>
                <textarea
                    value={shopDescription}
                    onChange={(e) => setShopDescription(e.target.value)}
                    placeholder="Enter shop description"
                    required
                    className="border border-gray-300 p-3 rounded-md w-full resize-none h-32 bg-gray-100"
                />
            </div>

            {/* Shop ZIP Code */}
            <div className="mb-4">
                <label
                    className="block text-lg font-semibold mb-2"
                    style={{ color: colors.text }}
                >
                    Shop ZIP Code
                </label>
                <input
                    type="text"
                    value={shopZipCode}
                    onChange={(e) => setShopZipCode(e.target.value)}
                    placeholder="Enter shop ZIP code"
                    required
                    className="border border-gray-300 p-3 rounded-md w-full bg-gray-100"
                />
            </div>

            {/* Shop Profile Picture */}
            <ImageCropperComponent
                label="Shop Profile Picture"
                aspectRatio={4 / 3}
                onImageChange={setShopProfilePicture}
            />
        </div>
    );
};

export default ShopDetailsComponent;
