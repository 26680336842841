// src/components/UnauthorizedPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const UnauthorizedPage = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-gray-800 mb-4">403</h1>
                <p className="text-xl text-gray-600 mb-8">You are not authorized to access this page.</p>
                <Link to="/" className="px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300">
                    Go Back to Home
                </Link>
            </div>
        </div>
    );
};

export default UnauthorizedPage;
