const MessagePreview = ({ renderedMessage }) => {
    return (
        <div>
            <label className="block text-darkBrown font-semibold text-sm mb-2">Message Preview:</label>
            <div className="mt-2 text-sm text-gray-700">
                <p>{renderedMessage}</p>
            </div>
        </div>
    );
};
export default MessagePreview;