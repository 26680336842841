import React, { useState } from "react";
import authenticatedInstance from "../../../custom-axios/axios-auth";
import { FiArrowDownLeft, FiInfo } from "react-icons/fi";

const DowngradeSubscription = ({ currentPlan, availablePlans, setMessage, setLoading }) => {
    const [selectedPlan, setSelectedPlan] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);

    const handleDowngrade = async () => {
        setLoading(true);
        try {
            const response = await authenticatedInstance.post("/subscription/downgrade-subscription", {
                newPriceId: selectedPlan,
            });

            if (response.status === 200) {
                setMessage("Subscription downgrade scheduled for the next billing cycle.");
            } else {
                setMessage("Failed to schedule downgrade.");
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
        setLoading(false);
    };

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Downgrade Subscription</h2>
            <div className="flex items-center">
                <select
                    value={selectedPlan}
                    onChange={(e) => setSelectedPlan(e.target.value)}
                    className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:outline-none"
                >
                    {availablePlans.map((plan) => (
                        <option key={plan.priceId} value={plan.priceId}>
                            {plan.name} - ${plan.price.toFixed(2)}
                        </option>
                    ))}
                </select>

                <div
                    className="ml-2 relative flex items-center cursor-pointer"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <FiInfo className="text-blue-600" />
                    {showTooltip && (
                        <div className="absolute top-8 left-0 w-48 bg-gray-900 text-white text-sm px-2 py-1 rounded shadow-lg">
                            Downgrade will take effect at the start of the next billing cycle.
                        </div>
                    )}
                </div>
            </div>

            <button
                onClick={handleDowngrade}
                className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 w-full"
            >
                <FiArrowDownLeft className="inline mr-2" /> Schedule Downgrade
            </button>
        </div>
    );
};

export default DowngradeSubscription;
