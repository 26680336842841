import React, {useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Menu, Transition} from '@headlessui/react';
import {BellIcon, ChatBubbleOvalLeftEllipsisIcon} from '@heroicons/react/24/outline';
import Avatar from '@mui/material/Avatar';
import {UserContext} from '../../contexts/UserContext';
import NotificationItem from './NotificationItem';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const NavRightSection = () => {
    const {
        isLoggedIn,
        userInfo,
        notifications,
        verificationMessage,
        isEnabled,
        setNotifications,
        logout,
        user
    } = useContext(UserContext);

    const navigate = useNavigate();

    const handleNotificationClick = (notification) => {
        navigate('/chat', {
            state: {
                userId: user?.userId,
                sellerId: notification.userId,
                sellerFullName: notification.userFullName,
            },
        });

        setNotifications((prevNotifications) =>
            prevNotifications.filter((n) => n.userId !== notification.userId)
        );
    };

    const handleLogout = () => {
        logout();
        navigate('/', {replace: true});
    };

    return (
        <div className="flex items-center space-x-4">
            {!isLoggedIn ? (
                <>
                    {/* Log In / Register Buttons */}
                    <Link to="/register"
                          className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                        Register
                    </Link>
                    <Link to="/login"
                          className="text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white px-4 py-2 rounded-md text-sm font-medium whitespace-nowrap">
                        Log in
                    </Link>
                </>
            ) : (
                <>
                    {/* Notifications */}
                    <Menu as="div" className="relative">
                        <Menu.Button
                            className="relative rounded-full bg-secondaryServiceColor p-1 text-primaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondaryServiceColor">
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true"/>
                            {notifications.length > 0 && (
                                <span
                                    className="absolute -top-1 -right-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-600 text-primaryServiceColor">
                                    {notifications.length}
                                </span>
                            )}
                            {verificationMessage && !isEnabled && (
                                <span
                                    className="absolute -top-1 -right-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-600 text-primaryServiceColor">
                                       {notifications.length > 0 ? notifications.length + 1 : 1}
                                </span>
                            )}
                        </Menu.Button>
                        <Transition as={React.Fragment}>
                            <Menu.Items
                                className="absolute right-0 mt-2 w-64 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                <div className="py-1">
                                    {notifications.length > 0 ? (
                                        notifications.map((notification) => (
                                            <Menu.Item key={notification.userId}>
                                                {({active}) => (
                                                    <button
                                                        onClick={() => handleNotificationClick(notification)}
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-primaryServiceColor')}
                                                    >
                                                        {`${notification.userFullName}: ${notification.messagePreview}`}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))
                                    ) : (
                                        <div className="block px-4 py-2 text-sm text-primaryServiceColor">No new
                                            notifications</div>
                                    )}
                                    {verificationMessage && (
                                        <div
                                            className="block px-4 py-2 text-sm text-primaryServiceColor">{verificationMessage}</div>
                                    )}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>

                    {/* Chat Icon */}
                    <button
                        type="button"
                        onClick={() => navigate('/chat')}
                        className="rounded-full bg-secondaryServiceColor p-1 text-primaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondaryServiceColor"
                    >
                        <span className="sr-only">View chat</span>
                        <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6" aria-hidden="true"/>
                    </button>

                    {/* Logout Button */}
                    <button
                        type="button"
                        onClick={handleLogout}
                        className="rounded-full bg-secondaryServiceColor p-1 text-primaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondaryServiceColor"
                    >
                        <span className="sr-only">Logout</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M15.75 9V5.25a2.25 2.25 0 00-2.25-2.25h-6A2.25 2.25 0 005.25 5.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M9 12h12m0 0l-3-3m3 3l-3 3"/>
                        </svg>
                    </button>

                    {/* User Avatar with Dropdown */}
                    <Menu as="div" className="relative ml-3">
                        <div>
                            <Menu.Button
                                className="flex rounded-full bg-secondaryServiceColor text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondaryServiceColor">
                                <span className="sr-only">Open user menu</span>
                                {userInfo?.imageLink && userInfo.imageLink !== 'default/chat-icon.png' ? (
                                    <Avatar alt={userInfo?.fullName?.[0]} src={userInfo.imageLink}/>
                                ) : (
                                    <Avatar>{userInfo?.fullName?.[0]}</Avatar>
                                )}
                            </Menu.Button>
                        </div>
                        <Transition as={React.Fragment}>
                            <Menu.Items
                                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                    {({active}) => (
                                        <Link to="/settings"
                                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-primaryServiceColor')}>
                                            Settings
                                        </Link>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({active}) => (
                                        <button
                                            onClick={handleLogout}
                                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-primaryServiceColor')}
                                        >
                                            Logout
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </>
            )}
        </div>
    );
};

export default NavRightSection;
