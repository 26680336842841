import { useMemo } from 'react';

const useRenderMessageContent = (content) => {
    const renderedContent = useMemo(() => {
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = linkRegex.exec(content)) !== null) {
            const [fullMatch, text, url] = match;
            const start = match.index;

            // Push preceding text
            if (start > lastIndex) {
                parts.push(content.substring(lastIndex, start));
            }

            // Push link
            parts.push(
                <a
                    key={start}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                >
                    {text}
                </a>
            );

            lastIndex = linkRegex.lastIndex;
        }

        // Push remaining text
        if (lastIndex < content.length) {
            parts.push(content.substring(lastIndex));
        }

        return parts;
    }, [content]);

    return renderedContent;
};

export default useRenderMessageContent;
