const getOrderStatusClasses = (status) => {
    switch (status) {
        case 'ACTIVE':
            return 'bg-green-100 text-green-900';
        case 'AWAITING_APPROVAL':
            return 'bg-yellow-100 text-yellow-900'; // or another color of your choice
        case 'APPROVED':
            return 'bg-blue-100 text-blue-900'; // or another color of your choice
        case 'COMPLETED':
            return 'bg-blue-100 text-blue-900';
        case 'CANCELLED':
            return 'bg-red-100 text-red-900';
        case 'EXPIRED':
            return 'bg-yellow-100 text-yellow-900';
        case 'DELIVERED':
            return 'bg-green-200 text-green-900'; // or another color of your choice
        default:
            return 'bg-gray-100 text-gray-900';
    }
};


export default getOrderStatusClasses;
