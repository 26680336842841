import React from 'react';

const SellerProfileComponent = ({ profile }) => {
    if (!profile) {
        return (
            <div className="profile-section">
                <h2>Seller Profile</h2>
                <button className="create-profile-button">Create Seller Profile</button>
            </div>
        );
    }

    return (
        <div className="profile-section">
            <h2>Seller Profile</h2>
            <p><strong>First Name:</strong> {profile.firstName}</p>
            <p><strong>Last Name:</strong> {profile.lastName}</p>
            <p><strong>Bio:</strong> {profile.bio}</p>
            <p><strong>Online Status:</strong> {profile.onlineStatus ? 'Online' : 'Offline'}</p>
            <p><strong>Approved:</strong> {profile.approved ? 'Yes' : 'No'}</p>
            {profile.contactInformation && (
                <div className="contact-information">
                    <h3>Contact Information:</h3>
                    <p><strong>Mobile Phone:</strong> {profile.contactInformation.mobilePhone}</p>
                    <p><strong>Email:</strong> {profile.contactInformation.email}</p>
                </div>
            )}
            {profile.socialMediaLinks && profile.socialMediaLinks.length > 0 && (
                <div className="social-media-links">
                    <h3>Social Media Links:</h3>
                    <ul>
                        {profile.socialMediaLinks.map(link => (
                            <li key={link.id}>
                                <strong>{link.platform}:</strong> <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {profile.profilePicture && (
                <div className="profile-picture">
                    <img src={profile.profilePicture.imagePath} alt={`Seller ${profile.firstName} ${profile.lastName}`} />
                </div>
            )}
        </div>
    );
};

export default SellerProfileComponent;
