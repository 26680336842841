import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { Disclosure } from '@headlessui/react';
import NavLeftSection from "./NavLeftSection";
import NavRightSection from "./NavRightSection";
import NavMobileSection from "./NavMobileSection";

const Navbar = () => {
    const { isLoggedIn } = useContext(UserContext);

    return (
        <Disclosure as="nav" className="bg-secondaryServiceColor">
            {/* Mobile View - Show NavMobileSection */}
            <div className="sm:hidden h-24 flex items-center justify-between w-full"> {/* Visible only on mobile screens */}
                <NavMobileSection/> {/* Render the mobile section */}
            </div>

            {/* Desktop View - Show NavLeftSection and NavRightSection */}
            <div className="hidden sm:flex w-full px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-24 items-center justify-between w-full">
                    {/* Left Section */}
                    <NavLeftSection/> {/* Apply primaryServiceColor here too in NavLeftSection */}

                    {/* Right Section */}
                    <NavRightSection/> {/* Apply primaryServiceColor here too in NavRightSection */}
                </div>
            </div>

            {/* Legacy Mobile Menu Panel (if still needed) */}
            <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2">
                    {!isLoggedIn && (
                        <>
                            <Disclosure.Button
                                as={Link}
                                to="/register"
                                className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                            >
                                Register
                            </Disclosure.Button>
                            <Disclosure.Button
                                as={Link}
                                to="/login"
                                className="block px-3 py-2 rounded-md text-base font-medium text-primaryServiceColor hover:bg-secondaryServiceColor hover:text-white"
                            >
                                Log in
                            </Disclosure.Button>
                        </>
                    )}
                </div>
            </Disclosure.Panel>
        </Disclosure>
    );
};

export default Navbar;
