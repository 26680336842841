import React, { useState } from 'react';
import Select from 'react-select';
import { useOrderSorting } from "../../../contexts/OrderSortingContext";

// Options for status filter
const statusOptions = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
    { value: 'APPROVED', label: 'Approved' },
    { value: 'COMPLETED', label: 'Completed' },
    { value: 'CANCELLED', label: 'Cancelled' },
    { value: 'EXPIRED', label: 'Expired' },
    { value: 'DELIVERED', label: 'Delivered' },
];


// Options for sorting
// Updated options for sorting
const sortOptions = [
    { value: 'asc', label: 'Sort by Delivery Date Ascending' },
    { value: 'desc', label: 'Sort by Delivery Date Descending' },
    { value: 'statusAsc', label: 'Sort by Status Ascending' }, // New option
    { value: 'statusDesc', label: 'Sort by Status Descending' }, // New option
];


const OrderSortingControls = ({ allTitles }) => {
    const { selectedStatus, setSelectedStatus, selectedTitles, setSelectedTitles, sortOrder, setSortOrder } = useOrderSorting();
    const [isExpanded, setIsExpanded] = useState(false); // State to manage visibility of controls

    // Extract unique titles
    const uniqueTitles = Array.from(new Set(allTitles)).map(title => ({ value: title, label: title }));

    const handleStatusChange = (selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setSelectedStatus(values);
    };

    const handleTitleChange = (selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setSelectedTitles(values);
    };

    const handleSortChange = (selectedOption) => {
        setSortOrder(selectedOption ? selectedOption.value : 'asc');
    };

    return (
        <div className="mb-4">
            <button
                onClick={() => setIsExpanded(prev => !prev)}
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
                {isExpanded ? 'Hide Sort & Filter' : 'Show Sort & Filter'}
            </button>
            {isExpanded && (
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between">
                        <div className="w-1/2 mr-2">
                            <h4 className="mb-2 font-semibold">Filter by Product Title:</h4>
                            <Select
                                isMulti
                                name="titles"
                                options={uniqueTitles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleTitleChange}
                                placeholder="Select product titles..."
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: 'gray',
                                        '&:hover': {
                                            borderColor: 'blue',
                                        },
                                    }),
                                }}
                            />
                        </div>
                        <div className="w-1/2 ml-2">
                            <h4 className="mb-2 font-semibold">Filter by Status:</h4>
                            <Select
                                isMulti
                                name="statuses"
                                options={statusOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleStatusChange}
                                placeholder="Select statuses..."
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: 'gray',
                                        '&:hover': {
                                            borderColor: 'blue',
                                        },
                                    }),
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <h4 className="mb-2 font-semibold">Sort Orders:</h4>
                        <Select
                            name="sortOrder"
                            options={sortOptions}
                            className="basic-single"
                            classNamePrefix="select"
                            onChange={handleSortChange}
                            placeholder="Select sorting order..."
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderColor: 'gray',
                                    '&:hover': {
                                        borderColor: 'blue',
                                    },
                                }),
                            }}
                        />

                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderSortingControls;
