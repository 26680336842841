import React from 'react';
import Select from 'react-select';

// Define your custom colors
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--secondary-service-color)', // Use your secondary service color
        borderColor: state.isFocused ? 'var(--primary-service-color)' : 'var(--primary-service-color)', // Use primary color for borders
        '&:hover': {
            borderColor: 'var(--primary-service-color)', // Hover effect
        },
        boxShadow: state.isFocused ? '0 0 0 1px var(--primary-service-color)' : null,
        color: 'var(--primary-service-color)', // Text color inside the select input
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--primary-service-color)', // Selected option text color
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'var(--secondary-service-color)', // Dropdown menu background color
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'var(--primary-service-color)' : 'var(--secondary-service-color)', // Selected and non-selected option colors
        color: state.isSelected ? 'white' : 'var(--primary-service-color)', // Text color for selected and non-selected options
        '&:hover': {
            backgroundColor: 'var(--primary-service-color)', // Hover effect for options
            color: 'white',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'var(--primary-service-color)', // Placeholder text color
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'var(--primary-service-color)', // Color for the dropdown arrow
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: 'var(--primary-service-color)', // Separator color between indicators
    }),
};

const CustomSelect = ({ typeOptions, selectedType, setSelectedType }) => {
    return (
        <div className="w-full md:w-auto flex-grow">
            <Select
                value={typeOptions.find(option => option.value === selectedType)}
                onChange={(selectedOption) => setSelectedType(selectedOption.value)}
                options={typeOptions}
                styles={customStyles} // Apply the custom styles
                className="w-full md:w-auto"
            />
        </div>
    );
};

export default CustomSelect;
