import { useState } from 'react';
import authenticatedInstance from "../custom-axios/axios-auth"; // Assuming your axios instance
import getStatusMessage from './getStatusMessage'; // Import the helper function

const useOrderStatus = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [reason, setReason] = useState('');
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const openStatusModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
        setNewStatus('');
        setReason('');
        setError(null);
        setIsSubmitting(false);
    };

    // Submit status change with message
    const submitStatusChange = async (senderId, recipientId, newStatus, message, onStatusChange) => {
        if (!selectedOrder || !newStatus) {
            alert("Please select a status and provide a reason.");
            return;
        }

        const payload = {
            orderId: selectedOrder.id,
            newStatus,
            senderId,
            recipientId,
            message,
            timestamp: new Date().toISOString(),
        };

        setIsSubmitting(true);
        try {
            const response = await authenticatedInstance.post('/orders/update-status-with-message', payload);

            if (response.status === 200) {
                onStatusChange(newStatus); // Update order status
                alert('Order status updated and message sent.');
                closeModal();
            } else {
                alert(`Status update failed: ${response.data}`);
            }
        } catch (err) {
            alert(`Failed to update order status or send message: ${err.message}`);
            console.error(err);
            setError(err);
        } finally {
            setIsSubmitting(false);
        }
    };



    return {
        isModalOpen,
        openStatusModal,
        closeModal,
        submitStatusChange,
        newStatus,
        setNewStatus,
        reason,
        setReason,
        error,
        isSubmitting,
    };
};

export default useOrderStatus;
