import React, { useState } from 'react';
import Avatar from "@mui/material/Avatar";

const BASE_FILE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/files/download`;


const ReviewItem = ({ review, userId, onDelete }) => {
    return (
        <div style={styles.reviewContainer}>
            <div style={styles.userInfo}>
                {/*<img src={`${BASE_FILE_URL}/${review.imagePath}`}*/}
                {/*     // alt="profile"*/}
                {/*     alt={`${review.userFullName[0]}'s profile picture'`}*/}
                {/*     style={styles.profileImage}/>*/}
                {review?.imagePath ? (
                    <Avatar alt={review.userFullName} src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${review.imagePath}`} style={styles.profileImage}/>
                ) : (
                    <Avatar style={styles.profileImage}>{review?.userFullName?.[0]}</Avatar>
                )}
                <div>
                    <p style={styles.userName}>{review.userFullName}</p>
                </div>
            </div>

            <div style={styles.ratingContainer}>
                {[...Array(5)].map((_, index) => (
                    <SvgStar key={index} filled={index < review.rating}/>
                ))}
                <p style={styles.reviewTitle}>{review.title}</p>
            </div>
            <p style={styles.reviewDate}>
                {(() => {
                    const dateParts = (review.dateTime).toString().split(',');
                    return (
                        <>
                            {/* Display the first part (Date) */}
                            <span>Reviewed on: {dateParts[0]}/</span>
                            {/* Optionally display the second part (Time Zone) */}
                            <span>{dateParts[1]}/</span>
                            <span>{dateParts[2]}</span>
                        </>
                    );
                })()}
            </p>
            <p style={styles.reviewText}>{review.comment}</p>


            <div style={styles.helpfulness}>
                {review.userId === userId && (
                    <button className="custom-btn-secondary" onClick={() => onDelete(review.id)}>
                        Delete Review
                    </button>
                )}
            </div>
        </div>
    );
};

const SvgStar = ({filled}) => (
    <svg width={16} height={16} fill="none" viewBox="0 0 22 20">
        <path
            d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
            fill={filled ? '#FBBF24' : '#D1D5DB'}
        />
    </svg>
);

const ReviewList = ({ reviews, userId, onDeleteReview }) => {
    const [showAll, setShowAll] = useState(false);
    const userReview = reviews.find((review) => review.userId === userId);
    const otherReviews = reviews.filter((review) => review.userId !== userId);
    const sortedOtherReviews = otherReviews.sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());

    const sortedReviews = userReview ? [userReview, ...sortedOtherReviews] : sortedOtherReviews;
    const reviewsToDisplay = showAll ? sortedReviews : sortedReviews.slice(0, 3);

    return (
        <div>
            <ul>
                {reviewsToDisplay.map((item) => (
                    <li key={item.id}>
                        <ReviewItem review={item} userId={userId} onDelete={onDeleteReview} />
                    </li>
                ))}
            </ul>
            {sortedReviews.length > 3 && !showAll && (
                <button onClick={() => setShowAll(true)} style={styles.showMoreButton}>
                    Click to see all
                </button>
            )}
        </div>
    );
};

const styles = {
    reviewContainer: {
        padding: '16px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        margin: '8px 0',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '8px',
    },
    profileImage: {
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        marginRight: '10px',
    },
    userName: {
        fontWeight: '600',
    },
    ratingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '4px',
    },
    reviewTitle: {
        marginLeft: '8px',
        fontWeight: 'bold',
        color: '#1F2937',
    },
    reviewDate: {
        fontSize: '12px',
        color: '#6B7280',
        marginBottom: '4px',
    },
    reviewText: {
        fontSize: '14px',
        color: '#4B5563',
        marginBottom: '8px',
    },
    readMore: {
        marginBottom: '8px',
        fontSize: '14px',
        color: '#3B82F6',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
    helpfulness: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#fff',
        padding: '10px 20px',
        borderRadius: '10px',
        border: '1px solid red',
        marginRight: '8px',
        marginBottom: '8px',
        color: 'red',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
    },
    showMoreButton: {
        padding: '10px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    showMoreText: {
        fontSize: '14px',
        color: '#3B82F6',
        textDecoration: 'underline',
    },
};

export default ReviewList;
