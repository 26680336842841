import React, { useState } from 'react';

const AdminHomeImageUploader = ({ onUploadImage }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (file) {
            onUploadImage(file);
            setFile(null);
        } else {
            alert('Please select a file to upload.');
        }
    };

    return (
        <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Upload Homepage Images</h2>

            <div className="flex items-center space-x-4">
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleUpload} className="custom-btn-primary">
                    Upload Image
                </button>
            </div>
        </div>
    );
};

export default AdminHomeImageUploader;
