import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom'; // To capture query params
import axiosAuth from "../../custom-axios/axios-auth";
import { UserContext } from '../../contexts/UserContext';
const ConfirmAccount = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams(); // Capture query parameters
    const token = searchParams.get('token');
    const { setIsEnabled, setVerificationMessage } = useContext(UserContext);

    const navigate = useNavigate(); // Initialize useNavigate hook
    useEffect(() => {
        const confirmAccount = async () => {
            if (!token) {
                setMessage('Token is missing');
                setLoading(false);
                return;
            }
            try {
                const response = await axiosAuth.get(`/confirm-account?token=${token}`);
                if(response.status===200){
                    const newToken = response.data;
                    localStorage.setItem('JWT', newToken);
                    setMessage("Account confirmed successfully.");
                    setIsEnabled(true);
                    setVerificationMessage(null);
                }
            } catch (error) {
                setMessage('Failed to confirm account. The token may be invalid or expired.');
            } finally {
                setLoading(false);
            }
        };

        confirmAccount(); // Call the function on component mount
    }, [token]);

    if (loading) {
        return <p>Loading...</p>; // Show loading state while waiting for the response
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">Account Confirmation</h2>
            <p className="text-xl text-gray-600">{message}</p>
        </div>
    );
};

export default ConfirmAccount;
