import React from 'react';

const CustomerProfileComponent = ({ profile }) => {
    if (!profile) {
        return (
            <div className="profile-section">
                <h2>Customer Profile</h2>
                <button className="create-profile-button">Create Customer Profile</button>
            </div>
        );
    }

    return (
        <div className="profile-section">
            <h2>Customer Profile</h2>
            <p><strong>First Name:</strong> {profile.firstName}</p>
            <p><strong>Last Name:</strong> {profile.lastName}</p>
            {profile.reviews && profile.reviews.length > 0 && (
                <div className="reviews-section">
                    <h3>Reviews:</h3>
                    <ul>
                        {profile.reviews.map(review => (
                            <li key={review.id}>
                                <p><strong>Rating:</strong> {review.rating}</p>
                                <p><strong>Comment:</strong> {review.comment}</p>
                                <p><strong>Date:</strong> {new Date(review.dateTime).toLocaleString()}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomerProfileComponent;
