import React, { useContext } from 'react';
import { useOrderContext } from "../../../../contexts/OrderContext";
import { UserContext } from "../../../../contexts/UserContext";
import OrderDetails from './OrderDetails';
import ProfileInfo from './ProfileInfo';
import OrderStatusModal from "../../OrderStatusModal";
import useOrderStatus from '../../../../hooks/useOrderStatus';

const OrderComponent = () => {
    const { user } = useContext(UserContext);
    const { order, setOrder, error, loading } = useOrderContext();
    const {
        isModalOpen,
        openStatusModal,
        closeModal,
        submitStatusChange,
        newStatus,
        setNewStatus,
        reason,
        setReason,
        isSubmitting,
    } = useOrderStatus();

    const handleStatusChange = (updatedStatus) => {
        setOrder(prevOrder => ({
            ...prevOrder,
            status: { status: updatedStatus },
        }));
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-red-500">Error: {error.message}</div>;
    if (!order) return <div className="text-red-500">No order found.</div>;

    const isSeller = user && user.userId === order.seller.user.id;
    const profileInfo = isSeller ? order.buyer : order.seller;
    const recipientId = isSeller ? order.buyer.user.id : order.seller.user.id;

    let statusOptions = [];

    if (isSeller) {
        if (order.status.status === 'AWAITING_APPROVAL') {
            statusOptions = ['APPROVED', 'CANCELLED'];
        } else {
            statusOptions = ['ACTIVE', 'CANCELLED', 'COMPLETED', 'DELIVERED', 'EXPIRED'];
        }
    } else if (order.status.status === 'AWAITING_APPROVAL') {
        statusOptions = ['CANCELLED'];
    }

    return (
        <div className="p-4 bg-white rounded shadow">
            <h2 className="text-xl font-bold mb-4">Order Details</h2>
            <OrderDetails
                order={order}
                openStatusModal={openStatusModal}
                isSeller={isSeller}
                setNewStatus={setNewStatus}
            />            <ProfileInfo profileInfo={profileInfo} isSeller={isSeller} />
            <OrderStatusModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                submitStatusChange={(newStatus, message) => submitStatusChange(user.userId, recipientId, newStatus, message, handleStatusChange)}
                newStatus={newStatus}
                setNewStatus={setNewStatus}
                reason={reason}
                setReason={setReason}
                statusOptions={statusOptions}
                isSubmitting={isSubmitting}
                selectedOrder={order}
            />

            {/* Message Display Section */}
            {order.message && (
                <div className="mt-4 p-4 bg-primaryGreen rounded-lg text-darkBrown">
                    <p className="font-semibold">Latest Message:</p>
                    <div className="message-content">
                        {renderMessageContent(order.message.content)}
                    </div>
                    <p className="text-sm mt-2 text-gray-700">Sent on: {new Date(order.message.timestamp).toLocaleString()}</p>
                </div>
            )}
        </div>
    );
};


// Function to render message content with links
const renderMessageContent = (content) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = linkRegex.exec(content)) !== null) {
        const [fullMatch, text, url] = match;
        const start = match.index;

        // Push preceding text
        if (start > lastIndex) {
            parts.push(content.substring(lastIndex, start));
        }

        // Push link
        parts.push(
            <a
                key={start}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-700"
            >
                {text}
            </a>
        );

        lastIndex = linkRegex.lastIndex;
    }

    // Push remaining text
    if (lastIndex < content.length) {
        parts.push(content.substring(lastIndex));
    }

    return parts;
};

export default OrderComponent;
