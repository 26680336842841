import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import authenticatedInstance from "../../../custom-axios/axios-auth"; // Use your custom axios instance

const UpgradeSubscription = ({ subscriptionId, currentPlan, availablePlans, setMessage, setLoading, onUpgradeSuccess }) => {
    const [newPriceId, setNewPriceId] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState("");

    const handleUpgrade = async () => {
        if (!newPriceId) {
            alert("Please select a new plan to upgrade.");
            return;
        }

        setLoading(true);
        try {
            // Send request to the backend to upgrade the subscription using subscriptionId and newPriceId
            const response = await authenticatedInstance.post("/subscription/upgrade-subscription", {
                subscriptionId: subscriptionId,
                newPriceId: newPriceId,
            });

            setPaymentIntentClientSecret(response.data.paymentIntentClientSecret); // Store client secret for payment
            setMessage("Subscription upgrade initiated. Please complete the payment.");
        } catch (error) {
            console.error("Error upgrading subscription:", error);
            setMessage(`Failed to upgrade subscription: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handlePayment = async () => {
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentIntent } = await stripe.confirmCardPayment(paymentIntentClientSecret, {
            payment_method: {
                card: cardElement,
            },
        });

        if (error) {
            setMessage(`Payment failed: ${error.message}`);
        } else if (paymentIntent.status === "succeeded") {
            setMessage("Payment successful! Subscription upgraded.");
            onUpgradeSuccess(newPriceId); // Notify the parent component about the successful upgrade
        }
    };

    return (
        <div>
            <h2>Upgrade Your Subscription</h2>
            <select value={newPriceId} onChange={(e) => setNewPriceId(e.target.value)}>
                <option value="">Select Plan</option>
                {availablePlans.map((plan) => (
                    <option key={plan.priceId} value={plan.priceId}>
                        {plan.name} - ${plan.price}
                    </option>
                ))}
            </select>
            <button onClick={handleUpgrade} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg">
                Upgrade Subscription
            </button>

            {paymentIntentClientSecret && (
                <div>
                    <h3>Complete Payment</h3>
                    <CardElement />
                    <button onClick={handlePayment} className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg">
                        Pay Now
                    </button>
                </div>
            )}
        </div>
    );
};

export default UpgradeSubscription;
