import React, { useState, useEffect } from 'react';
import authenticatedInstance from '../../custom-axios/axios-auth';
import ApproveSubmissionComponent from '../Submission/ApproveSubmissionComponent';
import { useNavigate } from 'react-router-dom';

const SubmissionsComponent = ({ status, endpoint }) => {
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // useNavigate hook to programmatically navigate

    useEffect(() => {
        fetchSubmissions();
    }, [endpoint]);

    const fetchSubmissions = async () => {
        try {
            const response = await authenticatedInstance.get(endpoint);
            setSubmissions(response.data);
        } catch (error) {
            console.error('Error fetching submissions:', error);
        } finally {
            setLoading(false);
        }
    };

    // Callback function to refresh the list of submissions after approval/rejection
    const refreshSubmissions = () => {
        fetchSubmissions();
    };

    // Helper function to determine background color based on status
    const getStatusClass = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'bg-green-100'; // Light green background for approved submissions
            case 'DECLINED':
                return 'bg-red-100'; // Light red background for declined submissions
            case 'NOT_REVIEWED_YET':
                return 'bg-gray-100'; // Light gray background for not reviewed submissions
            default:
                return 'bg-white'; // Default white background
        }
    };

    return (
        <div className="submission-container max-w-5xl mx-auto py-10 px-6 bg-bgGrayOrange min-h-screen">
            {/* Title */}
            <h1 className="text-3xl font-bold text-darkBrown font-raleway mb-8 text-center">{status} Submissions</h1>

            {/* Loading State */}
            {loading ? (
                <div className="flex justify-center items-center">
                    <p className="text-lg font-semibold text-darkBrown">Loading...</p>
                </div>
            ) : (
                <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {/* Submissions List */}
                    {submissions.map(submission => (
                        <div
                            key={submission.id}
                            className={`submission-card p-6 rounded-lg shadow-md border border-lightBrown cursor-pointer transition-transform transform hover:-translate-y-1 hover:shadow-lg ${getStatusClass(submission.status)}`}
                            onClick={() => navigate(`/admin/submission/${submission.id}`)}
                        >
                            <div className="flex flex-col h-full">
                                <div className="mb-4">
                                    <h2 className="text-xl font-bold text-darkBrown font-lora mb-2">
                                        {submission.shop.name}
                                    </h2>
                                    <p className="text-sm text-darkBrown mb-1">
                                        <strong>Seller:</strong> {submission.sellerProfile.firstName} {submission.sellerProfile.lastName}
                                    </p>
                                    <p className="text-sm text-darkBrown mb-1">
                                        <strong>Bio:</strong> {submission.sellerProfile.bio}
                                    </p>
                                    <p className="text-sm text-darkBrown mb-1">
                                        <strong>Shop Description:</strong> {submission.shop.description}
                                    </p>
                                    <p className="text-sm text-darkBrown mb-3">
                                        <strong>Status:</strong> {submission.status}
                                    </p>
                                </div>

                                {/* Actions */}
                                <div className="flex justify-between items-center mt-auto space-x-4" onClick={(e) => e.stopPropagation()}>
                                    <ApproveSubmissionComponent submissionId={submission.id} onActionComplete={refreshSubmissions} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SubmissionsComponent;
